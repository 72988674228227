import auth0 from 'auth0-js';
import config from '../client/config';

export interface User {
  sub: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  vikingId?: string;
}

export interface AuthToken {
  accessToken: string;
  expiresIn: number;
  user: User;
}

export function checkSession(
  client: auth0.WebAuth,
  timeoutMs: number,
): Promise<AuthToken> {
  return new Promise((resolve, reject) => {
    client.checkSession(
      {
        timeout: timeoutMs.toString(),
      } as any, // Done because of incorrect type declration for checkSession.
      (err, authRes) => {
        if (err) {
          reject(err);
        } else {
          // looking for the user/app metadata key here, it always ends with [user/app]Metadata
          // but it's different between tiers for staging it is:
          // http://schemas.sothebys-staging.com/userMetadata
          const userMetadataKey = Object.keys(authRes.idTokenPayload).find(
            (key) => key.includes('userMetadata'),
          );
          const appMetadataKey = Object.keys(authRes.idTokenPayload).find(
            (key) => key.includes('appMetadata'),
          );

          const userMetadata = userMetadataKey
            ? authRes.idTokenPayload[userMetadataKey]
            : undefined;
          const appMetadata = appMetadataKey
            ? authRes.idTokenPayload[appMetadataKey]
            : undefined;

          resolve({
            accessToken: authRes.accessToken,
            expiresIn: authRes.expiresIn,
            user: {
              sub: authRes.idTokenPayload.sub,
              email: authRes.idTokenPayload.email,
              firstName: userMetadata?.firstName,
              lastName: userMetadata?.lastName,
              vikingId: appMetadata?.customerData?.userId,
            },
          });
        }
      },
    );
  });
}

export function getLoginUri(currentBrowserUri: string): string {
  const redirectArgument = encodeURIComponent(
    encodeURIComponent(encodeURIComponent(currentBrowserUri)),
  );

  return config.aemLoginUrl() + redirectArgument;
}

export function getRegisterUri(currentBrowserUri: string): string {
  const redirectArgument = encodeURIComponent(
    encodeURIComponent(encodeURIComponent(currentBrowserUri)),
  );

  return config.aemRegisterUrl() + redirectArgument;
}

export const constructClient = (): auth0.WebAuth =>
  new auth0.WebAuth({
    tenant: config.auth0.tenant(),
    audience: config.auth0.audience(),
    domain: config.auth0.domain(),
    clientID: config.auth0.clientID(),
    redirectUri: config.auth0.redirectUri(),
    responseType: 'token id_token',
    scope: 'email openid',
  });
