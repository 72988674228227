import _ from 'lodash';
import { isServer } from './utils';

export const tokensToString = (tokens: string[]) => {
  return _.map(tokens, (token: string) =>
    _.startCase(_.replace(token, '-', ' ')),
  );
};

export const getTokens = (path: string) => {
  const trimmedPath = _.trim(path, '/');
  return _.split(trimmedPath, '/');
};

export const pathToAlgoliaHierarchy = (path: string) => {
  const tokens = tokensToString(getTokens(path));
  const nestedLevel = tokens.length - 1;
  return `categories.lvl${nestedLevel}: ` + tokens.join(' > ');
};

export const hierarchyToTokens = (hierarchy: { [key: number]: string }) => {
  return _.map(hierarchy, (value) => value);
};

export const amountToString = (
  value: number,
  currency: string,
  toFloat: boolean = false,
) => {
  return `${value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: toFloat ? 2 : 0,
  })} ${currency}`;
};

export const stripHTMLFromString = (htmlString: string) => {
  return htmlString.replace(/(<([^>]+)>)/gi, '');
};

type tokensToPageTitleProps = {
  tokens: (string | undefined)[];
  separator?: string;
};
export const tokensToFormattedString = ({
  tokens,
  separator = ' ',
}: tokensToPageTitleProps) => {
  const truncatedTokens = tokens
    .filter((token) => token !== '' && token !== undefined)
    .map((token) => toTitleCase(token));
  return truncatedTokens.join(separator);
};

const toTitleCase = (str: string | undefined) =>
  str?.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1));

type UrlPathToSegmentPageNameProps = {
  path: string;
  language: string;
};

export const urlPathToSegmentPageName = ({
  path,
  language,
}: UrlPathToSegmentPageNameProps) => {
  const pathStr = _.trimStart(path, language + '/');
  const tokens = _.split(pathStr, '/').map((el) => {
    if (_.startsWith(el, '_')) {
      return _.trimStart(el, '_');
    } else {
      return _.startCase(el);
    }
  });
  return _.join(tokens, ': ');
};

export const getCategoriesFromPath = (path: string) => {
  const tokens = _.split(path, '/');
  const buyIndex = _.findIndex(tokens, (i) => i === 'buy');
  const strippedTokens = _.slice(tokens, buyIndex + 1);
  return _.filter(strippedTokens, (token) => token[0] !== '_');
};

export const isUUID = (uuid: string) =>
  uuid.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
  ) !== null;

export const escapeSpecialCharacters = (string: string) =>
  string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export const replaceAll = (
  originalString: string,
  replace: string,
  replaceWith: string,
) => originalString.split(replace).join(replaceWith);

export const stringAfterSeparator = (string: string, separator: string) => {
  const substringsArray = string?.split(separator);
  return substringsArray.length <= 1
    ? string // separator does not appear in string
    : substringsArray.slice(1).join(separator);
};

export const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
