import Head from 'next/head';
import { CSSProperties } from 'react';

type stylesProps = {
  [key: string]: CSSProperties;
};

const styles: stylesProps = {
  title: {
    fontFamily:
      'Mercury Display A, Mercury Display B, MercuryDisplay-wb, serif',
    fontWeight: 400,
  },
  titleH1: {
    fontSize: '100px',
    lineHeight: 1,
    marginBottom: 0,
  },
  titleH3: {
    fontSize: '20px',
    maxWidth: '440px',
    lineHeight: '1.25',
    letterSpacing: '2px',
    margin: '64px auto',
  },
  titleH5: {
    fontSize: '20px',
    lineHeight: '1.2',
  },
  errorBody: {
    margin: '32px auto',
    maxWidth: '667px',
    textAlign: 'center',
  },
  homepageLink: {
    marginTop: '64px',
    display: 'inline-block',
    color: '#333',
  },
};

const UnexpectedErrorScreen = () => {
  return (
    <>
      <Head>
        <title>Something went wrong | Sotheby's</title>
      </Head>
      <div style={styles.errorBody}>
        <h3 style={{ ...styles.title, ...styles.titleH3 }}>
          Oops, an unexpected error occurred.
        </h3>
        <h5 style={{ ...styles.title, ...styles.titleH5 }}>
          Please try again.
        </h5>
        <a href="/" style={styles.homepageLink}>
          <h5 style={{ ...styles.title, ...styles.titleH5 }}>
            Return to Sotheby's homepage
          </h5>
        </a>
      </div>
    </>
  );
};

export default UnexpectedErrorScreen;
