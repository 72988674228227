import BaseStore from './BaseStore';

/**
 *
 * Stores data used for analytics
 *
 **/

export interface TrackingState {
  algoliaQueryID?: string;
  algoliaObjectID?: string;
  algoliaIndexName?: string;
}

export class TrackingStateStore extends BaseStore<TrackingState> {}

export default new TrackingStateStore({});
