import _ from 'lodash';

export interface AlgoliaConfig {
  appID: () => string;
  productIndexName: () => string;
  clickAnalytics: () => boolean;
}

export interface Auth0Config {
  tenant: () => string;
  domain: () => string;
  clientID: () => string;
  redirectUri: () => string;
  audience: () => string;
}

export interface AppConfig {
  algolia: AlgoliaConfig;
  getGrahpqlUrl: () => string;
  tier: () => string;
  auth0: Auth0Config;
  cartUrl: () => string;
  checkoutUrl: () => string;
  damUrl: () => string;
  segmentWriteKey: () => string;
  aemLoginUrl: () => string;
  aemRegisterUrl: () => string;
  googleOptimizeSrc: () => string;
  githash: () => string;
}

export const isBrowser = (): boolean => {
  return process['browser'] === true;
};

const getRequiredPropertyFromEnv = (env: any, variableName: string) => {
  const variable = env[variableName];
  if (variable !== undefined) {
    return variable;
  }

  throw new Error(`Environment variable ${variableName} is missing`);
};

export const getEnvVariable = (variableName: string) => {
  if (isBrowser()) {
    return getRequiredPropertyFromEnv(window, variableName);
  } else {
    return getRequiredPropertyFromEnv(process.env, variableName);
  }
};

// in some cases we need env variables, but we cant call getEnvVariable since that fails during build time
// this allows you to call a env variable with a default value
// use carefully since this will fail silently
export const getOptionalEnvVariableWithDefault = (
  variableName: string,
  defaultValue: string,
): string => {
  if (isBrowser()) {
    // @ts-ignore
    return window[variableName] || defaultValue;
  } else {
    return process.env[variableName] || defaultValue;
  }
};

const appConfig: AppConfig = {
  algolia: {
    appID: () => getEnvVariable('ALGOLIA_APP_ID'),
    productIndexName: () => getEnvVariable('ALGOLIA_INDEX_NAME'),
    clickAnalytics: () => true,
  },
  getGrahpqlUrl: () => {
    if (isBrowser()) {
      return getEnvVariable('GRAPHQL_URL');
    } else {
      return getEnvVariable('GRAPHQL_NEXTJS_URL');
    }
  },
  tier: () => getEnvVariable('TIER'),
  auth0: {
    tenant: () => getEnvVariable('AUTH_0_TENANT'),
    domain: () => getEnvVariable('AUTH_0_DOMAIN'),
    clientID: () => getEnvVariable('AUTH_0_CLIENT_ID'),
    redirectUri: () => getEnvVariable('AUTH_0_REDIRECT_URI'),
    audience: () => getEnvVariable('AUTH_0_AUDIENCE'),
  },
  cartUrl: () => getEnvVariable('CART_URL'),
  checkoutUrl: () => getEnvVariable('CHECKOUT_URL'),
  damUrl: () => getEnvVariable('DAM_URL'),
  segmentWriteKey: () => getEnvVariable('SEGMENT_WRITE_KEY'),
  aemLoginUrl: () => getEnvVariable('AEM_LOGIN_URL'),
  aemRegisterUrl: () => getEnvVariable('AEM_REGISTER_URL'),
  googleOptimizeSrc: () => getEnvVariable('GOOGLE_OPTIMIZE_SRC'),
  githash: () => getOptionalEnvVariableWithDefault('GITHASH', 'development'),
};

export default appConfig;
