export enum ProductInteractionType {
  ClickAlternativeImage = 'click alternative image',
  ClickCataloguing = 'click cataloguing',
  ClickConditionReport = 'click condition report',
  ClickExhibited = 'click exhibited',
  ClickLiterature = 'click literature',
  ClickLotDetails = 'click lot details',
  ClickProvenance = 'click provenance',
  ClickReadLess = 'click read less',
  ClickReadMore = 'click read more',
  ClickSaleroomNotice = 'click saleroom notice',
  FinanceServiceModalClicked = 'finance service modal clicked',
  FinanceServiceModalClosed = 'finance service modal closed',
  FinanceServiceModalOpened = 'finance service modal opened',
}
