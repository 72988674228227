import { useEffect, useLayoutEffect } from 'react';
import _ from 'lodash';
import {
  RetailItemMetadata,
  RetailItemPropertyType,
  RetailItem,
  DimensionMetadata,
  Media,
  Variant,
} from '../../client/client';
import { PDPTypes } from '../routes/ProductDetailPage/config';
import { unavailableStatesToShipWine } from '../shared/data/statesList';
import * as AlgoliaTypes from '../routes/BrowsePage/AlgoliaTypes';
import { RetailItemContextType } from '../providers/ProductDetailPageProvider';

export const isServer = () => typeof window === 'undefined';
export const isBrowser = () => typeof window !== 'undefined';

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const isMobileOrTablet = () => {
  let check = false;
  if (isBrowser()) {
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
      // detect IOS version in iOS 13 and Up
      if (
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
  }
  return check;
};

type CurrentPDPUrlProps = {
  host?: string;
  path?: string;
};

export const getCurrentPDPUrl = ({ host, path }: CurrentPDPUrlProps) => {
  const url = isBrowser() ? window.location.href : 'https://' + host + path;
  return url.split('?')[0];
};

export const getQueryString = (path: string = '') => {
  return isBrowser()
    ? window.location.search.slice(1)
    : path.indexOf('?')
    ? path.split('?')[1]
    : '';
};

export const getCurrentPath = (path: string = '') => {
  const url = isBrowser() ? window.location.pathname : path;
  return url;
};

export const getCurrentPathname = (path: string = '') => {
  const url = isBrowser() ? window.location.pathname : path;
  return url.split('?')[0];
};

export const isFineArtPDP = (category?: string) => {
  return (
    category === 'Painting' ||
    category === 'Print' ||
    category === 'Work on Paper' ||
    category === 'Photograph' ||
    category === 'Vintage Poster' ||
    category === 'Mixed Media' ||
    category === 'Sculpture'
  );
};

export const isSneakersPDP = (objectType?: string) => {
  return objectType === 'Sneaker';
};

export const isHandbagPDP = (category?: string) => {
  return category === 'Handbag';
};

export const isFurniturePDP = (category?: string) => {
  return (
    category === 'Seating' ||
    category === 'Table' ||
    category === 'Storage' ||
    category === 'Bed' ||
    category === 'Lighting' ||
    category === 'Rug' ||
    category === 'Collectibles' ||
    category === 'Clock' ||
    category === 'Dining & Serveware' ||
    category === 'Decor' ||
    category === 'Textiles' ||
    category === 'Ritual Items & Weapons' ||
    category === 'Architectural & Garden'
  );
};

export const isBookPDP = (category?: string) => {
  return category === 'Book';
};

export const isWinePDP = (category?: string) => category === 'Wine';

export const isSpiritsPDP = (category?: string) => category === 'Spirit';

export const isWineSalesEntity = (
  salesEntity?: AlgoliaTypes.Hit['salesEntity'],
) => salesEntity === 'WINE';

export const isPrivateSale = (propertyType: RetailItemPropertyType) => {
  return propertyType === RetailItemPropertyType.PrivateSaleExhibition;
};

export const getPDPType = (category?: string, isPrivateSale?: boolean) => {
  switch (category) {
    case 'Painting':
    case 'Print':
    case 'Photograph':
    case 'Vintage Poster':
    case 'Sculpture':
    case 'Mixed Media':
    case 'Work on Paper':
      return isPrivateSale ? PDPTypes.PEXSFINEART : PDPTypes.FINEART;
    case 'Sneakers':
      return PDPTypes.SNEAKERS;
    case 'Handbag':
      return PDPTypes.HANDBAG;
    case 'Seating':
    case 'Table':
    case 'Storage':
    case 'Bed':
    case 'Lighting':
    case 'Rug':
    case 'Collectibles':
    case 'Clock':
    case 'Dining & Serveware':
    case 'Decor':
    case 'Textiles':
    case 'Ritual Items & Weapons':
    case 'Architectural & Garden':
      return isPrivateSale ? PDPTypes.PEXSVARIOUS : PDPTypes.VARIOUS;
    case 'Watch':
      return PDPTypes.WATCH;
    case 'Book':
      return PDPTypes.BOOK;
    default:
      return undefined;
  }
};

export const convertToInches = (unit: string, measurement = 0) => {
  if (unit === 'mm') {
    return measurement / 25.4;
  } else if (unit === 'cm') {
    return measurement / 2.54;
  } else if (unit === 'inches' || unit === 'in') {
    return measurement;
  } else if (unit === 'feet') {
    return measurement * 12;
  }

  return measurement;
};

export const convertToCm = (unit: string, measurement = 0) => {
  if (unit === 'mm') {
    return measurement / 10;
  } else if (unit === 'cm') {
    return measurement;
  } else if (unit === 'inches' || unit === 'in') {
    return measurement * 2.54;
  } else if (unit === 'feet') {
    return measurement * 30.48;
  }

  return measurement;
};

export const extractMetadataByKeys = (
  keys: string[],
  metadata?: Array<RetailItemMetadata>,
) => {
  if (keys.length) {
    return keys.map((key) => {
      return metadata?.find(
        (data) =>
          data.key === key &&
          (data.value.stringValue ||
            data.value.stringValues ||
            data.value.integerValue ||
            data.value.boolValue),
      );
    });
  }

  return [];
};

export const extractMetadataByKey = (
  key: string,
  metadata?: Array<RetailItemMetadata>,
) =>
  metadata?.find(
    (data) =>
      data.key === key &&
      (data.value.stringValue ||
        data.value.stringValues ||
        data.value.integerValue ||
        data.value.boolValue ||
        data.value.floatValue),
  );
export const extractDimensionMetadataByKeys = (
  keys: string[],
  metadata?: Array<DimensionMetadata>,
  showAll?: boolean,
) => {
  const dimensionList = showAll
    ? metadata
    : keys
        .map((key) =>
          metadata?.find(
            (data) => data.key === key && (data.value || data.unit),
          ),
        )
        .filter((item) => item);
  const orederedList = dimensionList
    ? _.sortBy(dimensionList, (dimension) =>
        dimension
          ? keys.indexOf(dimension.key) >= 0
            ? keys.indexOf(dimension.key)
            : keys.length
          : undefined,
      )
    : [];
  return orederedList;
};

export const getProductImageUrl = (
  media?: Media,
  size: string = 'Original',
) => {
  const originalImages = [];
  media?.images.map((image) => {
    const originalImage = image.renditions.find(
      (rendition) => rendition.imageSize === size,
    );
    if (originalImage) {
      originalImages.push(originalImage.url);
    }
  });
  if (originalImages.length === 0) {
    originalImages.push(media?.images[0].renditions[0].url);
  }
  return originalImages.length ? originalImages[0] : '';
};

export const getPercentDifferenceInt = (x: number, y: number): number => {
  if (x > 0 && y >= 0) {
    const result = ((x - y) / x) * 100;
    return Math.ceil(result);
  } else {
    return 0;
  }
};

type ImageRendition = {
  url: string;
  imageSize: string;
  width: number;
  height: number;
};

export const getCollectionImageBySize = (
  media?: Media,
  size: string = 'Original',
): ImageRendition | undefined =>
  //it is assumed that the collection can have only one image
  media?.images[0]?.renditions.find(
    (rendition) => rendition.imageSize === size,
  );

type useScriptProps = {
  url: string;
  async?: boolean;
  defer?: boolean;
};
export const useScript = ({ url, async, defer }: useScriptProps) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    if (async) {
      script.async = async;
    }
    if (defer) {
      script.defer = defer;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const generateCreatorsString = (
  attribution?: string,
  creators?: string[],
) => {
  if (creators && creators.length > 0) {
    let creatorString = '';
    if (attribution && attribution !== '') {
      creatorString += attribution + ' ';
    }
    creatorString += creators.join(', ');
    return creatorString;
  } else {
    return '';
  }
};

// The method returns a string with information where the item was produced.
// Example: getProducedPlace(a, b, c) => 'a, b, c'
export const getProducedPlace = (...args: (string | undefined)[]): string => {
  return args.filter((place) => !!place?.trim()).join(', ');
};

// The method checks the availability of the state for delivery
// Example: getAvailableStateToShipWine(AL) => true
export const getAvailableStateToShipWine = (state: string): boolean => {
  return !unavailableStatesToShipWine.includes(state);
};

export const quantityLabel = (quantity?: number) => {
  if (!quantity) {
    return `Out of Stock`;
  }
  if (quantity > 24) {
    return `In Stock (24+ Available)`;
  }

  return `In Stock (${quantity} Available)`;
};

// The method returns data where the product is located
// Example: getShipsFromText(param1, param2, param3) => Ships from: New York, United States
export const getShipsFromText = (
  city?: string,
  state?: string,
  countryISO?: { englishShortNameReadingOrder: string },
) => {
  if (countryISO?.englishShortNameReadingOrder && state) {
    return `Ships from: ${state}, ${countryISO.englishShortNameReadingOrder}`;
  } else if (countryISO?.englishShortNameReadingOrder && city) {
    return `Ships from: ${city}, ${countryISO.englishShortNameReadingOrder}`;
  } else if (countryISO?.englishShortNameReadingOrder) {
    return `Ships from: ${countryISO?.englishShortNameReadingOrder}`;
  } else {
    return 'This item ships within the US';
  }
};

// The method returns date from the object metadata
// Example: getDateDetail(...params) => Circa Early 10th Century 1998 - 2000
export const getDateDetail = ({
  circa,
  centuryPrefix,
  century,
  constructionYearStart,
  constructionYearEnd,
}: {
  [key: string]: RetailItemMetadata | undefined;
}) => {
  return `${circa?.value.boolValue ? 'Circa ' : ''}${
    centuryPrefix?.value.stringValue
      ? centuryPrefix.value.stringValue + ' '
      : ''
  }${
    century?.value.stringValue ? century.value.stringValue + ' Century ' : ''
  }${
    constructionYearStart?.value.integerValue
      ? constructionYearStart.value.integerValue
      : ''
  }${
    constructionYearStart?.value.integerValue &&
    constructionYearEnd?.value.integerValue
      ? ' - '
      : ''
  }${
    constructionYearEnd?.value.integerValue
      ? constructionYearEnd.value.integerValue
      : ''
  }`;
};

/**
 * Takes in a retailItem object and returns the seller of the retail Item
 * @param retailItem
 * @returns 'Bucherer' | 'Sothebys' | undefined
 */
export const getRetailItemSeller = (retailItem: RetailItem) => {
  const certifiedPreOwnedByBucherer = extractMetadataByKey(
    'Certified Pre-Owned By Bucherer',
    retailItem.objects?.[0].metadata,
  );
  if (certifiedPreOwnedByBucherer) {
    return 'Bucherer';
  } else if (retailItem.isOwned) {
    return 'Sothebys';
  } else {
    return undefined;
  }
};

/**
 * Takes in a hit and returns the seller
 * @param hit
 * @returns 'Bucherer' | 'Sothebys' | undefined
 */
export const getSellerFromHit = (hit: AlgoliaTypes.Hit) => {
  const certifiedPreOwnedByBucherer = hit['Certified Pre-Owned By Bucherer'];
  if (certifiedPreOwnedByBucherer) {
    return 'Bucherer';
  } else if (hit.isOwned) {
    return 'Sothebys';
  } else {
    return undefined;
  }
};

export const isInternationalFreeship = (retailItem: RetailItem) => {
  const minPrice = 2000;
  const hasVariants = retailItem.variantGroup?.variants.length > 1;

  const isOverPriceMin = hasVariants
    ? retailItem.variantGroup?.variants.every(
        (variant) => variant.listPrice >= minPrice,
      )
    : (retailItem.pricing.listPrice || 0) >= minPrice;

  return (
    !retailItem.config.offerEnabled &&
    retailItem.shipping?.offeredServiceTypes?.includes('SmallParcel') &&
    isOverPriceMin
  );
};

export const getPdpTokens = (
  retailItem: RetailItem,
  separator: string,
  prefixText: string,
  suffixText: string,
) => {
  const metadata = retailItem?.objects?.[0]?.metadata;
  const [periodGeneral, specialFeature, bookSpecialFeature] =
    extractMetadataByKeys(
      ['Period - General', 'Special Feature', 'Book Special Feature'],
      metadata,
    ).map((metadata) => {
      let value = metadata?.value;
      return value?.__typename === 'ObjectMetadataStringValue'
        ? value?.['stringValue']
        : value?.['stringValues']?.[0];
    });

  const creators = retailItem?.creators
    ?.map((creator) => creator.displayName)
    ?.join(separator);
  const productTitle = retailItem?.title;

  return [
    prefixText,
    creators,
    periodGeneral !== 'Contemporary' ? periodGeneral : undefined,
    productTitle,
    specialFeature,
    bookSpecialFeature,
    suffixText,
  ].filter((token) => token !== '' && token !== undefined);
};

export const getPdpPageTitleTokens = (retailItem: RetailItem) =>
  getPdpTokens(
    retailItem,
    ', ',
    '',
    'Available For Immediate Sale at Sotheby’s',
  );

export const getPdpMetaDescriptionTokens = (retailItem: RetailItem) =>
  getPdpTokens(
    retailItem,
    ', ',
    isInternationalFreeship(retailItem)
      ? 'Complimentary Shipping on'
      : 'Easy Checkout on',
    'Plus Shop Our New Arrivals, New Items Added Daily',
  );

export const getPdpHeaders = (retailItem: RetailItemContextType) => {
  const { title, creator } = retailItem;

  const creatorLabel = [creator?.attribution, creator?.displayName]
    .filter(Boolean)
    .join(' ');
  const header = creatorLabel ? creatorLabel : title;
  const italicHeader = creatorLabel ? title : undefined;

  return {
    header: header,
    italicHeader: italicHeader,
  };
};
