import {
  createInstance,
  ReactSDKClient,
  setLogLevel,
} from '@optimizely/react-sdk';
import { createContext, useContext, useEffect, useState } from 'react';
import appConfig from '../config';
import { useAuthentication } from '../hooks/AuthenticationHook';
import AuthZeroStore, { TokenState } from '../stores/AuthZeroStore';
import {
  isAnalyticsTrackingEnabled,
  setOptimizelyAnonymousUserId,
} from '../utils/segment/shared';

type Variations = {
  [key: string]: string | null;
};

type UserAttributes = { [name: string]: any };

export interface OptimizelyContextInterface {
  optimizely: ReactSDKClient | null;
  userAttributes?: UserAttributes;
}

export const OptimizelyContext = createContext<
  OptimizelyContextInterface | undefined
>(undefined);

const OptimizelyProvider = ({ children }: { children: React.ReactChild }) => {
  const isTrackingEnabled = isAnalyticsTrackingEnabled();
  const authState = useAuthentication('App');

  const [optimizely, setOptimizely] = useState<ReactSDKClient>();
  const [variations, setVariations] = useState<Variations>();

  useEffect(() => {
    if (isTrackingEnabled) {
      setOptimizely(
        createInstance({
          sdkKey:
            appConfig.tier() === 'prod'
              ? 'FBjumdW1iD4Uf3oXNLuka'
              : 'FJA9g12ud8y5n3iaHqiE6',
          eventBatchSize: 10,
          eventFlushInterval: 1000,
        }),
      );
      setLogLevel('warn');
    }
  }, []);

  useEffect(() => {
    if (optimizely) {
      if (authState.tokenState !== TokenState.NOT_FETCHED) {
        if (authState.tokenState === TokenState.UNAUTHENTICATED) {
          setOptimizelyAnonymousUserId(optimizely);
        } else if (authState.tokenState === TokenState.AUTHENTICATED) {
          optimizely.setUser({
            id: AuthZeroStore.getAuth0Id(),
          });
        }
      }
    }
  }, [authState]);

  optimizely?.onUserUpdate((_) => {
    const decisions = optimizely.decideAll();
    setVariations(
      Object.fromEntries(
        Object.entries(decisions).map(([featureKey, decision]) => [
          featureKey,
          decision.variationKey,
        ]),
      ),
    );
  });

  if (optimizely) {
    return (
      <OptimizelyContext.Provider
        value={{
          optimizely: optimizely,
          userAttributes: { variations: variations },
        }}
      >
        {children}
      </OptimizelyContext.Provider>
    );
  }
  return <>{children}</>;
};

const OptimizelyContextComponent = () => {
  const optimizelyProviderContext = useContext(OptimizelyContext);

  const track = (eventName: string) =>
    optimizelyProviderContext?.optimizely?.track(eventName);
  const getVariation = (featureKey: string) => {
    const variation =
      optimizelyProviderContext?.userAttributes?.['variations']?.[featureKey];

    if (variation === undefined) return 'not-set';
    return variation;
  };

  return {
    track,
    getVariation,
  };
};

export { OptimizelyProvider, OptimizelyContextComponent };
