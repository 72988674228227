class BaseStore<T> {
  private state: T;
  private listeners: Map<string, (a: T) => void>;

  constructor(initialState: T) {
    this.state = initialState;
    this.listeners = new Map();
  }

  listen(name: string, cb: (state: T) => void): T {
    if (this.listeners.has(name)) {
      // TODO: Bugsnag this error
      throw new Error(`${name} is already listening in the store`);
    }
    this.listeners.set(name, cb);
    return this.state;
  }

  remove(name: string): void {
    this.listeners.delete(name);
  }

  getState(): T {
    return this.state;
  }

  notify(): void {
    this.listeners.forEach((v, name) => {
      v(this.state);
    });
  }

  setState(state: T) {
    this.state = state;
    this.notify();
  }
}

export default BaseStore;
