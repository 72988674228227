/**
 * We followed this https://kentcdodds.com/blog/how-to-use-react-context-effectively
 * to create this context.
 * Kristinn & Elsa 13.April.2021
 */

import * as React from 'react';

import {
  CurrencyRate,
  CurrencyRates,
  getCurrencyRates,
  Currency,
} from '../client';
import { amountToString } from '../utils/strUtils';
import Cookies from 'js-cookie';
import { isServer } from '../utils/utils';
export interface CurrencyContext {
  selectedCurrency: Currency;
  allCurrencies?: CurrencyRates;
  isLoading: boolean;
  setSeletectedRate: (rate: CurrencyRate) => void;
  convertedCurrency: (
    amount: number,
    currency: Currency,
    toFloat?: boolean,
  ) => string;
  convertedCurrencyAmount: (
    amount: number,
    otherCurrency: Currency,
    selectedCurrencyIsBase?: boolean,
  ) => number;
}

export const SELECTED_CURRENCY_COOKIE_KEY =
  'marketplace-selected-currency-rate';

export const CurrencyContextDefaultValue: CurrencyContext = {
  selectedCurrency: Currency.USD,
  isLoading: true,
  setSeletectedRate: () => {},
  convertedCurrency: (a, b) => {
    return a.toString() + ' ' + b;
  },
  convertedCurrencyAmount: (amount, _otherCurrency, _selectedCurrencyIsBase) =>
    amount,
};

type CurrencyProviderProps = {
  children: React.ReactNode;
  currency: Currency;
  currencyRates: CurrencyRates;
};

const CurrencyRatesContext = React.createContext<CurrencyContext | undefined>(
  undefined,
);

function CurrencyProvider({
  children,
  currency,
  currencyRates,
}: CurrencyProviderProps) {
  const [isLoading, setLoading] = React.useState(true);
  const [selectedRate, setSeletectedRateState] =
    React.useState<Currency>(currency);
  const [allCurrencies, setAllCurrencies] = React.useState<
    CurrencyRates | undefined
  >(currencyRates);

  React.useEffect(() => {
    setLoading(true);
    getCurrencyRates(selectedRate)
      .then((data) => {
        const rate = data?.currencyRates?.baseCurrency;
        const isStillRelevant = data?.currencyRates?.baseCurrency === rate;

        if (isStillRelevant && rate) {
          setAllCurrencies(data.currencyRates);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedRate]);

  const setSeletectedRate = (rate: CurrencyRate) => {
    setSeletectedRateState(rate.currency);
    Cookies.set(SELECTED_CURRENCY_COOKIE_KEY, rate.currency, { expires: 1 });
  };

  const convertCurrency = (
    amount: number,
    currency: Currency,
    toFloat: boolean = false,
  ) => {
    if (allCurrencies) {
      const rate = allCurrencies.rates?.find((rate) => {
        return rate.currency === currency;
      });
      if (rate) {
        const rateNumber: number = +rate.rate;
        const calculatedRate: number = toFloat
          ? amount / rateNumber
          : Math.floor(amount / rateNumber);

        return amountToString(calculatedRate, selectedRate, toFloat);
      }
    }
    return amountToString(amount, currency, toFloat);
  };

  const convertCurrencyAmount = (
    amount: number,
    otherCurrency: Currency,
    selectedCurrencyIsBase: boolean = true,
  ) => {
    if (!isServer() && allCurrencies) {
      const rate = allCurrencies.rates.find(
        (rate) => rate.currency === otherCurrency,
      );
      if (rate) {
        const rateNumber: number = +rate.rate;

        return selectedCurrencyIsBase
          ? Math.floor(amount * rateNumber)
          : Math.floor(amount / rateNumber);
      }
    }
    return amount;
  };

  return (
    <CurrencyRatesContext.Provider
      value={{
        selectedCurrency: selectedRate,
        allCurrencies: allCurrencies,
        isLoading: isLoading,
        setSeletectedRate,
        convertedCurrency: convertCurrency,
        convertedCurrencyAmount: convertCurrencyAmount,
      }}
    >
      {children}
    </CurrencyRatesContext.Provider>
  );
}

function useCurrency() {
  const context = React.useContext(CurrencyRatesContext);
  if (context === undefined) {
    return CurrencyContextDefaultValue;
    //throw new Error("useCurrency must be used within a CurrencyProvider");
  }
  return context;
}

export { CurrencyProvider, useCurrency };
