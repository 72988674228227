import { useState, useEffect } from 'react';
import authZeroStore, { State, TokenState } from '../stores/AuthZeroStore';

function getInitialState(): State {
  if (process.browser) {
    return authZeroStore.getState();
  }

  return {
    token: null,
    tokenState: TokenState.UNAUTHENTICATED,
  };
}

export const useAuthentication = (componentName: string) => {
  const [authToken, setAuthToken] = useState<State>(getInitialState());

  useEffect(() => {
    authZeroStore.listen(componentName, (state) => {
      setAuthToken(state);
    });

    if (process.browser) {
      authZeroStore.startAuthLoop();
    }
    return () => {
      authZeroStore.remove(componentName);
    };
  }, []);

  return authToken;
};
