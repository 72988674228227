export enum AttributeType {
  METADATA,
  PRIMARY,
  BUCHERER,
  CONDITION,
  DIMENSION,
  OBJECTPRIMARY,
}

interface ObjectTypeConfig {
  attribute: string;
  type: AttributeType;
  label?: string;
  dimensionAttributes?: string[];
}

export enum PDPTypes {
  FINEART = 1,
  SNEAKERS = 2,
  HANDBAG = 3,
  VARIOUS = 4,
  WATCH = 5,
  BOOK = 6,
  PEXSFINEART = 7,
  PEXSVARIOUS = 8,
}

interface ObjectConfigMap {
  [key: string]: ObjectTypeConfig[];
}

const objectConfigMap: ObjectConfigMap = {
  [PDPTypes.FINEART]: [
    {
      attribute: 'description',
      type: AttributeType.PRIMARY,
      label: 'Description',
    },
    {
      attribute: 'Dimensions',
      type: AttributeType.DIMENSION,
      dimensionAttributes: [
        'Height',
        'Width',
        'Depth',
        'Frame Height',
        'Frame Width',
        'Frame Depth',
        'Sight Height',
        'Sight Width',
        'Diameter',
      ],
    },
    {
      attribute: 'Signature Details',
      type: AttributeType.METADATA,
      label: 'Signature',
    },
    {
      attribute: 'provenance',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Provenance',
    },
    {
      attribute: 'exhibition',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Exhibition',
    },
    {
      attribute: 'literature',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Literature',
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
    {
      attribute: 'condition',
      type: AttributeType.CONDITION,
    },
    {
      attribute: 'Publisher',
      type: AttributeType.METADATA,
    },
    {
      attribute: 'Art Period - Specific',
      type: AttributeType.METADATA,
      label: 'Art Period',
    },
    {
      attribute: 'Art Movement',
      type: AttributeType.METADATA,
      label: 'Movement/Style',
    },
  ],
  [PDPTypes.PEXSFINEART]: [
    {
      attribute: 'provenance',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Provenance',
    },
    {
      attribute: 'exhibition',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Exhibition',
    },
    {
      attribute: 'literature',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Literature',
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
  ],
  [PDPTypes.SNEAKERS]: [
    {
      attribute: 'description',
      type: AttributeType.PRIMARY,
      label: 'Description',
    },
    {
      attribute: 'Sneaker Model',
      type: AttributeType.METADATA,
      label: 'Model',
    },
    {
      attribute: 'Special Feature',
      type: AttributeType.METADATA,
      label: 'Special Feature(s)',
    },
    {
      attribute: 'Sneaker Type',
      type: AttributeType.METADATA,
      label: 'Type',
    },
    {
      attribute: 'Color',
      type: AttributeType.METADATA,
      label: 'Color(s)',
    },
    {
      attribute: 'Materials',
      type: AttributeType.METADATA,
      label: 'Materials',
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
    {
      attribute: 'condition',
      type: AttributeType.CONDITION,
    },
  ],
  [PDPTypes.HANDBAG]: [
    {
      attribute: 'description',
      type: AttributeType.PRIMARY,
      label: 'Description',
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
    {
      attribute: 'condition',
      type: AttributeType.CONDITION,
    },
    {
      attribute: 'Year',
      type: AttributeType.METADATA,
    },
    {
      attribute: 'Dimensions',
      type: AttributeType.DIMENSION,
      dimensionAttributes: [
        'Height',
        'Width',
        'Depth',
        'Handle Drop',
        'Shoulder Strap Drop Max',
        'Shoulder Strap Drop Min',
      ],
    },
  ],
  [PDPTypes.VARIOUS]: [
    {
      attribute: 'description',
      type: AttributeType.PRIMARY,
      label: 'Description',
    },
    {
      attribute: 'provenance',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Provenance',
    },
    {
      attribute: 'exhibition',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Exhibition',
    },
    {
      attribute: 'literature',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Literature',
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
    {
      attribute: 'condition',
      type: AttributeType.CONDITION,
    },
    {
      attribute: 'Dimensions',
      type: AttributeType.DIMENSION,
      dimensionAttributes: [
        'Show All',
        'Height',
        'Width',
        'Depth',
        'Arm Height',
        'Seat Height',
        'Seat Depth',
        'Diameter',
      ],
    },
    {
      attribute: 'Materials',
      type: AttributeType.METADATA,
      label: 'Materials',
    },
    {
      attribute: 'Period - Specific',
      type: AttributeType.METADATA,
      label: 'Period',
    },
    {
      attribute: 'Decorative Style',
      type: AttributeType.METADATA,
      label: 'Decorative Style',
    },
    {
      attribute: 'Country',
      type: AttributeType.METADATA,
      label: 'Country of Origin',
    },
  ],
  [PDPTypes.WATCH]: [
    {
      attribute: 'description',
      type: AttributeType.PRIMARY,
      label: 'Description',
    },
    {
      attribute: 'Certified Pre-Owned By Bucherer',
      type: AttributeType.BUCHERER,
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
    {
      attribute: 'condition',
      type: AttributeType.CONDITION,
    },
    {
      attribute: 'Year',
      type: AttributeType.METADATA,
      label: 'Year',
    },
    {
      attribute: 'Reference Number',
      type: AttributeType.METADATA,
      label: 'Reference Number',
    },
    {
      attribute: 'Watch Model',
      type: AttributeType.METADATA,
      label: 'Model',
    },
    {
      attribute: 'Watch Model Notes',
      type: AttributeType.METADATA,
      label: ' ',
    },
    {
      attribute: 'Complication',
      type: AttributeType.METADATA,
      label: 'Complication',
    },
    {
      attribute: 'Gender',
      type: AttributeType.METADATA,
      label: 'Gender',
    },
    {
      attribute: 'Dial Color(s)',
      type: AttributeType.METADATA,
      label: 'Dial Color(s)',
    },
    {
      attribute: 'Dial Notes',
      type: AttributeType.METADATA,
      label: 'Dial Notes',
    },
    {
      attribute: 'Dial Numerals',
      type: AttributeType.METADATA,
      label: 'Dial Numerals',
    },
    {
      attribute: 'Caliber',
      type: AttributeType.METADATA,
      label: 'Caliber',
    },
    {
      attribute: 'Movement Type',
      type: AttributeType.METADATA,
      label: 'Movement Type',
    },
    {
      attribute: 'Movement Number',
      type: AttributeType.METADATA,
      label: 'Movement Number',
    },
    {
      attribute: 'Case Material(s)',
      type: AttributeType.METADATA,
      label: 'Case Material(s)',
    },
    {
      attribute: 'Case Number',
      type: AttributeType.METADATA,
      label: 'Case Number',
    },
    {
      attribute: 'Case Size (mm)',
      type: AttributeType.METADATA,
      label: 'Case Size (mm)',
    },
    {
      attribute: 'Case Back',
      type: AttributeType.METADATA,
      label: 'Case Back',
    },
    {
      attribute: 'Band Material',
      type: AttributeType.METADATA,
      label: 'Band Material',
    },
    {
      attribute: 'Dimension text',
      type: AttributeType.METADATA,
      label: 'Dimension Notes',
    },
    {
      attribute: 'Clasp Type',
      type: AttributeType.METADATA,
      label: 'Clasp Type',
    },
    {
      attribute: 'Clasp Material(s)',
      type: AttributeType.METADATA,
      label: 'Clasp Material(s) ',
    },
    {
      attribute: 'Watch Signature',
      type: AttributeType.METADATA,
      label: 'Signature',
    },
    {
      attribute: 'Box',
      type: AttributeType.METADATA,
      label: 'Box',
    },
    {
      attribute: 'Papers',
      type: AttributeType.METADATA,
      label: 'Papers',
    },
  ],
  [PDPTypes.BOOK]: [
    {
      attribute: 'description',
      type: AttributeType.PRIMARY,
      label: 'Description',
    },
    {
      attribute: 'provenance',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Provenance',
    },
    {
      attribute: 'literature',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Literature',
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
    {
      attribute: 'condition',
      type: AttributeType.CONDITION,
    },
    {
      attribute: 'Dimensions',
      type: AttributeType.DIMENSION,
      dimensionAttributes: ['Height', 'Width', 'Depth'],
    },
    {
      attribute: 'Book Special Feature',
      type: AttributeType.METADATA,
      label: 'Feature(s)',
    },
    {
      attribute: 'Book Language',
      type: AttributeType.METADATA,
      label: 'Language',
    },
    {
      attribute: 'Book Subject',
      type: AttributeType.METADATA,
      label: 'Subject',
    },
  ],
  [PDPTypes.PEXSVARIOUS]: [
    {
      attribute: 'description',
      type: AttributeType.PRIMARY,
      label: 'Description',
    },
    {
      attribute: 'provenance',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Provenance',
    },
    {
      attribute: 'exhibition',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Exhibition',
    },
    {
      attribute: 'literature',
      type: AttributeType.OBJECTPRIMARY,
      label: 'Literature',
    },
    {
      attribute: 'catalogueNote',
      type: AttributeType.PRIMARY,
      label: 'Catalogue Note',
    },
    {
      attribute: 'condition',
      type: AttributeType.CONDITION,
    },
    {
      attribute: 'Dimensions',
      type: AttributeType.DIMENSION,
      dimensionAttributes: [
        'Show All',
        'Height',
        'Width',
        'Depth',
        'Arm Height',
        'Seat Height',
        'Seat Depth',
        'Diameter',
      ],
    },
    {
      attribute: 'Materials',
      type: AttributeType.METADATA,
      label: 'Materials',
    },
    {
      attribute: 'Period - Specific',
      type: AttributeType.METADATA,
      label: 'Period',
    },
    {
      attribute: 'Decorative Style',
      type: AttributeType.METADATA,
      label: 'Decorative Style',
    },
    {
      attribute: 'Country',
      type: AttributeType.METADATA,
      label: 'Country of Origin',
    },
  ],
};

//ignore the following object metadata attributes for all PDPs
export const ignoreList = [
  'Researcher notes',
  'Dimension text',
  'Period - General',
  //following fields are deprecated and will be phased out from metadata
  'Provenance',
  'Exhibition',
  'Literature',
];

export const getVarianObjectAttributeKey = (objectTypeName?: string) => {
  switch (objectTypeName) {
    case 'Sneakers':
      return 'US Size';
    case 'Apparel':
      return 'Apparel Size';
    default:
      undefined;
  }
};

export const objectTypeToConfig = (key: PDPTypes) => {
  return objectConfigMap[key] || null;
};
