import React, { ReactNode } from 'react';
import { isBrowser } from '../../../client/utils/utils';
import UnexpectedErrorScreen from '../unexpected_error_screen';

type State = {
  hasError: boolean;
  errorInfo: { message?: string; url?: string }[];
};

type Props = {
  children?: ReactNode;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, errorInfo: [] };
    if (isBrowser()) {
      setTimeout(() => {
        fetch('/static/marketplace/api/slo', {
          method: 'POST',
          body: JSON.stringify({
            success: !this.state.hasError,
            errorInfo: this.state.errorInfo,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }, 5000);
    }
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    this.setState({
      ...this.state,
      errorInfo: [
        ...this.state.errorInfo,
        {
          message: error?.message,
          url: isBrowser() ? window?.location?.href : '',
        },
      ],
    });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <UnexpectedErrorScreen />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
