// Stored in src/pageUtils since files under src/pages have a specific meaning

import { Currency } from '../client/client';
import { isBrowser } from '../client/config';
import { SELECTED_CURRENCY_COOKIE_KEY } from '../client/hooks/CurrencyHook';
import { getCurrency } from 'locale-currency';

export interface ParsedUrl {
  url: string;
  language: string;
  queryString?: string;
  collectionSlug?: string;
  retailItemSlug?: string;
}

export const parseAsPath = (asPath: string): ParsedUrl => {
  const url = asPath.split('?')[0] || '';

  const urlSplitOnSlash = url.split('/').filter((value) => value !== '');
  const language = urlSplitOnSlash[0] || 'en';

  const queryString = asPath.split('?')[1];

  const maybeCollectionUrl = url.split('/buy/')[1].split('/_')[0];
  const collectionSlug = maybeCollectionUrl.startsWith('_')
    ? undefined
    : maybeCollectionUrl;

  const retailItemSlug = urlSplitOnSlash
    .find((value: string) => value.startsWith('_'))
    ?.replace('_', '');

  return {
    url,
    queryString,
    language,
    collectionSlug,
    retailItemSlug,
  };
};

export const getHost = (maybeHost: string | undefined): string | undefined => {
  if (maybeHost) {
    return maybeHost;
  } else {
    if (isBrowser()) {
      return window.location.host;
    } else {
      return undefined;
    }
  }
};

export const getUserCurrencyFromRequest = (req?: any) => {
  const currencyFromCookies = req?.cookies?.[SELECTED_CURRENCY_COOKIE_KEY];
  if (currencyFromCookies in Currency) {
    return currencyFromCookies;
  }

  const localeFromRequest = req?.headers?.['accept-language']?.split(',')?.[0];
  if (localeFromRequest) {
    const currencyFromRequest = getCurrency(localeFromRequest);
    if (currencyFromRequest in Currency) {
      return currencyFromRequest;
    }
  }

  return Currency.USD;
};
