import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';

import {
  constructClient,
  checkSession,
  AuthToken,
  getLoginUri,
  getRegisterUri,
} from '../auth';
import BaseStore from './BaseStore';
import { identifyUser } from '../utils/segmentTracking';

export enum TokenState {
  NOT_FETCHED,
  UNAUTHENTICATED,
  AUTHENTICATED,
}

export interface State {
  token: AuthToken | null;
  tokenState: TokenState;
}

export class AuthZeroStore extends BaseStore<State> {
  private client: auth0.WebAuth | null;
  private isStarted: boolean;
  private auth0Timeout: number;
  private auth0Id: string;

  constructor() {
    super({
      tokenState: TokenState.NOT_FETCHED,
      token: null,
    });
    this.auth0Timeout = 4000;
    this.isStarted = false;
    this.client = null;
    this.auth0Id = '';
  }

  public getAuth0Id(): string {
    return this.auth0Id || '';
  }

  private getClient(): auth0.WebAuth {
    if (this.client === null) {
      this.client = constructClient();
    }
    return this.client;
  }

  private doLoop(): void {
    checkSession(this.getClient(), this.auth0Timeout)
      .then((token) => {
        this.setState({ tokenState: TokenState.AUTHENTICATED, token });
        try {
          const decoded: any = jwtDecode(token.accessToken);
          this.auth0Id = decoded.sub;
        } catch {}
      })
      .catch((err) => {
        this.setState({ tokenState: TokenState.UNAUTHENTICATED, token: null });

        if (err.code !== 'login_required' || err.error !== 'login_required') {
          // TODO: bugsnag error
          console.error(err);
        }
      });
  }

  public startAuthLoop(): void {
    if (!this.isStarted) {
      this.isStarted = true;

      checkSession(this.getClient(), this.auth0Timeout)
        .then((token) => {
          const refreshTime = token.expiresIn * 1000 * 0.8;
          this.setState({ tokenState: TokenState.AUTHENTICATED, token });

          if (process.browser && window?.analytics?.identify !== undefined) {
            const decoded: any = jwtDecode(token.accessToken);
            if (decoded.sub) {
              this.auth0Id = decoded.sub;
              Sentry.setTag('auth0Id', decoded.sub);
            }
            // looking for the app metadata key here, it always ends with app_metadata,
            // but it's different between tiers
            // For example this is staging: http://schemas.sothebys-staging.com/app_metadata
            const appMetadataKey = Object.keys(decoded).find((key) => {
              return key.includes('app_metadata');
            });
            let identifyData = {
              auth0Id: decoded.sub,
            };

            // making sure that the propertie we are going to access exists
            if (
              appMetadataKey &&
              // @ts-ignore
              decoded[appMetadataKey]?.customerData
            ) {
              // populating data for identify call
              identifyData = {
                ...identifyData,
                // @ts-ignore
                customer_id: decoded[appMetadataKey]?.customerData?.partyId,
                preferred_member_flag:
                  // @ts-ignore
                  decoded[appMetadataKey]?.customerData?.preferred,
              };
            }
            identifyUser(decoded.sub, identifyData);
          }
          setInterval(() => this.doLoop(), refreshTime);
        })
        .catch((err) => {
          if (process.browser && window?.analytics?.identify !== undefined) {
            identifyUser();
          }
          this.setState({
            tokenState: TokenState.UNAUTHENTICATED,
            token: null,
          });
          if (err.code !== 'login_required' || err.error !== 'login_required') {
            // TODO: Bugsnag error!
            console.error(err);
          }
        });
    }
  }

  login() {
    window.location.href = getLoginUri(window.location.href);
  }

  loginAndRedirectToAnotherHref(destination: string) {
    window.location.href = getLoginUri(destination);
  }

  register() {
    window.location.href = getRegisterUri(window.location.href);
  }
}

export default new AuthZeroStore();
