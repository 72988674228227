import { GraphQLClient } from 'graphql-request';
import { GraphQLError, Variables } from 'graphql-request/dist/types';
import appConfig from './config';
import { isUUID } from './utils/strUtils';
import * as AlgoliaTypes from './routes/BrowsePage/AlgoliaTypes';

let graphQlClient: GraphQLClient;

const getGraphQLClient = (token?: string) => {
  if (!graphQlClient) {
    graphQlClient = new GraphQLClient(appConfig.getGrahpqlUrl());
  }
  if (token) {
    graphQlClient.setHeader('authorization', `Bearer ${token}`);
  }
  graphQlClient.setEndpoint(appConfig.getGrahpqlUrl());
  return graphQlClient;
};

const submitRequest = async <T extends any>(
  query: string,
  variables?: Variables,
  token?: string,
): Promise<{
  data?: T | undefined;
  extensions?: any;
  errors?: GraphQLError[] | undefined;
  headers?: any;
}> => {
  const response = await getGraphQLClient(token)
    .rawRequest<T>(query, variables)
    .catch((error) => {
      /*
        We want to handle partial errors more gracefully hence we resolve the repsonse if we have an response
        then when the calle needs to check if the data requirement is fulfilled or to redirect to 404 or 500 page
      */
      const dataWithPartialError: {
        data?: T | undefined;
        errors?: GraphQLError[];
        headers?: Headers;
      } = error.response;
      const uberTraceId = dataWithPartialError?.headers?.get('uber-trace-id');

      if (dataWithPartialError?.data !== null) {
        return Promise.resolve(dataWithPartialError);
      } else {
        error.uberTraceId = uberTraceId;
        throw error;
      }
    });
  return response;
};

export enum CollectionStatus {
  DRAFT,
  PUBLISHED,
  UNKNOWN,
}

export interface MerchandisingTile {
  id: string;
  placementIndex: number;
  title: string;
  url: string;
}

export interface Collection {
  title: string;
  language: string;
  collectionPath: string;
  algoliaQuery: string;
  status: CollectionStatus;
  media?: Media;
  heroDescription?: string;
  merchandisingTiles: MerchandisingTile[];
}

export interface CollectionAndAlgoliaSearchKeyResponse {
  collection: Collection;
  algoliaSearchKey: {
    key: string;
    timeToLive: string;
  };
}

export enum Currency {
  AED = 'AED',
  ARS = 'ARS',
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  PHP = 'PHP',
  QAR = 'QAR',
  RUB = 'RUB',
  SGD = 'SGD',
  TWD = 'TWD',
  USD = 'USD',
}

export interface CurrencyRate {
  currency: Currency;
  rate: string;
}
export interface CurrencyRates {
  date: string;
  baseCurrency: Currency;
  rates: CurrencyRate[];
}

export interface CurrencyRatesResponse {
  currencyRates?: CurrencyRates;
}

interface isItemInLoggedInUserCart {
  itemInLoggedInCart: boolean | null;
}

export interface CollectionParentPath {
  collectionParentPath: {
    collectionPath: string;
    title: string;
  }[];
}
export enum OfferStatus {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  COUNTERED = 'COUNTERED',
  PENDING = 'PENDING',
}
export interface MyActiveOfferType {
  offerStatus: OfferStatus;
  pricing: {
    subtotal: number;
    currency: Currency;
  };
  item: {
    offerAmount: number;
    counterOfferAmount?: number;
  };
}

export interface EnquireContactInfoInput {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  phone?: string;
}

interface SendRetailItemEnquireEmailResponse {
  sendRetailItemEnquireEmail: {
    retailItemId: string;
  };
}

interface MergeBagsResponse {
  mergeBags: {
    id: string;
  };
}

export interface MyActiveOfferResponse {
  retailItem: {
    myActiveOffer?: MyActiveOfferType;
  };
}
interface AddRetailItemToBagResponse {
  addRetailItemToBag: {
    bagId: string;
    items: Array<{
      retailItem: Pick<RetailItem, 'id' | 'itemInLoggedInCart'>;
      retailItemId: string;
      quantity: number;
    }>;
  };
}
export type Media = {
  images: {
    title?: string;
    renditions: {
      url: string;
      imageSize: string;
      width: number;
      height: number;
    }[];
  }[];
};

export enum RetailItemPropertyType {
  PeerToPeer = 'PeerToPeer',
  Marketplace = 'Marketplace',
  Confidential = 'Confidential',
  Conservation = 'Conservation',
  PrivateSaleExhibition = 'PrivateSaleExhibition',
  LiveAuctions = 'LiveAuctions',
  OnlineAuctions = 'OnlineAuctions',
  Storage = 'Storage',
  TimeBasedSale = 'TimeBasedSale',
  Valuation = 'Valuation',
}

export interface RecommendedRetailItem {
  __typename: string;
  id: string;
  sku?: string;
  retailItemId: string;
  title: string;
  slug: string;
  creators?: {
    displayName: string;
  }[];
  pricing: {
    listPrice?: number;
    currency?: Currency;
    estimatedRetailPrice?: number;
  };
  media?: Media;
  propertyType?: RetailItemPropertyType;
  salesEntity?: AlgoliaTypes.Hit['salesEntity'];
  objects?: {
    objectTypeName?: string;
    metadata: Array<RetailItemMetadata>;
  }[];
  availableQuantity?: number;
  category?: {
    categoryId: number;
    name: string;
  }[];
  url?: string;
}

export interface LowHighEstimateV2 {
  __typename: string;
  highEstimate: {
    amount: number;
    currency: string;
  };
  lowEstimate: {
    amount: number;
    currency: string;
  };
}
export interface EstimateUponRequest {
  __typename: string;
  estimateUponRequest: boolean;
}

export type EstimateV2 = EstimateUponRequest | LowHighEstimateV2;
export interface AuctionSlug {
  __typename: 'AuctionSlug';
  name: string;
  year: string;
}
export interface LotSlug {
  __typename: 'LotSlug';
  auctionSlug: AuctionSlug;
  lotSlug: string;
}
export interface RecommendedLot {
  __typename: string;
  id: string;
  lotId: string;
  auction: {
    auctionId: string;
  };
  title: string;
  creators?: {
    displayName: string;
  }[];
  estimateV2?: EstimateV2;
  media?: Media;
  lotSlug: LotSlug;
}

export type SimilarItem = RecommendedRetailItem | RecommendedLot;

export type VariantGroup = {
  id: string;
  name: string;
  variants: Variant[];
};

export type Variant = {
  id: string;
  retailItemId: string;
  variantId: string;
  value: string;
  listPrice: number;
  sku: string;
  availableQuantity: number;
  isAvailable: boolean;
  groupName?: string;
};
export interface RetailItem {
  id: string;
  retailItemId: string;
  sku?: string;
  itemInLoggedInCart?: boolean;
  title: string;
  description?: string;
  catalogueNote?: string;
  attribution?: string;
  guaranteeLine?: string;
  saleDesignationLine?: string;
  isAvailable: boolean;
  isOwned: boolean;
  availableQuantity?: number | null;
  category?: {
    categoryId: number;
    name: string;
  }[];
  department?: {
    departmentId: string;
    title: string;
  };
  creators?: {
    displayName: string;
  }[];
  pricing: {
    listPrice?: number;
    listPriceRange?: { from?: number; to?: number };
    currency?: Currency;
    estimatedRetailPrice?: number;
    minOffer?: number;
  };
  propertyType: RetailItemPropertyType;
  condition: {
    overall?: number;
    notes?: string;
  };
  config: {
    offerEnabled: boolean;
    enquireEnabled: boolean;
    buyNowEnabled: boolean;
    managerEmail?: string;
  };
  media?: Media;
  objects?: RetailItemObject[];
  tags?: {
    tagLabel: string;
  }[];
  shipping?: RetailItemShipping;
  salesEntity?: AlgoliaTypes.Hit['salesEntity'];
  variantGroup: VariantGroup;
  url?: string;
}

export type RetailItemObject = {
  objectTypeName?: string;
  dimensionMetadata: Array<DimensionMetadata>;
  metadata: Array<RetailItemMetadata>;
  sothebysApproved: boolean;
  provenance?: string;
  exhibition?: string;
  literature?: string;
};

export type RetailItemShipping = {
  id: string;
  hasFreeShipping?: boolean;
  originAddress?: {
    city?: string;
    state?: string;
    countryISO?: {
      englishShortNameReadingOrder: string;
    };
  };
  availableShippingDestinations: {
    id: string;
    englishShortNameReadingOrder: string;
  }[];
  shipsInternationally: boolean;
  offeredServiceTypes?: string[];
};

export type DimensionMetadata = {
  value: number;
  key: string;
  unit: string;
};

export type RetailItemMetadata = {
  value: {
    __typename: string;
    stringValue?: string;
    stringValues?: string[];
    boolValue?: boolean;
    integerValue?: number;
    floatValue?: number;
  };
  key: string;
};

interface getBagByIdResponse {
  bagById: {
    items: {
      retailItemId: string;
      quantity: number;
    }[];
  } | null;
}

interface getBagForLoggedInUserResponse {
  bag: {
    items: {
      retailItemId: string;
      quantity: number;
    }[];
  } | null;
}

interface getBagsForLoggedInUserResponse {
  bags: {
    defaultBag: {
      items: {
        retailItemId: string;
        quantity: number;
      }[];
    } | null;
    alcoholBag: {
      items: {
        retailItemId: string;
        quantity: number;
      }[];
    } | null;
  };
}

export type BagItem = {
  retailItemId: string;
  quantity: number;
};

export async function getCollectionAndAlgoliaSearchKey(
  path: string,
  language: string,
  fetcher = submitRequest,
): Promise<CollectionAndAlgoliaSearchKeyResponse> {
  const query = /* GraphQL */ `
    query getCollectionAndAlgoliaSearchKey(
      $collectionPath: String!
      $language: String!
    ) {
      collection(collectionPath: $collectionPath, language: $language) {
        algoliaQuery
        collectionPath
        language
        status
        title
        heroDescription
        media {
          images {
            renditions {
              url
              width
              height
              imageSize
            }
          }
        }
        merchandisingTiles {
          id
          placementIndex
          title
          url
        }
      }
      algoliaSearchKey {
        key
        timeToLive
      }
    }
  `;

  const variables = {
    collectionPath: path,
    language: language,
  };
  const result = await fetcher<CollectionAndAlgoliaSearchKeyResponse>(
    query,
    variables,
  );
  const uberTraceId = result?.headers?.get('uber-trace-id');

  if (result?.data?.algoliaSearchKey && result?.data?.collection) {
    return result?.data;
  } else {
    let errorMsg = '';
    if (result?.data?.algoliaSearchKey === undefined) {
      errorMsg = 'Algolia search key not found';
    } else {
      errorMsg = `Collection: ${path} not found`;
    }
    const err: any = new Error(errorMsg);
    err.statusCode = 404;
    err.uberTraceId = uberTraceId;
    throw err;
  }
}

export interface CollectionParentPathResponse {
  data?: CollectionParentPath;
  extensions?: any;
  errors?: GraphQLError[] | undefined;
}

export async function getCollectionParentPath(
  path: string | undefined,
  language: string,
  fetcher = submitRequest,
): Promise<CollectionParentPath | undefined> {
  if (path === undefined) return undefined;

  const query = `query getParentPathCollections {
    collectionParentPath(collectionPath: "${path}", language: "${language}") {
      collectionPath
      title
    }
  }
  `;

  const result = await fetcher<CollectionParentPath>(query);

  const uberTraceId = result?.headers?.get('uber-trace-id');

  if (result?.data) {
    return result?.data;
  } else {
    let errorMsg = `Failed to fetch collection parent path for: ${path}`;
    const err: any = new Error(errorMsg);
    err.statusCode = 404;
    err.uberTraceId = uberTraceId;
    throw err;
  }
}

const retailItemQuery = `
  id
  retailItemId
  sku
  guaranteeLine
  catalogueNote
  itemInLoggedInCart
  isAvailable
  condition {
    overall
    notes
  }
  description
  attribution
  isOwned
  creators {
    displayName
  }
  config {
    offerEnabled
    enquireEnabled
    buyNowEnabled
    managerEmail
  }
  category {
    name
    categoryId
  }
  department {
    title
  }
  media {
    images {
      title
      renditions {
        url
        imageSize
      }
    }
  }
  pricing {
    currency
    listPrice
    estimatedRetailPrice
    minOffer
  }
  propertyType
  saleDesignationLine
  title
  objects {
    objectTypeName
    sothebysApproved
    provenance
    exhibition
    literature
    metadata {
      value {
        ... on ObjectMetadataStringValue {
          __typename
          stringValue
        }
        ... on ObjectMetadataStringValues {
          __typename
          stringValues
        }
        ... on ObjectMetadataBooleanValue {
          __typename
          boolValue
        }
        ... on ObjectMetadataIntegerValue {
          __typename
          integerValue
        }
        ... on ObjectMetadataFloatValue {
          __typename
          floatValue
        }
      }
      key
    }
    dimensionMetadata {
      key
      value
      unit
    }
  }
  tags {
    tagLabel
  }
  shipping {
    id
    retailItemId
    hasFreeShipping
    originAddress {
      city
      state
      countryISO {
        englishShortNameReadingOrder
      }
    }
    availableShippingDestinations {
      id
      englishShortNameReadingOrder
    }
    shipsInternationally
    offeredServiceTypes
  }
  availableQuantity
  salesEntity
  variantGroup {
    id
    name
    variants {
      id
      retailItemId
      variantId
      sku
      value
      availableQuantity
      listPrice
      isAvailable
      groupName
    }
  }
  url
`;

export async function getRetailItem(
  itemId: string,
  language: string,
  fetcher = submitRequest,
): Promise<RetailItem> {
  if (isUUID(itemId)) {
    const query = /* GraphQL */ `
      query getRetailItem($id: String!) {
        retailItem(id: $id) {
          ${retailItemQuery}
        }
      }
    `;

    const variables = {
      id: itemId,
      language: language,
    };

    const serverResponse = await fetcher<{ retailItem: RetailItem }>(
      query,
      variables,
    );

    const result = serverResponse?.data;
    const apiErrors = serverResponse?.errors;
    const uberTraceId = serverResponse?.headers?.get('uber-trace-id');

    if (result?.retailItem) {
      return result?.retailItem;
    } else {
      // Retail item queries are the entry point to PDPs, so we are interested in capturing the API status code in case of errors
      const [statusCode, message] = errorStatusAndMessage(apiErrors, [
        404,
        `Retail Item by id: ${itemId}, not found`,
      ]);
      const err: any = new Error(message);
      err.statusCode = statusCode;
      err.uberTraceId = uberTraceId;
      throw err;
    }
  } else {
    const query = /* GraphQL */ `
      query getRetailItemBySlug($slug: String!) {
        retailItemBySlug(slug: $slug) {
          ${retailItemQuery}
        }
      }
    `;

    const variables = {
      slug: itemId,
    };

    const serverResponse = await fetcher<{
      retailItemBySlug: RetailItem;
    }>(query, variables);
    const result = serverResponse?.data;
    const apiErrors = serverResponse?.errors;
    const uberTraceId = serverResponse?.headers?.get('uber-trace-id');

    if (result?.retailItemBySlug) {
      return result?.retailItemBySlug;
    } else {
      // Retail item queries are the entry point to PDPs, so we are interested in capturing the API status code in case of errors
      const [statusCode, message] = errorStatusAndMessage(apiErrors, [
        404,
        `Retail Item with slug: ${itemId}, not found`,
      ]);
      const err: any = new Error(message);
      err.statusCode = statusCode;
      err.uberTraceId = uberTraceId;
      throw err;
    }
  }
}

type RecommendationSimilarItemsType = 'RETAILITEMS' | 'LOTS';

export async function getRecommendations(
  itemId: string,
  language: string,
  limit: number,
  type?: RecommendationSimilarItemsType, // will return both lots and retail items if no type is set
  fetcher = submitRequest,
): Promise<SimilarItem[] | undefined> {
  const query = /* GraphQL */ `
    query getRetailItemRecommendations(
      $retailItemId: String!
      $limit: Int
      $type: SimilarItemsType
    ) {
      retailItem(id: $retailItemId) {
        recommendations(hideNonAvailable: true, limit: $limit, type: $type) {
          __typename
          ... on RetailItem {
            id
            sku
            retailItemId
            slug
            title
            creators {
              displayName
            }
            pricing {
              listPrice
              currency
              estimatedRetailPrice
            }
            salesEntity
            propertyType
            media {
              images {
                title
                renditions {
                  url
                  imageSize
                }
              }
            }
            availableQuantity
            category {
              categoryId
              name
            }
            url
            objects {
              objectTypeName
              metadata {
                value {
                  ... on ObjectMetadataStringValue {
                    __typename
                    stringValue
                  }
                  ... on ObjectMetadataStringValues {
                    __typename
                    stringValues
                  }
                  ... on ObjectMetadataBooleanValue {
                    __typename
                    boolValue
                  }
                  ... on ObjectMetadataIntegerValue {
                    __typename
                    integerValue
                  }
                  ... on ObjectMetadataFloatValue {
                    __typename
                    floatValue
                  }
                }
                key
              }
            }
          }
          ... on LotCard {
            id
            title
            creators {
              displayName
            }
            estimateV2 {
              ... on LowHighEstimateV2 {
                __typename
                highEstimate {
                  amount
                  currency
                }
                lowEstimate {
                  amount
                  currency
                }
              }
              ... on EstimateUponRequest {
                __typename
                estimateUponRequest
              }
            }
            media {
              images {
                title
                renditions {
                  url
                  imageSize
                }
              }
            }
            lotId
            auction {
              auctionId
            }
            lotSlug: slug {
              lotSlug
              auctionSlug {
                name
                year
              }
            }
          }
        }
      }
    }
  `;

  const variables = {
    retailItemId: itemId,
    language: language,
    limit: limit,
    type: type,
  };

  const serverResponse = await fetcher<{
    retailItem: { recommendations: SimilarItem[] };
  }>(query, variables);

  const result = serverResponse?.data;
  const apiErrors = serverResponse?.errors;
  const uberTraceId = serverResponse?.headers?.get('uber-trace-id');

  if (apiErrors) {
    const [statusCode, message] = errorStatusAndMessage(apiErrors, [
      500,
      `Error fetching recommendations for retailItemId: ${itemId}`,
    ]);
    const err: any = new Error(message);
    err.statusCode = statusCode;
    err.uberTraceId = uberTraceId;
    throw err;
  }

  if (result?.retailItem?.recommendations) {
    return result?.retailItem?.recommendations;
  }
}

export async function isItemInLoggedInUserCart(
  token: string,
  itemId: string,
  fetcher = submitRequest,
): Promise<boolean | null> {
  const query = /* GraphQL */ `
    query isItemInLoggedInUserCart($id: String!) {
      retailItem(id: $id) {
        itemInLoggedInCart
      }
    }
  `;
  const variables = {
    id: itemId,
  };

  const serverResponse = await fetcher<{
    retailItem: isItemInLoggedInUserCart;
  }>(query, variables, token);

  const result = serverResponse?.data;
  const uberTraceId = serverResponse?.headers?.get('uber-trace-id');

  if (result?.retailItem) {
    return result?.retailItem.itemInLoggedInCart;
  } else {
    const err: any = new Error(`Retail Item by id: ${itemId}, not found`);
    err.statusCode = 404;
    err.uberTraceId = uberTraceId;
    throw err;
  }
}

export async function getMyActiveOffer(
  token: string,
  retailItemId: string,
  fetcher = submitRequest,
): Promise<MyActiveOfferResponse | undefined> {
  const query = /* GraphQL */ `
    query getMyActiveOffer($id: String!) {
      retailItem(id: $id) {
        myActiveOffer {
          offerStatus
          pricing {
            subtotal
            currency
          }
          item {
            offerAmount
            counterOfferAmount
          }
        }
      }
    }
  `;
  const variables = {
    id: retailItemId,
  };

  const result = await fetcher<MyActiveOfferResponse | undefined>(
    query,
    variables,
    token,
  );
  const uberTraceId = result?.headers?.get('uber-trace-id');

  if (result?.data) {
    return result?.data;
  } else {
    const err: any = new Error(
      `No active offers for retail item: ${retailItemId}, found`,
    );
    err.statusCode = 404;
    err.uberTraceId = uberTraceId;
    throw err;
  }
}

export interface AddToBagResponseData {
  itemInLoggedInCart: boolean;
  anonymousBagId?: string;
  items?: BagItem[];
}
export async function addToBag(
  productId: string,
  quantity: number,
  bagId: string | null,
  token?: string,
  fetcher = submitRequest,
): Promise<AddToBagResponseData> {
  const query = /* GraphQL */ `
    mutation addRetailItemToBag(
      $retailItemId: String!
      $bagId: String
      $quantity: Int
    ) {
      addRetailItemToBag(
        retailItemId: $retailItemId
        bagId: $bagId
        quantity: $quantity
      ) {
        bagId
        items {
          quantity
          retailItemId
          retailItem {
            id
            itemInLoggedInCart
          }
        }
      }
    }
  `;

  const variables = {
    retailItemId: productId,
    bagId,
    quantity,
  };

  const serverResponse = await fetcher<AddRetailItemToBagResponse>(
    query,
    variables,
    token,
  );
  const uberTraceId = serverResponse?.headers?.get('uber-trace-id');

  const result = serverResponse?.data;
  if (result?.addRetailItemToBag) {
    const response: AddToBagResponseData = {
      itemInLoggedInCart: result.addRetailItemToBag.items.some((item) => {
        return item.retailItemId == productId;
      }),
      anonymousBagId:
        token === undefined ? result.addRetailItemToBag.bagId : undefined,
      items: result.addRetailItemToBag.items.map((item) => ({
        retailItemId: item.retailItemId,
        quantity: item.quantity,
      })),
    };

    return response;
  } else {
    const err: any = new Error(
      `Unable to add retail item by id: ${productId} to bag`,
    );
    err.statusCode = 500;
    err.uberTraceId = uberTraceId;
    throw err;
  }
}

export async function getCurrencyRates(
  baseCurrency: string,
  fetcher = submitRequest,
): Promise<CurrencyRatesResponse | undefined> {
  const query = /* GraphQL */ `
    query getCurrencyRates($baseCurrency: Currency!) {
      currencyRates(base: $baseCurrency) {
        date
        baseCurrency
        rates {
          currency
          rate
        }
      }
    }
  `;

  const variables = {
    baseCurrency: baseCurrency,
  };

  const result = await fetcher<CurrencyRatesResponse | undefined>(
    query,
    variables,
  );
  return result?.data;
}

export async function getBagsForLoggedInUser(
  token?: string,
  fetcher = submitRequest,
): Promise<getBagsForLoggedInUserResponse | undefined> {
  const query = `
    query bags {
      bags {
        defaultBag {
          id
          items {
            retailItemId
            quantity
          }
        }
        alcoholBag {
          id
          items {
            retailItemId
            quantity
          }
        }
      }
    }
  `;

  const result = await fetcher<getBagsForLoggedInUserResponse | undefined>(
    query,
    {},
    token,
  );

  return result?.data;
}

export async function getBagForLoggedInUser(
  token?: string,
  fetcher = submitRequest,
): Promise<getBagForLoggedInUserResponse | undefined> {
  const query = `
    query bag {
      bag {
        id
        items {
          retailItemId
          quantity
        }
      }
    }
  `;

  const result = await fetcher<getBagForLoggedInUserResponse | undefined>(
    query,
    {},
    token,
  );

  return result?.data;
}

export async function getBagById(
  id: string,
  fetcher = submitRequest,
): Promise<getBagByIdResponse | undefined> {
  const query = /* GraphQL */ `
    query bagById($id: ID!) {
      bagById(id: $id) {
        id
        items {
          retailItemId
          quantity
        }
      }
    }
  `;

  const variables = {
    id: id,
  };

  const result = await fetcher<getBagByIdResponse | undefined>(
    query,
    variables,
  );
  return result?.data;
}

export async function mergeBags(
  token: string,
  guestBagId: string,
  fetcher = submitRequest,
): Promise<MergeBagsResponse | undefined> {
  const mutation = /* GraphQL */ `
    mutation mergeBags($guestBagId: String!) {
      mergeBags(guestBagId: $guestBagId) {
        id
      }
    }
  `;

  const variables = {
    guestBagId: guestBagId,
  };

  const serverResponse = await fetcher<MergeBagsResponse>(
    mutation,
    variables,
    token,
  );
  return serverResponse?.data;
}

export async function sendEnquireEmail(
  token: string,
  productId: string,
  contactInfoInput: EnquireContactInfoInput,
  fetcher = submitRequest,
): Promise<SendRetailItemEnquireEmailResponse | undefined> {
  const mutation = /* GraphQL */ `
    mutation sendRetailItemEnquireEmail(
      $retailItemId: String!
      $contactInfo: EnquireContactInfoInput!
    ) {
      sendRetailItemEnquireEmail(
        retailItemId: $retailItemId
        contactInfo: $contactInfo
      ) {
        retailItemId
      }
    }
  `;

  const variables = {
    retailItemId: productId,
    contactInfo: contactInfoInput,
  };

  const serverResponse = await fetcher<SendRetailItemEnquireEmailResponse>(
    mutation,
    variables,
    token,
  );
  return serverResponse?.data;
}

// Given a list of GraphQLError that all share the same error code within the extensions object
// Returns a tuple of the error code and combined messages of all errors
export function errorStatusAndMessage(
  errs: GraphQLError[] | undefined,
  onEmpty: [number, string],
): [number, string] {
  if (errs && errs.length > 0) {
    let statusCodes = new Set<number>();
    let messages: string[] = [];
    errs.forEach((err) => {
      messages.push(err.message);
      const statusCode = statusCodeFromError(err);
      statusCode && statusCodes.add(statusCode.code);
    });
    if (statusCodes.size == 1) {
      return [statusCodes.values().next().value, messages.join(', ')];
    }
  }
  return onEmpty;
}

function statusCodeFromError(
  err: GraphQLError | undefined,
): { code: number } | undefined {
  if (
    err?.extensions?.statusCode &&
    typeof err?.extensions?.statusCode === 'number'
  ) {
    return { code: err?.extensions?.statusCode };
  } else if (err?.extensions?.code == 'INTERNAL_SERVER_ERROR') {
    return { code: 500 };
  } else {
    return undefined;
  }
}
