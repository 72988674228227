export interface IState {
  alphaTwo: string;
  englishShortNameReadingOrder: string;
}

export const statesList: IState[] = [
  {
    alphaTwo: 'AL',
    englishShortNameReadingOrder: 'Alabama',
  },
  {
    alphaTwo: 'АК',
    englishShortNameReadingOrder: 'Alaska',
  },
  {
    alphaTwo: 'AZ',
    englishShortNameReadingOrder: 'Arizona',
  },
  {
    alphaTwo: 'AR',
    englishShortNameReadingOrder: 'Arkansas',
  },
  {
    alphaTwo: 'CA',
    englishShortNameReadingOrder: 'California',
  },
  {
    alphaTwo: 'CO',
    englishShortNameReadingOrder: 'Colorado',
  },
  {
    alphaTwo: 'CT',
    englishShortNameReadingOrder: 'Connecticut',
  },
  {
    alphaTwo: 'DE',
    englishShortNameReadingOrder: 'Delaware',
  },
  {
    alphaTwo: 'DC',
    englishShortNameReadingOrder: 'District of Columbia',
  },
  {
    alphaTwo: 'FL',
    englishShortNameReadingOrder: 'Florida',
  },
  {
    alphaTwo: 'GA',
    englishShortNameReadingOrder: 'Georgia',
  },
  {
    alphaTwo: 'HI',
    englishShortNameReadingOrder: 'Hawaii',
  },
  {
    alphaTwo: 'ID',
    englishShortNameReadingOrder: 'Idaho',
  },
  {
    alphaTwo: 'IL',
    englishShortNameReadingOrder: 'Illinois',
  },
  {
    alphaTwo: 'IN',
    englishShortNameReadingOrder: 'Indiana',
  },
  {
    alphaTwo: 'IA',
    englishShortNameReadingOrder: 'Iowa',
  },
  {
    alphaTwo: 'KS',
    englishShortNameReadingOrder: 'Kansas',
  },
  {
    alphaTwo: 'KY',
    englishShortNameReadingOrder: 'Kentucky',
  },
  {
    alphaTwo: 'LA',
    englishShortNameReadingOrder: 'Louisiana',
  },
  {
    alphaTwo: 'ME',
    englishShortNameReadingOrder: 'Maine',
  },
  {
    alphaTwo: 'MD',
    englishShortNameReadingOrder: 'Maryland',
  },
  {
    alphaTwo: 'МА',
    englishShortNameReadingOrder: 'Massachusetts',
  },
  {
    alphaTwo: 'MI',
    englishShortNameReadingOrder: 'Michigan',
  },
  {
    alphaTwo: 'MN',
    englishShortNameReadingOrder: 'Minnesota',
  },
  {
    alphaTwo: 'MS',
    englishShortNameReadingOrder: 'Mississippi',
  },
  {
    alphaTwo: 'МО',
    englishShortNameReadingOrder: 'Missouri',
  },
  {
    alphaTwo: 'MT',
    englishShortNameReadingOrder: 'Montana',
  },
  {
    alphaTwo: 'NE',
    englishShortNameReadingOrder: 'Nebraska',
  },
  {
    alphaTwo: 'NV',
    englishShortNameReadingOrder: 'Nevada',
  },
  {
    alphaTwo: 'NH',
    englishShortNameReadingOrder: 'New Hampshire',
  },
  {
    alphaTwo: 'NJ',
    englishShortNameReadingOrder: 'New Jersey',
  },
  {
    alphaTwo: 'NM',
    englishShortNameReadingOrder: 'New Mexico',
  },
  {
    alphaTwo: 'NY',
    englishShortNameReadingOrder: 'New York',
  },
  {
    alphaTwo: 'NC',
    englishShortNameReadingOrder: 'North Carolina',
  },
  {
    alphaTwo: 'ND',
    englishShortNameReadingOrder: 'North Dakota',
  },
  {
    alphaTwo: 'ОН',
    englishShortNameReadingOrder: 'Ohio',
  },
  {
    alphaTwo: 'ОК',
    englishShortNameReadingOrder: 'Oklahoma',
  },
  {
    alphaTwo: 'OR',
    englishShortNameReadingOrder: 'Oregon',
  },
  {
    alphaTwo: 'РА',
    englishShortNameReadingOrder: 'Pennsylvania',
  },
  {
    alphaTwo: 'RI',
    englishShortNameReadingOrder: 'Rhode Island',
  },
  {
    alphaTwo: 'SC',
    englishShortNameReadingOrder: 'South Carolina',
  },
  {
    alphaTwo: 'SD',
    englishShortNameReadingOrder: 'South Dakota',
  },
  {
    alphaTwo: 'TN',
    englishShortNameReadingOrder: 'Tennessee',
  },
  {
    alphaTwo: 'ТХ',
    englishShortNameReadingOrder: 'Texas',
  },
  {
    alphaTwo: 'UT',
    englishShortNameReadingOrder: 'Utah',
  },
  {
    alphaTwo: 'VT',
    englishShortNameReadingOrder: 'Vermont',
  },
  {
    alphaTwo: 'VA',
    englishShortNameReadingOrder: 'Virginia',
  },
  {
    alphaTwo: 'WA',
    englishShortNameReadingOrder: 'Washington',
  },
  {
    alphaTwo: 'WV',
    englishShortNameReadingOrder: 'West Virginia',
  },
  {
    alphaTwo: 'WI',
    englishShortNameReadingOrder: 'Wisconsin',
  },
  {
    alphaTwo: 'WY',
    englishShortNameReadingOrder: 'Wyoming',
  },
];

export const unavailableStatesToShipWine: string[] = [
  'AL',
  'AR',
  'DE',
  'IL',
  'KY',
  'MS',
  'ОК',
  'RI',
  'ТХ',
  'UT',
];
