/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface AbsenteeRegistrationComplete {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AccountProfileCompleted {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
}
export interface AccountProfileUpdateSubmitted {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
}
export interface AddToBagClicked {
	/**
	 * Price*.25. Used for GoogleAd optimization
	 */
	atc_value?: number
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * For this event it should be "cart", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, should be array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, same as price, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Image URL of the product image
	 */
	image_url?: string
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Viking lot guid
	 */
	lot_viking_id?: string
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * Boolean which is true if international shipping is available for the item
	 */
	ships_internationally?: boolean | null
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Tags, should be optional string, MP-1327
	 */
	tags?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface AdditionalInformationNeeded {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
}
export interface AuctionDetailsInteraction {
	/**
	 * Type of auction interaction
	 */
	auction_interaction: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionDetailsViewed {
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
}
export interface AuctionFilterApplied {
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Filter that was applied that triggered the event
	 */
	filter_applied?: string
	/**
	 * Denotes the UI filter widget type and is useful, for example, when AB testing
	 */
	filter_widget_type?: string
	/**
	 * Product filters that the customer is using
	 */
	filters?: any[]
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Number of search filters applied
	 */
	num_search_filters: number
	/**
	 * The number of search results returned
	 */
	num_search_results: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * T'he search term that was initially entered
	 */
	search_keyword?: string
}
export interface AuctionFilterCleared {
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Product filters that the customer is using
	 */
	filters?: any[]
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Number of search filters applied
	 */
	num_search_filters: number
	/**
	 * Number of search filters applied
	 */
	num_search_results?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionLandingCarouselClick {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * URL of lot clicked in carousel
	 */
	lot_carousel_url?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionPageView {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionRegistrationAccountSelected {
	/**
	 * Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
	 */
	auction_registration_origin: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source?: string
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionRegistrationBiddingMethodSelected {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source?: string
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionRegistrationButtonClicked {
	/**
	 * Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
	 */
	auction_registration_origin: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	registration_source?: any | null
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean
	sale_id?: any | null
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
}
export interface AuctionRegistrationCompleted {
	/**
	 * Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
	 */
	auction_registration_origin: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * number of items selected
	 */
	num_products?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: string
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source?: string
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface AuctionRegistrationLotsSelected {
	/**
	 * Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
	 */
	auction_registration_origin: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * number of items selected
	 */
	num_products?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source?: string
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionRegistrationPhoneBiddingClicked {
	/**
	 * Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
	 */
	auction_registration_origin: string
	/**
	 * For Zaius, "live_online" when user registers for online bidding, "telephone_bid" with telephone registration, or "sale_room_bid" with paddle registration
	 */
	bid_method: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Source of registration
	 */
	registration_source: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface AuctionRegistrationPhoneBiddingConfirmed {
	/**
	 * Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
	 */
	auction_registration_origin: string
	/**
	 * For Zaius, "live_online" when user registers for online bidding, "telephone_bid" with telephone registration, or "sale_room_bid" with paddle registration
	 */
	bid_method: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Number of lots selected
	 */
	num_lots_selected: string
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Source of registration
	 */
	registration_source: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface AuctionRoomEntered {
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * Auction name
	 */
	auction_name?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Matches "bidder" if logged into bidding room as a bidder, and "watcher" if not
	 */
	participant_type?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface AuctionRoomInteraction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Interaction within the bidding room
	 */
	bidding_interaction_type: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Matches "bidder" if logged into bidding room as a bidder, and "watcher" if not
	 */
	participant_type?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface AudioPlaybackPaused {
	''?: Record<string, any>
	/**
	 * User's Auth0 value
	 */
	auth0_id?: Record<string, any>
	/**
	 * Chapter title
	 */
	chapter_title: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface AudioPlaybackStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: Record<string, any>
	/**
	 * Chapter title
	 */
	chapter_title: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface AudioStreamingToggle {
	/**
	 * Viking auction guid

	 */
	auction_id?: string
	/**
	 * Auction name

	 */
	auction_name?: string
	/**
	 * The state of the audio stream toggle after interaction (pressing the audio icon)
	 */
	audio_stream_toggle?: string
	/**
	 * Whether the user is turning the audio on or off
	 */
	audio_streaming_toggle_to: boolean
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface Authenticated {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * email address
	 */
	email?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * If SSO login is true or false
	 */
	sso_login: boolean
}
export interface BiDnowRegistrationComplete {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface BidConfirmationInteraction {
	/**
	 * The interaction that the user initiated on the bid confirmation screen
	 */
	bid_confirmation_interaction_type?: string
	/**
	 * Result of the bid placed
	 */
	bid_result?: string
}
export interface BidPlaced {
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * Auction name
	 */
	auction_name?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Maximum bid amount
	 */
	bid_amount: number
	/**
	 * Currency of bid placed
	 */
	bid_currency: string
	/**
	 * Result of bid placed
	 */
	bid_result: string
	/**
	 * Type of bid placed
	 */
	bid_type: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * Lot name
	 */
	lot_name?: string
	/**
	 * Viking lot GUID
	 */
	lot_viking_id: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface BidStepCompleted {
	bid_amount?: number
}
export interface CarouselClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Style of carousel
	 */
	carousel_style?: string
	/**
	 * Title of carousel
	 */
	carousel_title?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface ProductsItem {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Secondary category of product
	 */
	category_2?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	name?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * TRUE if the product has become unavailable at the time of the cart related event being sent (e.g. cart viewed or product removed)
	 */
	product_unavailable?: boolean
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Boolean which is true if
 international shipping is available for the item
	 */
	ships_internationally?: boolean | null
}
export interface CartViewed {
	/**
	 * ID of the cart being viewed
	 */
	cart_id?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * for this event this property should be "cart", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap item id, array of strings MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * true if logged in, false if not  MP-1226
	 */
	logged_in?: boolean
	/**
	 * If there is no cart available (or empty cart) set this to TRUE
	 */
	no_cart_available?: boolean
	/**
	 * Products within a cart
	 */
	products?: ProductsItem[]
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
}
export interface ChapterNavigationClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Chapter title
	 */
	chapter_title: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Navigation level
	 */
	navigation_level?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ChapterViewed {
	''?: Record<string, any>
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Chapter title
	 */
	chapter_title: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ProductsItem1 {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	name?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface CheckoutError {
	cart_id?: string
	/**
	 * Checkout type purchase or make an offer
	 */
	checkout_type?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * departments concatenated to a string, delimited by a |
	 */
	department_category?: string
	/**
	 * The description of the error that is received, e.g. "bad postal code"
	 */
	error_description: string
	/**
	 * Error message
	 */
	error_msg?: string
	/**
	 * Products within a cart
	 */
	products?: ProductsItem1[]
	/**
	 * The step that the error occured in
	 */
	step_of_checkout?: string
	/**
	 * Zip code entered (if applicable)
	 */
	zip_code?: string
}
export interface ProductsItem2 {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Secondary category of product
	 */
	category_2?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	name?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	ships_internationally?: boolean | null
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface CheckoutStarted {
	cart_id?: string
	/**
	 * Checkout type purchase or make an offer
	 */
	checkout_type?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * all departments concatenated to a string, delimited by a |
	 */
	department_category?: string
	order_id?: string
	/**
	 * Products within a cart
	 */
	products?: ProductsItem2[]
	/**
	 * Revenue associated with the transaction (excluding shipping and tax)
	 */
	revenue?: number
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface ProductsItem3 {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	name?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: Record<string, any>
}
export interface CheckoutStepCompleted {
	cart_id?: string
	/**
	 * Checkout type purchase or make an offer
	 */
	checkout_type?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * departments concatenated to a string, delimited by a |
	 */
	department_category?: string
	/**
	 * True if the product has variants enabled and available
	 */
	has_variants?: boolean
	order_id?: string
	/**
	 * Products within a cart
	 */
	products?: ProductsItem3[]
	/**
	 * Number representing a step in the checkout process
	 */
	step?: number
	/**
	 * Name of the step that was completed
	 */
	step_name?: string
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface ProductsItem4 {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Secondary category of product
	 */
	cateory_2?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	name?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * TRUE if the product has become unavailable at the time of the cart related event being sent (e.g. cart viewed or product removed)
	 */
	product_unavailable?: boolean
	/**
	 * Quantity of the product
	 */
	quantity?: number
	shipping_method?: string | null
	ships_internationally?: boolean | null
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface CheckoutStepViewed {
	cart_id?: string
	/**
	 * Checkout type purchase or make an offer
	 */
	checkout_type?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * departments concatenated to a string, delimited by a |
	 */
	department_category?: string
	/**
	 * True if the product has variants enabled and available
	 */
	has_variants?: boolean
	order_id?: Record<string, any>
	/**
	 * Products within a cart
	 */
	products?: ProductsItem4[]
	revenue?: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number | null
	/**
	 * Number representing a step in the checkout process
	 */
	step?: number
	/**
	 * NAme of the step being viewed, e.g. "Shipping Address"
	 */
	step_name?: string
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
}
export interface ClickAction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * The type of click that triggered the event, e.g. \"carousel click\", \"contact us\" etc.
	 */
	click_type: string
	/**
	 * Section of the site
	 */
	content_type: string
	/**
	 * Call to action text, the text on the button that was clicked
	 */
	cta_text?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id?: string | null
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type?: string | null
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
}
export interface CompleteYourProfileClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ConditionsOfSaleViewed {
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
}
export interface ConfirmEmailAddressRequested {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
}
export interface ConfirmQuickBidButton {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Maximum bid amount
	 */
	bid_amount: number
	/**
	 * Currency of bid placed
	 */
	bid_currency: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface ConsignmentCategorySelected {
	consignment_category?: string
	consignment_sub_category?: string
}
export interface ConsignmentErrorItemDetails {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface ConsignmentErrorPicsDocs {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ConsignmentItemDetails {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ConsignmentItemDetailsEntered {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ConsignmentResumed {
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Step; in consignment flow equals "category", "details", "photos", "address", "review"
	 */
	step?: string
}
export interface ConsignmentReview {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ConsignmentSignInSelected {
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Step; in consignment flow equals "category", "details", "photos", "address", "review"
	 */
	step?: string
}
export interface ConsignmentSignUpSelected {
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Step; in consignment flow equals "category", "details", "photos", "address", "review"
	 */
	step?: string
}
export interface ConsignmentStarted {
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
}
export interface ConsignmentStepCompleted {
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Step; in consignment flow equals "category", "details", "photos", "address", "review"
	 */
	step?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface ConsignmentStepError {
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Step; in consignment flow equals "category", "details", "photos", "address", "review"
	 */
	step?: string
}
export interface ConsignmentStepViewed {
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Step; in consignment flow equals "category", "details", "photos", "address", "review"
	 */
	step?: string
}
export interface ConsignmentSubmit {
	/**
	 * Reference code
	 */
	auction_estimate_referencecode?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * Country location code of potential consignor
	 */
	consignor_location_country_code?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * Country location code of item
	 */
	item_location_country_code?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface ConsignmentSubmitted {
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
}
export interface ConsignmentUploads {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Category of object that user is uploading to consignment tool
	 */
	consignment_category?: string
	/**
	 * Unique ID of the consignment submission
	 */
	consignment_item_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ContentCardClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Details within content card
	 */
	card_details?: string
	/**
	 * Title of content card
	 */
	card_title?: string
	/**
	 * Type of content card
	 */
	card_type: string
	/**
	 * Style of carousel
	 */
	carousel_style?: string
	/**
	 * Title of carousel
	 */
	carousel_title?: string
	/**
	 * Collection name, used in marketplace app
	 */
	collection?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Collection name, used in marketplace app
	 */
	list_id?: Record<string, any>
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface ContentHeroClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Version of hero
	 */
	hero_version?: string
	/**
	 * Featured text on homepage hero
	 */
	homepage_featuredtext?: string
	/**
	 * Homepage image name
	 */
	homepage_imagename?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ContinueToConfirmBidButton {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Maximum bid amount
	 */
	bid_amount: number
	/**
	 * Currency of bid placed
	 */
	bid_currency: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface CostEstimated {
	/**
	 * Artist resale rights value
	 */
	artist_resale_rights: number
	/**
	 * Buyer's premium value
	 */
	buyers_premium: number
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * Estimated total value
	 */
	estimated_total: number
	/**
	 * Hammer price value
	 */
	hammer_price: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * Shipping and handling amount; passes the amount if it is present (either in the cost estimator or in payments if users select "Pay Now" for the shipping)
	 */
	shipping_amount: number
}
export interface CostEstimatorError {
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * Hammer price value
	 */
	hammer_price: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface CostEstimatorOpened {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface CouponApplied {
	/**
	 * Name of the coupon
	 */
	coupon_name?: string
}
export interface CouponDenied {
	/**
	 * Name of the coupon
	 */
	coupon_name?: string
}
export interface CouponEntered {
	/**
	 * Name of the coupon
	 */
	coupon_name?: string
}
export interface CouponRemoved {
	/**
	 * Name of the coupon
	 */
	coupon_name?: string
}
export interface CurrencySelected {
	/**
	 * Currency of the auction
	 */
	currency_auction?: string
	/**
	 * The currency the user is switching TO; e.g. if user is switching from USD to HKD then ‘currency’ should be USD and ‘currency_new’ should be HKD
	 */
	currency_new?: string
	/**
	 * Where the currency conversion took place; i.e. auction room, lot page, bidding flow, auction lot list, account
	 */
	currency_selection_origin?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Original currency of the item
	 */
	original_currency?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface DownloadAppButtonClicked {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface DownloadAppModalViewed {
	''?: Record<string, any>
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface EmailConfirmationFailed {
	/**
	 * email address
	 */
	email?: string | null
	/**
	 * Error message
	 */
	error_msg?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
}
export interface EmailConfirmed {
	/**
	 * email address
	 */
	email?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
}
export interface EmailSignup {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Segment page name of where the form was submitted (whatever is being passed in as name in page tracking), e.g. "Homepage: En"
	 */
	form_page?: string
	/**
	 * title of the form, e.g. "Lost Card Request Form"
	 */
	form_title?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface EmailSubscribed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Date of event (ISO 8601)
	 */
	event_date?: string
	/**
	 * Time of the event
	 */
	event_time?: string
	/**
	 * Segment page name of where the form was submitted (whatever is being passed in as name in page tracking), e.g. "Homepage: En"
	 */
	form_page?: string
	/**
	 * title of the form, e.g. "Lost Card Request Form"
	 */
	form_title?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Whether user is attending event or not
	 */
	rsvp?: string
	/**
	 * Total number of people attending
	 */
	rsvp_quantity?: string
	/**
	 * City of sweep
	 */
	sweep_city?: string
	/**
	 * Country of sweep
	 */
	sweep_country?: string
	/**
	 * Date of sweep (ISO 8601)
	 */
	sweep_date?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface EnquireClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * For this event it should be "cart", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, should be array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, same as price, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Image URL of the product image
	 */
	image_url?: string
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Viking lot guid
	 */
	lot_viking_id?: string
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Tags, should be optional string, MP-1327
	 */
	tags?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
}
export interface User {
	/**
	 * Email
	 */
	email: string
	/**
	 * First Name
	 */
	first_name: string
	/**
	 * Last Name
	 */
	last_name: string
	/**
	 * Phone Number
	 */
	phone_number?: string
}
export interface EnquireSubmitted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * For this event it should be "cart", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, should be array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, same as price, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Image URL of the product image
	 */
	image_url?: string
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Viking lot guid
	 */
	lot_viking_id?: string
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Tags, should be optional string, MP-1327
	 */
	tags?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
	/**
	 * Form Details
	 */
	user?: User
}
export interface ExitLinkClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * URL of external (non sothebys.com) link
	 */
	external_link: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ExperimentViewed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
}
export interface FaqClick {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Defined by [Segment page name]: [faq text]
	 */
	faq_text?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface FilterResultsClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * search term typed into the filter component search field
	 */
	filter_search_keyword?: string
	/**
	 * concatenated list of filter selections, ie. "[item 1]" | "[item2]" | "[item3]" etc
	 */
	filter_selections?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface FormSubmitted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Segment page name of where the form was submitted (whatever is being passed in as name in page tracking), e.g. "Homepage: En"
	 */
	form_page?: string
	/**
	 * title of the form, e.g. "Lost Card Request Form"
	 */
	form_title?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface GridView {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface HotspotClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * title of the entire hotspot component
	 */
	hotspot_component_title?: string
	/**
	 * title of specific hotspot that user has opened, ie. when they click on red circle icon overlaying main image
	 */
	hotspot_content_title?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface HotspotOpened {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * title of the entire hotspot component
	 */
	hotspot_component_title?: string
	/**
	 * title of specific hotspot that user has opened, ie. when they click on red circle icon overlaying main image
	 */
	hotspot_content_title?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface InteractiveModuleClicked {
	module_type?: string
}
export interface InternalLink {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Internal (sothebys.com domain) URL
	 */
	internal_link: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ItemFollowed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Item Followed origin
	 */
	follow_origin?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Type of item
	 */
	item_type?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Type of sale
	 */
	sale_type?: string
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface ItemUnfollowed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Item Followed origin
	 */
	follow_origin?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Type of item
	 */
	item_type?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Type of sale
	 */
	sale_type?: string
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface LearnMore {
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Where did the user click on learn more (How do offers work / authenticity guaranteed / international shipping)
	 */
	component?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Retail item id
	 */
	product_id?: string
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * SKU number of the item
	 */
	sku?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
}
export interface LinkButtonClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Link button call to action text
	 */
	link_button: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface LoadMoreResultsClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Number of lots in the sale
	 */
	num_lots_sale: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Sequence of events that could occur consecutively on the same page; for example if user has clicked "Load More Results Clicked" on infinite scroll for the 2nd time, pass "2"
	 */
	sequence: number
}
export interface LoginAttempt {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
}
export interface LoginAttemptError {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
}
export interface LoginClicked {
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Call to action text, the text on the button that was clicked
	 */
	cta_text: string
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * What action or ask initiated the login? This is used to understand what drives users to login. The difference from cta is that this gives the context of the page or modal and not just the cta of the button
	 */
	login_context: string
	/**
	 * Where on the page or screen is the thing placed, e.g. \"top nav bar\" or \"next to bid button\".  This differs from 'position' as that has only an integer position of an item in a list.
	 */
	placement?: string | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id?: string | null
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name?: string | null
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
}
export interface LoginStart {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
}
export interface LoginToBid {
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * Auction name
	 */
	auction_name?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Lot name
	 */
	lot_name?: string
	/**
	 * Viking lot GUID
	 */
	lot_viking_id: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface LotTracked {
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	auth0_id?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	customer_id?: any | null
	/**
	 * Six-digit SAP sale number
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * User's Auth0 value
	 */
	sale_id?: string
}
export interface MakeAnOfferClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * For this event it should be "cart", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, should be array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, same as price, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Image URL of the product image
	 */
	image_url?: string
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * PDP or modal (if clicking from "how do offers work"
	 */
	location?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * Viking lot guid
	 */
	lot_viking_id?: string
	/**
	 * Price*.25  Used for GoogleAd optimization
	 */
	mao_value?: number
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Tags, should be optional string, MP-1327
	 */
	tags?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
}
export interface MaybeLaterClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * If user is Preferred
	 */
	preferred_member_flag?: boolean | null
}
export interface MeiMosesChartInteraction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Name of Mei Moses chart
	 */
	mei_moses_chart_name?: string
	/**
	 * number of page that the user toggles to (integer value) via the arrows on the left or right of the iframe
	 */
	mei_moses_chart_page: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface MeiMosesChartLoaded {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Name of Mei Moses chart
	 */
	mei_moses_chart_name?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface MyProfileUpdated {
	/**
	 * Type of Update
	 */
	update_type: string
}
export interface Navigated {
	/**
	 * Used in Avo to generate Segment page name
	 */
	'Segment: Page Name'?: string
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * CMP tracking code string
	 */
	cmp_trackingcode?: string
	/**
	 * Type of content
	 */
	content_type?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Previous call to action
	 */
	previous_cta?: string
	/**
	 * Previous page name
	 */
	previous_pagename?: string
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Type of sale
	 */
	sale_type?: string
}
export interface NavigationClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Call to action link URL
	 */
	cta_link_url: string
	/**
	 * Call to action text
	 */
	cta_text?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * For links within the 3 primary navigational options, pass "Main Navigation Item - BUY", "Main Navigation Item - SELL", "Main Navigation Item - DISCOVER" depending on the location of the navigational click. For lesser links (on the right hand side of the header – see screenshot), pass "Top Level Navigation", for the items on the lower right send 'Sub Navigation Item - MUSEUM NETWORK', 'Sub Navigation Item - ABOUT' and 'Sub Navigation Item - SERVICES'
	 */
	navigation_level?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface NotAllowedToBid {
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * email address
	 */
	email?: string | null
	/**
	 * user's first name
	 */
	first_name?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * user's last name
	 */
	last_name?: string | null
	/**
	 * Type of message the user views
	 */
	message_type?: string
	/**
	 * If user is Preferred
	 */
	preferred_member_flag: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * user's title or salutation
	 */
	title?: string | null
	/**
	 * Origin of transactional registration
	 */
	transactional_registration_origin?: string
}
export interface NotifyMe {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * NotifyMe
	 */
	loggedIn_status: boolean
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * Why not part of bidding???
	 */
	lot_item_id?: string
	/**
	 * NotifyMe
	 */
	notify_me_origin: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface ProductsItem5 {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Secondary category of the product
	 */
	category_2?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	/**
	 * Name of the department to which the item belongs
	 */
	department?: string
	name?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	ships_internationally?: boolean | null
}
export interface OfferCompleted {
	/**
	 * offer bag id
	 */
	cart_id?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * departments concatenated to a string, delimited by a |
	 */
	department_category?: string
	/**
	 * for this event it should be "purchase", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Offer/transaction ID
	 */
	offer_id: string
	/**
	 * Numerical number of offer
	 */
	offer_number?: string
	/**
	 * Offer amount the user enters
	 */
	offer_price?: number
	/**
	 * The percentage difference between list price and offer price
	 */
	percent_of_list_price?: number
	/**
	 * Products within a cart
	 */
	products?: ProductsItem5[]
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Represents the shipping the method chosen (only pass this value if a shipping method is set)
	 */
	shipping_method?: string
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page (MP-1092)
	 */
	url?: string
}
export interface OfferEntered {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * For this event it should be "cart", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, should be array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, same as price, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Image URL of the product image
	 */
	image_url?: string
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * true if logged in, false if not
	 */
	logged_in?: boolean
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * Offer amount the user entered
	 */
	offer_price?: number
	/**
	 * The percentage difference between list price and offer price
	 */
	percent_of_list_price?: number
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Tags, should be optional string, MP-1327
	 */
	tags?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
}
export interface OnboardingModuleInteraction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface ProductsItem6 {
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Name of product
	 */
	name?: string
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id?: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface OrderCancelled {
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem6[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface ProductsItem7 {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Secondary category of the product
	 */
	category_2?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	/**
	 * Name of the department to which the item belongs
	 */
	department?: string
	name?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	ships_internationally?: boolean | null
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface OrderCompleted {
	cart_id?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * departments concatenated to a string, delimited by a |
	 */
	department_category?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * for this event it should be "purchase", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * True if the product has variants enabled and available
	 */
	has_variants?: boolean
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * Order/transaction ID
	 */
	order_id: string
	payment_method?: string
	/**
	 * Products within a cart
	 */
	products?: ProductsItem7[]
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
	 */
	seller?: string
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Represents the shipping the method chosen (only pass this value if a shipping method is set)
	 */
	shipping_method?: string
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
	 */
	userToken?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface ProductsItem8 {
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Name of product
	 */
	name?: string
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id?: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface OrderRefunded {
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem8[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface PaddleRegistrationComplete {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface PageViewed {
	auctionId?: any | null
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Author of editorial piece
	 */
	author?: string
	client_userid?: any | null
	/**
	 * CMP tracking code string
	 */
	cmp_trackingcode?: string | null
	/**
	 * Type of content
	 */
	content_type?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * Edition code of the consignment tool
	 */
	edition_code?: string
	/**
	 * url path of editorial content
	 */
	editorial_content?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * user's selected language
	 */
	language?: string
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * page name
	 */
	name?: string
	/**
	 * window.location.path
	 */
	path: string
	/**
	 * Set to true if content is accessible to Preferred only
	 */
	preferred_content?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Previous call to action
	 */
	previous_cta?: string
	/**
	 * url of previous page
	 */
	previous_page_url?: string
	/**
	 * Previous page name
	 */
	previous_pagename?: string
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Rakutan referral data
	 */
	rakuten_referral_data?: string
	/**
	 * window.document.referrer
	 */
	referrer: string
	/**
	 * array of related sales
	 */
	related_sales?: string
	/**
	 * Repeat if user is a repeat user, New if not
	 */
	repeat_user?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Type of sale
	 */
	sale_type?: string
	/**
	 * window.location.search
	 */
	search: string
	/**
	 * Set to true if content is related explicitly to Studios (e.g. could be Studios content related to Article, Slideshow, Video, City Guide, etc.); false if not related explicitly to Studios (e.g. sothebys.com homepage)
	 */
	studios_content?: string
	/**
	 * user's salutaion
	 */
	title: string
	/**
	 * Video topic tag
	 */
	topic?: string
	/**
	 * The value of UIApplicationLaunchOptionsURLKey from launchOptions (auto-collected on iOS only)
	 */
	url?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface PasswordChangeRequested {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface Payment3DsError {
	/**
	 * User's Auth0 value
	 */
	auth_id?: string
	/**
	 * Currency code associated with the transaction

	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: string
	/**
	 * Error Type
	 */
	error_type?: string
	/**
	 * States whether the user is browsing the web from within the app or not

	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"

	 */
	preferred_member_flag?: boolean
}
export interface Payment3DsValidationStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * Amount that user attempts to pay or successfully pays, excluding shipping
	 */
	'payment_amount '?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Where in the payment flow the 3DS validation was initiated
	 */
	validation_origin?: string
}
export interface PaymentAddNewMethod {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PaymentCancel {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Screen name
	 */
	screen?: string
}
export interface PaymentError {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Quantity of checking accounts selected
	 */
	checking_accounts_selected?: number
	/**
	 * Amount being paid by checking
	 */
	checking_payment_amount?: number
	/**
	 * Quantity of credit cards selected
	 */
	creditcard_accounts_selected?: number
	/**
	 * Amount being paid by credit card
	 */
	'creditcard_payment_amount '?: number
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Error message
	 */
	error_msg?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * Amount that user attempts to pay or successfully pays, excluding shipping
	 */
	payment_amount: number
	/**
	 * Remaining balance that user must pay (i.e. total invoice amount less payment_amount)
	 */
	payment_balance?: number
	/**
	 * Reason for payment error or failure
	 */
	payment_error?: string
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * Shipping amount
	 */
	shipping_amount?: number
	/**
	 * Total amount of the invoice
	 */
	total_invoice_amount?: number
}
export interface ProductsItem9 {
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Name of product
	 */
	name?: string
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id?: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
}
export interface PaymentInfoEntered {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem9[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface PaymentMethodSelected {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Quantity of checking accounts selected
	 */
	checking_accounts_selected?: number
	/**
	 * Amount being paid by checking
	 */
	checking_payment_amount?: number
	/**
	 * Quantity of credit cards selected
	 */
	creditcard_accounts_selected?: number
	/**
	 * Amount being paid by credit card
	 */
	'creditcard_payment_amount '?: number
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface PaymentNewMethodError {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * The returned error message for the error, if available
	 */
	error_msg?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * The type of payment method added or used
	 */
	payment_method?: string
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PaymentNewMethodSuccess {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Credit Card Type
	 */
	cc_type?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * The type of payment method added or used
	 */
	payment_method?: string
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PaymentProcessStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Yes/No wether the invoice offers a "Shipping options" step in the payment process
	 */
	has_shipping_options?: boolean
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Total amount of the invoice
	 */
	total_invoice_amount?: number
}
export interface PaymentSelectInvoice {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * Whether invoice is payable or not
	 */
	invoice_payable: boolean
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * Total amount of the invoice
	 */
	total_invoice_amount?: number
}
export interface PaymentShipping {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Is true if user is paying for shipping now but false otherwise
	 */
	pay_now?: boolean
	/**
	 * Amount that user attempts to pay or successfully pays, excluding shipping
	 */
	payment_amount: number
	/**
	 * The type of payment method added or used
	 */
	payment_method?: string
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Shipping amount
	 */
	shipping_amount?: number
}
export interface PaymentSubmit {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Quantity of checking accounts selected
	 */
	checking_accounts_selected?: number
	/**
	 * Amount being paid by checking
	 */
	checking_payment_amount?: number
	/**
	 * Quantity of credit cards selected
	 */
	creditcard_accounts_selected?: number
	/**
	 * Amount being paid by credit card
	 */
	'creditcard_payment_amount '?: number
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * Amount that user attempts to pay or successfully pays, excluding shipping
	 */
	payment_amount: number
	/**
	 * Remaining balance that user must pay (i.e. total invoice amount less payment_amount)
	 */
	payment_balance?: number
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * Shipping amount
	 */
	shipping_amount?: number
	/**
	 * Total amount of the invoice
	 */
	total_invoice_amount?: number
}
export interface PaymentSuccess {
	'Segment: Currency'?: any | null
	/**
	 * amount of revenue the event resulted in
	 */
	'Segment: Revenue'?: number
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Credit Card Type
	 */
	cc_type?: string
	/**
	 * Quantity of checking accounts selected
	 */
	checking_accounts_selected?: number
	/**
	 * Amount being paid by checking
	 */
	checking_payment_amount?: number
	/**
	 * Quantity of credit cards selected
	 */
	creditcard_accounts_selected?: number
	/**
	 * Amount being paid by credit card
	 */
	'creditcard_payment_amount '?: number
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * Does billing address and shipping address match
	 */
	is_address_match?: boolean
	/**
	 * Amount that user attempts to pay or successfully pays, excluding shipping
	 */
	payment_amount: number
	/**
	 * Remaining balance that user must pay (i.e. total invoice amount less payment_amount)
	 */
	payment_balance?: number
	/**
	 * Payment Channel
	 */
	payment_channel?: string
	/**
	 * The category of the payment_method, e.g.  credit card, checking account
	 */
	payment_method_category?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue?: number
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * Shipping amount
	 */
	shipping_amount?: number
	/**
	 * Total amount of the invoice
	 */
	total_invoice_amount?: number
}
export interface PercentPageViewed100 {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Scroll
	 */
	page_progress: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PercentPageViewed25 {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Scroll
	 */
	page_progress: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PercentPageViewed50 {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Scroll
	 */
	page_progress: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PercentPageViewed75 {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Scroll
	 */
	page_progress: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PlaceBidButton {
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * Auction name
	 */
	auction_name?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Text of call to action, e.g. "Place Bid" or "Increase Your Bid"
	 */
	cta_text?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Lot name
	 */
	lot_name?: string
	/**
	 * Viking lot GUID
	 */
	lot_viking_id: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
}
export interface PlaceMaximumBidButton {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Maximum bid amount
	 */
	bid_amount: number
	/**
	 * Currency of bid placed
	 */
	bid_currency: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface PreferredContentCardClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Details within content card
	 */
	card_details?: string
	/**
	 * Title of content card
	 */
	card_title?: string
	/**
	 * Type of content card
	 */
	card_type: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * View option selected
	 */
	view_selected: string
}
export interface PreferredMapInteraction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Map Interaction
	 */
	cluster_size?: number
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Map Interaction
	 */
	map_interaction_type: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface PreferredSearchResultClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * T'he search term that was initially entered
	 */
	search_keyword?: string
	/**
	 * The type of result, e.g. ""Article"", ""Lot"", etc
	 */
	search_result_category?: string
	/**
	 * Title of the result that was clicked on
	 */
	search_result_title: string
	/**
	 * View option selected
	 */
	view_selected: string
}
export interface PreferredViewTypeSelected {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * The type of result, e.g. ""Article"", ""Lot"", etc
	 */
	search_result_category?: string
	/**
	 * Title of the result that was clicked on
	 */
	search_result_title: string
	/**
	 * Defined by [page name]: [view selection], e.g. if the user is on the Auction Calendar and selects grid view then view_selection should pass “Auction Calendar: Grid”, or if they instead selected the List view then pass “Auction Calendar: List”
	 */
	view_selection: string
}
export interface PrintButtonClicked {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * origin of print button click
	 */
	print_origin: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface PrintCompleted {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Number of lots in the sale
	 */
	num_lots_sale: number
	/**
	 * Number of lots selected
	 */
	num_lots_selected: number
	/**
	 * Print format, either "thumbnails" or "full description"
	 */
	print_format: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface PrintFormatSelected {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Number of lots in the sale
	 */
	num_lots_sale: number
	/**
	 * Number of lots selected
	 */
	num_lots_selected: number
	/**
	 * Number of search filters applied
	 */
	num_search_filters?: number
	/**
	 * Print format, either "thumbnails" or "full description"
	 */
	print_format: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface PrintLotsSelected {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Number of lots in the sale
	 */
	num_lots_sale: number
	/**
	 * Number of lots selected
	 */
	num_lots_selected: number
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface PrivateSaleProductClicked {
	/**
	 * Name of product
	 */
	name?: string
}
export interface ProductsItem10 {
	/**
	 * Category of the product
	 */
	category?: string
	/**
	 * Creator of the product
	 */
	creator?: string
	name?: string
	/**
	 * Price of the product
	 */
	price?: number
	product_id?: string
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	ships_internationally?: boolean | null
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface ProceedToCheckout {
	cart_id?: string
	/**
	 * Checkout type purchase or make an offer
	 */
	checkout_type?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * departments concatenated to a string, delimited by a |
	 */
	department_category?: string
	order_id?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * Products within a cart
	 */
	products?: ProductsItem10[]
	/**
	 * Revenue associated with the transaction (excluding shipping and tax)
	 */
	revenue?: number
}
export interface ProductAdded {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * For this event it should be "cart", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, should be array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, same as price, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Image URL of the product image
	 */
	image_url?: string
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * List of SKUs of the items that are present in the cart, after the action has taken place, e.g. after a Product has been removed. Send null if no items are in the cart.
	 */
	items_in_cart?: any[] | null
	/**
	 * true if logged in, false if not MP-1226
	 */
	logged_in?: boolean
	/**
	 * Viking lot guid
	 */
	lot_viking_id?: string
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
	 */
	seller?: string
	/**
	 * Boolean which is true if international shipping is available for the item
	 */
	ships_internationally?: boolean | null
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Tags, should be optional string, MP-1327
	 */
	tags?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
	/**
	 * Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
	 */
	userToken?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface ProductInteraction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Low estimate of the product being interacted with
	 */
	low_estimate?: number
	/**
	 * The position of the thing being interacted with, e.g. position of the alternative image being clicked on
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Type of product interaction
	 */
	product_interaction_type?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
	/**
	 * Type of item being viewed, "Lot", "Buy Now", "Private Sale" or "Wine"
	 */
	type?: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface ProductsItem11 {
	/**
	 * should be creator for now
	 */
	brand?: string | null
	category?: string | null
	/**
	 * stripping out |,;
	 */
	clean_name?: string | null
	currency?: string | null
	image_url?: string | null
	name?: string | null
	/**
	 * The number of variants of the product, if it is a product with variants. Skip (or use null) if the product has no alternative variants.
	 */
	number_of_variants?: number | null
	/**
	 * position of the item in the list
	 */
	position?: number | null
	/**
	 * list price
	 */
	price?: number | null
	/**
	 * viking id
	 */
	product_id: string
	/**
	 * <pagination_total_pages><total_number_of_items><pagination_current_page><items_per_page><position> and delimiter ":"
	 */
	product_list_position?: string | null
	quantity?: number | null
	sku?: string | null
	url?: string | null
}
export interface ProductListFiltered {
	/**
	 * User's Auth0 value

	 */
	auth0_id?: string
	/**
	 * Name of the collection, e.g. “Furniture” or “New Arrivals”
	 */
	collection?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Filter that was applied that triggered the event

	 */
	filter_applied?: string
	/**
	 * The filter selection
	 */
	filter_selection?: string
	/**
	 * Denotes the UI filter widget type and is useful, for example, when AB testing

	 */
	filter_widget_type?: string
	/**
	 * Product filters that the customer is using

	 */
	filters?: any[]
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	items_per_page?: number
	/**
	 * Browsing language that user has selected via site header

	 */
	language?: string
	/**
	 * Unique ID of the collection page being viewed
	 */
	list_id?: string
	/**
	 * Number of search filters applied

	 */
	num_search_filters?: number
	/**
	 * The number of search results returned

	 */
	num_search_results?: number
	/**
	 * A list of the Algolia specific objectIDs of the relevant records. These can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectIDs?: any[]
	pagination_current_page?: number
	pagination_total_pages?: number
	/**
	 * List of products displayed on the page
	 */
	products?: ProductsItem11[]
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * T'he search term that was initially entered

	 */
	search_keyword?: string
	total_number_of_items?: number
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
	 */
	userToken?: string
}
export interface ProductsItem12 {
	/**
	 * should be creator for now
	 */
	brand?: string | null
	category?: string | null
	/**
	 * stripping out |,;
	 */
	clean_name?: string | null
	currency?: string | null
	image_url?: string | null
	name?: string | null
	/**
	 * The number of variants of the product, if it is a product with variants. Skip (or use null) if the product has no alternative variants.
	 */
	number_of_variants?: number | null
	/**
	 * position of the item in the list
	 */
	position?: number | null
	/**
	 * list price
	 */
	price?: number | null
	/**
	 * viking id
	 */
	product_id: string
	/**
	 *  <pagination_total_pages><total_number_of_items><pagination_current_page><items_per_page><position> and delimiter ":"
	 */
	product_list_position?: string | null
	quantity?: number | null
	sku?: string | null
	url?: string | null
}
export interface ProductListViewed {
	/**
	 * The open/close state of the relevant auction
	 */
	auction_state?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Primary category of page
	 */
	category?: string
	/**
	 * Secondary category of page
	 */
	category_2?: string
	/**
	 * Name of the collection, e.g. “Furniture” or “New Arrivals”
	 */
	collection?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	items_per_page?: number
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Unique ID of the collection page being viewed
	 */
	list_id?: string
	/**
	 * true if logged in, false if not MP-1226
	 */
	logged_in?: boolean
	/**
	 * A list of the Algolia specific objectIDs of the relevant records. These can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectIDs?: any[]
	/**
	 * Set the number of the page the user is currently viewing
	 */
	pagination_current_page?: number
	pagination_total_pages?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * All categories of products viewed in one string, separated by a |
	 */
	product_category_list?: string
	/**
	 * Type of product being interacted with or viewed. The type refers to the object type as in taxonomy definition and object DB. If many, then it's a concatenated string delimited by '|'.
	 */
	product_type?: string
	/**
	 * List of products dispalyed on the page, Jira MP-1743
	 */
	products?: ProductsItem12[]
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Type of sale
	 */
	sale_type?: string
	/**
	 * Search Key word, eg: "Rolex"
	 */
	search_keyword?: string
	/**
	 * Passes as 'true' if action occurred in sticky navigation, 'false' if not
	 */
	sticky_navigation?: boolean
	total_number_of_items?: number
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
	 */
	userToken?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
}
export interface ProductRemoved {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * FALSE if the user removes the product from the bag. TRUE if the item is removed automatically because of server logic.
	 */
	auto_removed?: boolean
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * URL of the image used
	 */
	image_url?: string
	/**
	 * List of SKUs of the items that are present in the cart, after the action has taken place, e.g. after a Product has been removed. Send null if no items are in the cart.
	 */
	items_in_cart?: any[] | null
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits). For retail items use its uuid.
	 */
	product_id?: string
	/**
	 * TRUE if the product has become unavailable at the time of removal
	 */
	product_unavailable?: boolean
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
	 */
	seller?: string
	/**
	 * Boolean which is true if international shipping is available for the item
	 */
	ships_internationally?: boolean | null
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * The url of the relevant item or content
	 */
	url?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface ProductViewed {
	/**
	 * Artist associated with the product
	 */
	artist?: string
	/**
	 * Passes only on the mobile app; set to TRUE if lot has augmented reality available and FALSE if not
	 */
	augmented_reality_enabled?: boolean
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Brand associated with the product
	 */
	brand?: string
	/**
	 * Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category?: string
	/**
	 * Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
	 */
	category_2?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Creator associated with the product
	 */
	creator?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * Designer associated with the product
	 */
	designer?: string
	/**
	 * Whether lot includes digital catalogue content (from Brightspot
	 */
	digital_catalogue_content?: boolean
	/**
	 * for this event it should be "product", MP-1280
	 */
	ecomm_pagetype?: string
	/**
	 * Sku/Sap id, array of strings, MP-1280
	 */
	ecomm_prodid?: any[]
	/**
	 * Total monetary value, same as price, MP-1280
	 */
	ecomm_totalvalue?: number
	/**
	 * Set true for marketplace products that have multiple variants available. False otherwise. Does not apply to lots as variants are not supported.
	 */
	has_variants?: boolean
	/**
	 * Image URL of the product image
	 */
	image_url?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
	 */
	index?: string
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * true if logged in, false if not MP-1226
	 */
	logged_in?: boolean
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * The open/close state of the relevant lot
	 */
	lot_state?: string
	/**
	 * Viking lot guid
	 */
	lot_viking_id?: string
	/**
	 * name of the product
	 */
	name?: string
	/**
	 * Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	objectID?: string
	/**
	 * The general period of the object, such as antique,...
	 */
	period_general?: string
	/**
	 * The position (integer) of the thing being interacted with
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Price of the product
	 */
	price?: number
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Product tags associated with a product
	 */
	product_tags?: any[]
	/**
	 * Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
	 */
	product_type?: string
	/**
	 * Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
	 */
	queryID?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * Type of sale
	 */
	sale_type?: string
	/**
	 * The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
	 */
	seller?: string
	/**
	 * Boolean which is true if international shipping is available for the item
	 */
	ships_internationally?: boolean | null
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * Tags, should be optional string, MP-1327
	 */
	tags?: string
	/**
	 * Type of item being viewed, "Lot" or "Buy Now"
	 */
	type?: string
	/**
	 * URL of the product page
	 */
	url?: string
	/**
	 * Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
	 */
	userToken?: string
	/**
	 * The user id created in viking and served via the user service (also known as viking_user_id)
	 */
	user_service_id?: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string
}
export interface PromotionClicked {
	/**
	 * Call to action text
	 */
	cta_text?: string
}
export interface QuantityUpdated {
	/**
	 * Log in status
	 */
	logged_in?: boolean
	quantity?: number
	/**
	 * product sku
	 */
	sku?: string
}
export interface QuickBidButton {
	/**
	 * Viking auction GUID
	 */
	auction_id?: string
	/**
	 * Auction name
	 */
	auction_name?: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Maximum bid amount
	 */
	bid_amount: number
	/**
	 * Currency of bid placed
	 */
	bid_currency: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Lot name
	 */
	lot_name?: string
	/**
	 * Viking lot GUID
	 */
	lot_viking_id: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface QuickBidCancelButton {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface QuickViewClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Call to action text
	 */
	cta_text?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface QuickViewInteraction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Interaction within the quick view card
	 */
	quickview_interaction_type: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface QuickViewOpened {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Defined by [page name]: [view selection], e.g. if the user is on the Auction Calendar and selects grid view then view_selection should pass “Auction Calendar: Grid”, or if they instead selected the List view then pass “Auction Calendar: List”
	 */
	view_selection: string
}
export interface RecommendationClicked {
	/**
	 * Top level product category being viewed
	 */
	category?: string | null
	/**
	 * The currency of the clicked product
	 */
	clicked_currency?: string | null
	/**
	 * The high estimate of the clicked product
	 */
	clicked_estimate_high?: number | null
	/**
	 * The low estimate of the clicked product
	 */
	clicked_estimate_low?: number | null
	/**
	 * The list price of the clicked product
	 */
	clicked_price?: number | null
	/**
	 * The id of the clicked product. For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011. For Marketplace: the SAP-id (aka. sku or external_id)
	 */
	clicked_product_id: string
	/**
	 * The uuid of the clicked product, created in the system that created the sale or product, i.e. lot_id or retail_item_id in Viking, or uuid in metaverse etc.
	 */
	clicked_product_uuid: string
	/**
	 * The type of sale of the clicked item
	 */
	clicked_sale_type: string
	/**
	 * The uuid of the sale of the clicked product, a.k.a. viking_auction_id, if applicable
	 */
	clicked_sale_uuid?: string | null
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * High estimate of a given lot
	 */
	estimate_high?: number | null
	/**
	 * High estimate of a given lot
	 */
	estimate_low?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price?: number | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * The number (integer) of recommendations included
	 */
	recommendation_num: number
	/**
	 * Source of the recommendation or suggestion
	 */
	recommendation_source: string
	/**
	 * Content of the trait
	 */
	recommendation_trait_content?: string | null
	/**
	 * Name of trait used to generate the recommendation
	 */
	recommendation_trait_name?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * Type of sale
	 */
	sale_type: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
}
export interface RecommendationLoaded {
	/**
	 * Top level product category being viewed
	 */
	category?: string | null
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * High estimate of a given lot
	 */
	estimate_high?: number | null
	/**
	 * High estimate of a given lot
	 */
	estimate_low?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price?: number | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * The number (integer) of recommendations included
	 */
	recommendation_num: number
	/**
	 * Source of the recommendation or suggestion
	 */
	recommendation_source: string
	/**
	 * Content of the trait
	 */
	recommendation_trait_content?: string | null
	/**
	 * Name of trait used to generate the recommendation
	 */
	recommendation_trait_name?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * Type of sale
	 */
	sale_type: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
}
export interface RecommendationSeen {
	/**
	 * Top level product category being viewed
	 */
	category?: string | null
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * High estimate of a given lot
	 */
	estimate_high?: number | null
	/**
	 * High estimate of a given lot
	 */
	estimate_low?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price?: number | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * The number (integer) of recommendations included
	 */
	recommendation_num: number
	/**
	 * Source of the recommendation or suggestion
	 */
	recommendation_source: string
	/**
	 * Content of the trait
	 */
	recommendation_trait_content?: string | null
	/**
	 * Name of trait used to generate the recommendation
	 */
	recommendation_trait_name?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * Type of sale
	 */
	sale_type: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
}
export interface RegistrationPendingMessageViewed {
	/**
	 * For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
	 */
	bid_method: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * The amount of the bid that the user intended to place prior to encountering the restricted bidding message
	 */
	intended_bid_amount?: number | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * Type of message the user views
	 */
	message_type: string
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
}
export interface SaleTracked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface SaleroomNoticeView {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * Condition Report
	 */
	details?: string
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface SearchAnswerCardLoaded {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * T'he search term that was initially entered
	 */
	search_keyword?: string
	/**
	 * The type of result, e.g. ""Article"", ""Lot"", etc
	 */
	search_result_category?: string
	/**
	 * Title of the result that was clicked on
	 */
	search_result_title: string
}
export interface SearchInitiated {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface SearchInputActivated {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface SearchResultClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Content Type like Buy Now or Auction or Lot
	 */
	content_type?: string
	/**
	 * Call to action text
	 */
	cta_text?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	result_type?: string
	/**
	 * The array of filters that were applied in the search that resulted to the click
	 */
	search_filters?: string
	/**
	 * T'he search term that was initially entered
	 */
	search_keyword?: string
	/**
	 * The type of result, e.g. ""Article"", ""Lot"", etc
	 */
	search_result_category?: string
	/**
	 * Departments of the search_result
	 */
	search_result_department?: any[]
	/**
	 * Origin of the search result click, specifically "Typeahead Top Suggestions" if user clicks on result within Top Suggestions column, "Search Results Page" if user clicks result on the actual search results page
	 */
	search_result_origin?: string
	/**
	 * On mobile app, where in the search result list was the clicked result. Used to see how relevant the result list was
	 */
	search_result_position?: string
	/**
	 * Title of the result that was clicked on
	 */
	search_result_title: string
	/**
	 * Logs whether the user used ‘card view’ or ‘list view’ to view the search result before clicking
	 */
	search_view_type?: string
}
export interface SearchSubmitted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * “Top Searches” if user has submitted a search term that matches one of the top searches presented in the dropdown; passes “Suggestions” if search term matches one of the suggestions presented in the dropdown; the query_suggestion_category property should ONLY pass if a user selects a query suggestion within the dropdown to execute their search
	 */
	query_suggestion_category?: string
	/**
	 * What was typed into the search field when the event was sent
	 */
	search_keyword: string
}
export interface SearchSuggestionClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * T'he search term that was initially entered
	 */
	search_keyword?: string
	/**
	 * The suggested keyword that the user clicked on, e.g. "pablo picasso, vallauris, 1953"
	 */
	search_suggested_keyword?: string
}
export interface SellerPortalAction {
	action_type?: Record<string, any>
	consignment_category?: Record<string, any>
	consignment_item_id?: Record<string, any>
	consignment_location?: Record<string, any>
	consignment_status?: Record<string, any>
	consignment_sub_category?: Record<string, any>
	path?: Record<string, any>
	tab_name?: Record<string, any>
	tab_position?: Record<string, any>
	task_type?: Record<string, any>
}
export interface ShipmentViewed {
	/**
	 * Number of days until estimated delivery of item; i.e. current date minus estimated date of delivery. Should be positive, e.g. "2" if shipping estimated to occur in the future, and negative, e.g. "-2" if shipping was estimated to occur in the past and is overdue. For example if shipping is expected tomorrow, then pass "1", if today, then pass "0", if it was expected yesterday, then pass "-1".
	 */
	days_until_delivery?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	'product_id_list.$'?: string
	/**
	 * shipping quote number
	 */
	quote_number?: number
	/**
	 * status of shipment
	 */
	shipment_status?: string
	/**
	 * method of shipment
	 */
	shipping_method?: string
	/**
	 * shipping tracking ID
	 */
	tracking_id?: string
}
export interface ShippingPreferenceConfirmed {
	/**
	 * Is this a live or timed auction
	 */
	auction_type?: string
	/**
	 * The department(s) selling the item(s)
	 */
	departments?: any[]
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * The location of the sale
	 */
	sale_location?: string
	/**
	 * Where did the user enter the shipping preference from
	 */
	shipping_preference_origin?: string
	/**
	 * Which type of shipping preference was selected
	 */
	shipping_preference_type: string
}
export interface ShippingSelected {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Invoice number
	 */
	invoice_number?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
	 */
	product_id_list?: any[]
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
	/**
	 * Shipping Selected
	 */
	selected_rate?: string
	/**
	 * Total amount of the invoice
	 */
	total_invoice_amount?: number
}
export interface SignedOut {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface SimpleRegistrationStart {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
}
export interface SimpleRegistrationSubmitAttempt {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
}
export interface SimpleRegistrationSubmitError {
	/**
	 * Error description
	 */
	error_description?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
}
export interface SlideshowAdvanced {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Slide number
	 */
	slideshow_slidenumber: number
	/**
	 * Title of slideshow
	 */
	slideshow_title?: string
}
export interface SlideshowCarouselOpened {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface SlideshowZoomed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface SortApplied {
	/**
	 * User's Auth0 value

	 */
	auth0_id?: string
	/**
	 * Name of the collection, e.g. “Furniture” or “New Arrivals”
	 */
	collection?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Browsing language that user has selected via site header

	 */
	language?: string
	/**
	 * Unique ID of the collection page being viewed
	 */
	list_id?: string
	/**
	 * T'he search term that was initially entered

	 */
	search_keyword?: string
	/**
	 * Name of sort applied
	 */
	sort_type?: string
}
export interface TabClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Name of the given tab that was clicked, e.g. ‘Overview’, ‘Cecil Beaton Library’, etc.

	 */
	tab_name?: string
	/**
	 * Position of tab
	 */
	tab_position?: number
}
export interface TechnicalRegistrationError {
	/**
	 * Error description
	 */
	error_description?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
}
export interface TelephoneRegistrationComplete {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Live sale bidding method selected during registration
	 */
	bid_method?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface TileGridModuleClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Name of the consignment category the user clicked; e.g. if the user clicked “Automobiles” then pass that value for tile_grid_category
	 */
	tile_grid_category: string
	/**
	 * Locale of the estimate array, e.g. "English", "Chinese", or "French"
	 */
	tile_grid_locale?: string
	/**
	 * Position number of the tile clicked by a user; for example, if the user clicks on the 3rd tile, pass '3'
	 */
	tile_grid_position?: number
}
export interface TimelineModuleClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * title of the entire hotspot component
	 */
	hotspot_component_title?: string
	/**
	 * title of specific hotspot that user has opened, ie. when they click on red circle icon overlaying main image
	 */
	hotspot_content_title?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Title of timeline content
	 */
	timeline_content_title?: string
	/**
	 * Title of timeline
	 */
	timeline_title?: any | null
}
export interface TransactionalBillingAddressView {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
}
export interface TransactionalBillingInformationViewed {
	in_app?: boolean
	used_suggested_address?: boolean
}
export interface TransactionalComplete {
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * email address
	 */
	email?: string
	/**
	 * user's first name
	 */
	first_name?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * user's last name
	 */
	last_name: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * user's salutaion
	 */
	title?: string
	/**
	 * Origin of transactional registration
	 */
	transactional_registration_origin?: string
}
export interface TransactionalCreditCardDetailsInteraction {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
}
export interface TransactionalCreditCardDetailsView {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
}
export interface TransactionalProfileInformationView {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	transactional_registration_origin?: string
}
export interface TransactionalRegistrationCancelled {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	page?: string | null
}
export interface TransactionalRegistrationCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * Origin of transactional registration
	 */
	transactional_registration_origin?: string
	/**
	 * The outcome of the verification process during transactional signups. Accounts are created event though the verification fails
	 */
	verification_result: string
}
export interface TransactionalRegistrationEdit {
	edit_type?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
}
export interface TransactionalRegistrationError {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Error message
	 */
	error_msg: string
	/**
	 * Error type
	 */
	error_type: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
}
export interface TransactionalRegistrationFailure {
	/**
	 * TRUE if the client is a web view within the app
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
}
export interface TransactionalRegistrationReview {
	in_app?: boolean
}
export interface TransactionalRegistrationStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Call to action text, the text on the button that was clicked
	 */
	cta_text?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * Origin of transactional registration
	 */
	transactional_registration_origin?: string
}
export interface TransactionalRegistrationStepCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
	 */
	step: number
	/**
	 * Name of the step that was completed
	 */
	step_name: string
	/**
	 * Origin of transactional registration
	 */
	transactional_registration_origin?: string
	used_suggested_address?: boolean | null
}
export interface TransactionalRegistrationSubmitted {
	in_app?: boolean
	transactional_registration_origin?: string
}
export interface TransactionalValidationError {
	/**
	 * Error description
	 */
	error_description?: string
	/**
	 * Error type
	 */
	error_type: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
}
export interface UnableToVerify {
	/**
	 * Whether address check failed
	 */
	address_check_fail: boolean
	/**
	 * Whether credit card failed
	 */
	credit_card_fail: boolean
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * email address
	 */
	email?: string
	/**
	 * user's first name
	 */
	first_name?: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * user's last name
	 */
	last_name?: string
	/**
	 * Whether Lexis Nexis failed
	 */
	lexis_nexis_fail?: boolean
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * user's title or salutation
	 */
	title?: string
	/**
	 * Origin of transactional registration
	 */
	transactional_registration_origin?: string
}
export interface UploadIdAction {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * The type of the action that the user or service performed while uploading an ID
	 */
	upload_id_action_type?: string
}
export interface UploadIdClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * context of the Upload ID button clicked
	 */
	context?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * If user is Preferred
	 */
	preferred_member_flag?: boolean | null
}
export interface UploadIdError {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Description of error that occurred while uploading an ID
	 */
	upload_id_error_description?: string | null
}
export interface UploadIdFailed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * The reason for the failure of uploading an ID
	 */
	upload_id_failure_reason?: string | null
}
export interface UploadIdSuccess {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface UserSessionError {
	/**
	 * Used in Avo to generate Segment page name
	 */
	'Segment: Page Name'?: string
	/**
	 * The name of the application that the user is signing in/up from
	 */
	application_name: string
	/**
	 * Creator of work
	 */
	artist_maker_author?: string
	/**
	 * Artist resale rights value
	 */
	artist_resale_rights: number
	/**
	 * Viking auction guid

	 */
	auction_id?: string
	/**
	 * Auction name

	 */
	auction_name?: string
	/**
	 * Whether the user is turning the audio on or off
	 */
	audio_streaming_toggle_to: boolean
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Bid hammer amount
	 */
	bid_amount: number
	/**
	 * Currency in three letter acronym format, e.g. "USD"
	 */
	bid_currency: string
	/**
	 * Current bid increment value (equal to or less than the user's maximum bid value)
	 */
	bid_current_amount: number
	/**
	 * Maximum bid amount selected by the user
	 */
	bid_max_amount: number
	/**
	 * For Zaius, "live_online" when user registers for online bidding, "telephone_bid" with telephone registration, or "sale_room_bid" with paddle registration
	 */
	bid_method: string
	/**
	 * Result of the bid placed
	 */
	bid_result?: string
	/**
	 * Type of bid placed
	 */
	bid_type?: string
	/**
	 * Type of interaction within the bidding/auction room
	 */
	bidding_interaction_type: string
	/**
	 * Bounce reason
	 */
	bounce_reason?: string
	/**
	 * Bounce type
	 */
	bounce_type?: string
	/**
	 * Buyer's premium value
	 */
	buyers_premium: number
	/**
	 * Campaign ID
	 */
	campaign_id?: any | null
	/**
	 * Campaign name
	 */
	campaign_name?: any | null
	/**
	 * Details within content card

	 */
	card_details?: string
	/**
	 * Title of content card

	 */
	card_title?: string
	/**
	 * Style of carousel

	 */
	carousel_style?: string
	/**
	 * Title of carousel
	 */
	carousel_title?: string
	/**
	 * Chapter title
	 */
	chapter_title?: string
	/**
	 * Tracking code from offsite marketing campaign
	 */
	cmp_trackingcode?: string
	/**
	 * Either "email" or "phone" depending upon which "Contact Us" medium a user chooses
	 */
	contact_us_medium?: string
	/**
	 * Section of the site
	 */
	content_type?: string
	/**
	 * Call to action link URL
	 */
	cta_link_url: string
	/**
	 * Currency in three letter acronym format, e.g. "USD"

	 */
	currency?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * Number of days until close of auction
	 */
	days_to_close?: string
	/**
	 * Department(s) to which the event is related
	 */
	department_category?: string
	/**
	 * Email ID
	 */
	email_id?: any | null
	/**
	 * Email vendor through which email was delivered
	 */
	email_vendor?: string
	/**
	 * Scheduled end date of timed auction (does not pass for live auctions); it's possible for an auction to close later than ...

	 */
	end_date?: string
	/**
	 * The description of the error that is received, e.g. "bad postal code"
	 */
	error_description: string
	/**
	 * The name of the form that returns the error, for example "credit card details" , "request_timeout", "okta error", "auth0 error"
	 */
	error_type: string
	/**
	 * Currency in three letter acronym format, e.g. "USD"
	 */
	estimate_currency?: string
	/**
	 * High estimate of a given lot
	 */
	estimate_high?: number
	/**
	 * High estimate of a given lot
	 */
	estimate_low?: number
	/**
	 * Estimated total value
	 */
	estimated_total: number
	/**
	 * Origin of follow
	 */
	follow_origin: string
	/**
	 * Hammer price value
	 */
	hammer_price: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * The amount of the bid that the user intended to place prior to encountering the restricted bidding message, i.e. within the advance bidding flow or live auction room
	 */
	intended_bid_amount?: number
	/**
	 * Currency in three letter acronym format, e.g. "USD"
	 */
	intended_bid_currency?: string
	/**
	 * Unique invoice number, e.g “123-456-78"
	 */
	invoice_number: string
	/**
	 * Type of item that user has followed
	 */
	item_type: string
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	keywords?: any | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string
	/**
	 * Link URL
	 */
	link_url?: string
	/**
	 * Date the live auction starts (does not pass for timed auctions)

	 */
	live_bidding_date?: string
	/**
	 * Auction location

	 */
	location?: string
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * The name of the lot, if available
	 */
	lot_name?: string
	/**
	 * Viking lot guid
	 */
	lot_viking_id: string
	/**
	 * Type of message the user views
	 */
	message_type?: string
	/**
	 * Navigation level
	 */
	navigation_level?: string
	/**
	 * Number of lots in the sale
	 */
	num_lots_sale: string
	/**
	 * Number of lots selected
	 */
	num_lots_selected: string
	/**
	 * Number of search filters applied
	 */
	num_search_filters: number
	/**
	 * First date that users are able to start submitting timed bids (for timed sales) and advanced bids (for live)

	 */
	open_date?: string
	/**
	 * The name of the page (or screen in app) from where the action was made
	 */
	pagename?: string
	/**
	 * Type of auction participant
	 */
	participant_type: string
	/**
	 * Amount that user attempts to pay or successfully pays, excluding shipping
	 */
	payment_amount: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Whether the Preference Center was related to Preferred
	 */
	preferred_preference_center?: boolean
	/**
	 * Previous call to action
	 */
	previous_cta?: string
	/**
	 * Previous page name
	 */
	previous_pagename?: string
	/**
	 * Print format, either "thumbnails" or "full description"
	 */
	print_format: string
	/**
	 * origin of print button click
	 */
	print_origin: Record<string, any>
	/**
	 * Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
	 */
	product_id?: string
	/**
	 * Type of interaction with product detail page
	 */
	product_interaction_type?: string
	/**
	 * Date the auction was published; can be before auction opens

	 */
	publish_date?: string
	/**
	 * “Top Searches” if user has submitted a search term that matches one of the top searches presented in the dropdown; passes “Suggestions” if search term matches one of the suggestions presented in the dropdown; the query_suggestion_category property should ONLY pass if a user selects a query suggestion within the dropdown to execute their search
	 */
	query_suggestion_category?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * Type of sale
	 */
	sale_type?: string
	/**
	 * The array of filters that were applied in the search that resulted to the click
	 */
	search_filters?: any | null
	/**
	 * The array of filters that were applied in the search that resulted to the click
	 */
	'search_filters.$'?: any[]
	/**
	 * What was typed into the search field when the event was sent
	 */
	search_keyword: string
	/**
	 * What was the category of the result that was clicked, e.g. art fair, museum, or services (for Preferred experience), or story, lot, auction, etc. on the mobile App
	 */
	search_result_category?: string
	/**
	 * Where in the search result list was the clicked result; used to see how relevant the result list was
	 */
	search_result_position?: number
	/**
	 * The text of the search result that was clicked (page title excluding the content after the  | )
	 */
	search_result_title?: string
	/**
	 * Whether the user used ‘card view’ or ‘list view’ to view the search result before clicking
	 */
	search_view_type?: string
	/**
	 * Shipping and handling amount; passes the amount if it is present (either in the cost estimator or in payments if users select "Pay Now" for the shipping)
	 */
	shipping_amount: number
	/**
	 * The title of the slideshow
	 */
	slideshow_title: string
	sso_login?: boolean
	/**
	 * Origin of transactional registration
	 */
	transactional_registration_origin?: string
	/**
	 * Where in the payment flow the 3DS validation was initiated
	 */
	validation_origin: string
	/**
	 * The title of the video
	 */
	video_title: string
	/**
	 * Which Search Result View Type is being switched to
	 */
	view_type?: string
}
export interface VideoCompanionContentClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Call to action text
	 */
	cta_text?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
}
export interface VideoPlaybackCompleted {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoPlaybackPaused {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * The intended recipient’s email address.
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * The opening browser’s user agent.
	 */
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoPlaybackResumed {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * Previous page name
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * CMP tracking code string
	 */
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoPlaybackSeekCompleted {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * Position number of the tile clicked by a user; for example, if the user clicks on the 3rd tile, pass '3'
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Locale of the estimate array, e.g. "English", "Chinese", or "French"
	 */
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * Length of video seek
	 */
	seek_length?: number
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoPlaybackStarted {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * The current index position in seconds of the playhead. If the playback is a livestream, you must send a negative integer that represents the offset in seconds in relation to the current timestamp. For example, if content is being livestreamed at 8PM but the viewer is 30 seconds behind, the value of this property should be -30
	 */
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoPlaylistSelected {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Video player
	 */
	video_player?: string
}
export interface VideoSelected {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any[]
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * Position of video within the playlist
	 */
	playlist_video_position: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface Keywords {
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	$?: any[]
}
export interface VideoShared {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * URL of external (non sothebys.com) link
	 */
	external_link: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: Keywords
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Video topic tag
	 */
	topic?: string
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoStreamInteraction {
	/**
	 * What was the interaction that was made: on, off, fullscreen, mute
	 */
	video_interaction_type?: string
}
export interface VideoWatched25 {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * Map Interaction
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * title of the form, e.g. "Lost Card Request Form"
	 */
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoWatched50 {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	/**
	 * number of days until close of auction
	 */
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * name of the page
	 */
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface VideoWatched75 {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
	 */
	closed_captions?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
	 */
	full_episode?: boolean
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen: boolean
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	'keyword.$'?: any | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content.
	 */
	keywords?: any | null
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean
	/**
	 * Playlist display name, ie. 'related' or 'trending'
	 */
	playlist_display_name?: string
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string
	position?: number
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	program?: string
	/**
	 * The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
	 */
	quality?: string
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * Video player
	 */
	video_player?: string
	/**
	 * Video title
	 */
	video_title?: string
}
export interface ViewConditionReport {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * Condition Report
	 */
	details?: string
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}
export interface ViewPrintCatalogueClicked {
	/**
	 * Call to action link URL
	 */
	cta_link_url: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface ViewTypeSelected {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: number
	/**
	 * What page was the event fired from, e.g. "Auction Page", "Lot Page", "Marketplace"
	 */
	page_name?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * New format: which view is selected, e.g. "Grid" or "List".              
 Old format: Defined by [page name]: [view selection], e.g. if the user is on the Auction Calendar and selects grid view then view_selection should pass “Auction Calendar: Grid”, or if they instead selected the List view then pass “Auction Calendar: List”
	 */
	view_selection: string
}
export interface WalletConnectClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
	/**
	 * The type of option that user clicks in the wallet connect, either name of wallet or label of link
	 */
	wallet_option_clicked: string
}
export interface WalletConnectOpened {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * customer_id
	 */
	customer_id?: number
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * From where was the wallet connect opened
	 */
	origin?: string
	/**
	 * Six-digit SAP sale ID

	 */
	sale_id?: string
}
export interface ZoomView {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string
	/**
	 * Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
	 */
	customer_id?: any | null
	/**
	 * Selling department
	 */
	department_category?: string
	/**
	 * SAP 6-digit sale Number concatenated with lot number
	 */
	lot_id?: string
	/**
	 * If user is Preferred, then "true" otherwise "false"
	 */
	preferred_member_flag?: boolean
	/**
	 * Six-digit SAP sale number plus lot number (4 digits)
	 */
	product_id?: string
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef AbsenteeRegistrationComplete
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} registration_source - Passes 'web' during live sale registration
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AccountProfileCompleted
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef AccountProfileUpdateSubmitted
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef AddToBagClicked
 * @property {number} [atc_value] - Price*.25. Used for GoogleAd optimization
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [ecomm_pagetype] - For this event it should be "cart", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, should be array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, same as price, MP-1280
 * @property {string} [image_url] - Image URL of the product image
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [lot_viking_id] - Viking lot guid
 * @property {string} [name] - name of the product
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {boolean | null} [ships_internationally] - Boolean which is true if international shipping is available for the item
 * @property {string} [sku] - Sku of the product
 * @property {string} [tags] - Tags, should be optional string, MP-1327
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef AdditionalInformationNeeded
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef AuctionDetailsInteraction
 * @property {string} auction_interaction - Type of auction interaction
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionDetailsViewed
 * @property {string} [department_category] - Selling department
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 */
/**
 * @typedef AuctionFilterApplied
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [filter_applied] - Filter that was applied that triggered the event
 * @property {string} [filter_widget_type] - Denotes the UI filter widget type and is useful, for example, when AB testing
 * @property {any[]} [filters] - Product filters that the customer is using
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} num_search_filters - Number of search filters applied
 * @property {number} num_search_results - The number of search results returned
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [search_keyword] - T'he search term that was initially entered
 */
/**
 * @typedef AuctionFilterCleared
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {any[]} [filters] - Product filters that the customer is using
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} num_search_filters - Number of search filters applied
 * @property {number} [num_search_results] - Number of search filters applied
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionLandingCarouselClick
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [lot_carousel_url] - URL of lot clicked in carousel
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionPageView
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionRegistrationAccountSelected
 * @property {string} auction_registration_origin - Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [registration_source] - Passes 'web' during live sale registration
 * @property {boolean} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionRegistrationBiddingMethodSelected
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [registration_source] - Passes 'web' during live sale registration
 * @property {boolean} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionRegistrationButtonClicked
 * @property {string} auction_registration_origin - Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {any | null} [registration_source] -
 * @property {boolean} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {any | null} [sale_id] -
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 */
/**
 * @typedef AuctionRegistrationCompleted
 * @property {string} auction_registration_origin - Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} [num_products] - number of items selected
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
 * @property {string} [registration_source] - Passes 'web' during live sale registration
 * @property {boolean} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef AuctionRegistrationLotsSelected
 * @property {string} auction_registration_origin - Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} [num_products] - number of items selected
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
 * @property {string} [registration_source] - Passes 'web' during live sale registration
 * @property {boolean} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionRegistrationPhoneBiddingClicked
 * @property {string} auction_registration_origin - Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page 
 * @property {string} bid_method - For Zaius, "live_online" when user registers for online bidding, "telephone_bid" with telephone registration, or "sale_room_bid" with paddle registration 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} registration_source - Source of registration
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef AuctionRegistrationPhoneBiddingConfirmed
 * @property {string} auction_registration_origin - Origin of the auction registration; passes "auction details" if registration related event occurred via the auction landing page, and "lot page" if registration related event occurred via the lot detail page 
 * @property {string} bid_method - For Zaius, "live_online" when user registers for online bidding, "telephone_bid" with telephone registration, or "sale_room_bid" with paddle registration 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} num_lots_selected - Number of lots selected
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A. 
 * @property {string} registration_source - Source of registration
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef AuctionRoomEntered
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auction_name] - Auction name
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [participant_type] - Matches "bidder" if logged into bidding room as a bidder, and "watcher" if not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef AuctionRoomInteraction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} bidding_interaction_type - Interaction within the bidding room
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [participant_type] - Matches "bidder" if logged into bidding room as a bidder, and "watcher" if not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef AudioPlaybackPaused
 * @property {Record<string, any>} [] -
 * @property {Record<string, any>} [auth0_id] - User's Auth0 value
 * @property {string} chapter_title - Chapter title
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef AudioPlaybackStarted
 * @property {Record<string, any>} [auth0_id] - User's Auth0 value
 * @property {string} chapter_title - Chapter title
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef AudioStreamingToggle
 * @property {string} [auction_id] - Viking auction guid

 * @property {string} [auction_name] - Auction name

 * @property {string} [audio_stream_toggle] - The state of the audio stream toggle after interaction (pressing the audio icon)
 * @property {boolean} audio_streaming_toggle_to - Whether the user is turning the audio on or off
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef Authenticated
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [email] - email address
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {boolean} sso_login - If SSO login is true or false
 */
/**
 * @typedef BiDnowRegistrationComplete
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} registration_source - Passes 'web' during live sale registration
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef BidConfirmationInteraction
 * @property {string} [bid_confirmation_interaction_type] - The interaction that the user initiated on the bid confirmation screen
 * @property {string} [bid_result] - Result of the bid placed
 */
/**
 * @typedef BidPlaced
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auction_name] - Auction name
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} bid_amount - Maximum bid amount
 * @property {string} bid_currency - Currency of bid placed
 * @property {string} bid_result - Result of bid placed
 * @property {string} bid_type - Type of bid placed
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {string} [lot_name] - Lot name
 * @property {string} lot_viking_id - Viking lot GUID
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef BidStepCompleted
 * @property {number} [bid_amount] -
 */
/**
 * @typedef CarouselClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [carousel_style] - Style of carousel
 * @property {string} [carousel_title] - Title of carousel
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef ProductsItem
 * @property {string} [category] - Category of the product
 * @property {string} [category_2] - Secondary category of product
 * @property {string} [creator] - Creator of the product
 * @property {string} [name] - 
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - 
 * @property {string} [product_type] - 
 * @property {boolean} [product_unavailable] - TRUE if the product has become unavailable at the time of the cart related event being sent (e.g. cart viewed or product removed)
 * @property {number} [quantity] - Quantity of the product
 * @property {boolean | null} [ships_internationally] - Boolean which is true if
 international shipping is available for the item
 */
/**
 * @typedef CartViewed
 * @property {string} [cart_id] - ID of the cart being viewed
 * @property {string} [content_type] - Section of the site
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {string} [ecomm_pagetype] - for this event this property should be "cart", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap item id, array of strings MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, MP-1280
 * @property {boolean} [logged_in] - true if logged in, false if not  MP-1226
 * @property {boolean} [no_cart_available] - If there is no cart available (or empty cart) set this to TRUE
 * @property {ProductsItem[]} [products] - Products within a cart
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 */
/**
 * @typedef ChapterNavigationClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} chapter_title - Chapter title
 * @property {number} [customer_id] - customer_id
 * @property {string} [navigation_level] - Navigation level
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ChapterViewed
 * @property {Record<string, any>} [] -
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} chapter_title - Chapter title
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ProductsItem1
 * @property {string} [category] - Category of the product
 * @property {string} [creator] - Creator of the product
 * @property {string} [name] -
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] -
 * @property {string} [product_type] -
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef CheckoutError
 * @property {string} [cart_id] - 
 * @property {string} [checkout_type] - Checkout type purchase or make an offer
 * @property {string} [content_type] - Section of the site
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {string} [department_category] - departments concatenated to a string, delimited by a |
 * @property {string} error_description - The description of the error that is received, e.g. "bad postal code"
 * @property {string} [error_msg] - Error message
 * @property {ProductsItem1[]} [products] - Products within a cart
 * @property {string} [step_of_checkout] - The step that the error occured in
 * @property {string} [zip_code] - Zip code entered (if applicable)
 */
/**
 * @typedef ProductsItem2
 * @property {string} [category] - Category of the product
 * @property {string} [category_2] - Secondary category of product
 * @property {string} [creator] - Creator of the product
 * @property {string} [name] -
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] -
 * @property {string} [product_type] -
 * @property {number} [quantity] - Quantity of the product
 * @property {boolean | null} [ships_internationally] -
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef CheckoutStarted
 * @property {string} [cart_id] -
 * @property {string} [checkout_type] - Checkout type purchase or make an offer
 * @property {string} [content_type] - Section of the site
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {string} [department_category] - all departments concatenated to a string, delimited by a |
 * @property {string} [order_id] -
 * @property {ProductsItem2[]} [products] - Products within a cart
 * @property {number} [revenue] - Revenue associated with the transaction (excluding shipping and tax)
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef ProductsItem3
 * @property {string} [category] - Category of the product
 * @property {string} [creator] - Creator of the product
 * @property {string} [name] -
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] -
 * @property {string} [product_type] -
 * @property {number} [quantity] - Quantity of the product
 * @property {Record<string, any>} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [cart_id] -
 * @property {string} [checkout_type] - Checkout type purchase or make an offer
 * @property {string} [content_type] - Section of the site
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {string} [department_category] - departments concatenated to a string, delimited by a |
 * @property {boolean} [has_variants] - True if the product has variants enabled and available
 * @property {string} [order_id] -
 * @property {ProductsItem3[]} [products] - Products within a cart
 * @property {number} [step] - Number representing a step in the checkout process
 * @property {string} [step_name] - Name of the step that was completed
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef ProductsItem4
 * @property {string} [category] - Category of the product
 * @property {string} [cateory_2] - Secondary category of product
 * @property {string} [creator] - Creator of the product
 * @property {string} [name] -
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] -
 * @property {string} [product_type] -
 * @property {boolean} [product_unavailable] - TRUE if the product has become unavailable at the time of the cart related event being sent (e.g. cart viewed or product removed)
 * @property {number} [quantity] - Quantity of the product
 * @property {string | null} [shipping_method] -
 * @property {boolean | null} [ships_internationally] -
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef CheckoutStepViewed
 * @property {string} [cart_id] -
 * @property {string} [checkout_type] - Checkout type purchase or make an offer
 * @property {string} [content_type] - Section of the site
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {string} [department_category] - departments concatenated to a string, delimited by a |
 * @property {boolean} [has_variants] - True if the product has variants enabled and available
 * @property {Record<string, any>} [order_id] -
 * @property {ProductsItem4[]} [products] - Products within a cart
 * @property {number} [revenue] -
 * @property {number | null} [shipping] - Shipping cost associated with the transaction
 * @property {number} [step] - Number representing a step in the checkout process
 * @property {string} [step_name] - NAme of the step being viewed, e.g. "Shipping Address"
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 */
/**
 * @typedef ClickAction
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {string} click_type - The type of click that triggered the event, e.g. \"carousel click\", \"contact us\" etc.
 * @property {string} content_type - Section of the site
 * @property {string | null} [cta_text] - Call to action text, the text on the button that was clicked
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {string | null} [department_code] - The internal code of the relevant department
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string | null} [product_id] - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [product_type] - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef CompleteYourProfileClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ConditionsOfSaleViewed
 * @property {string} [department_category] - Selling department
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 */
/**
 * @typedef ConfirmEmailAddressRequested
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef ConfirmQuickBidButton
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} bid_amount - Maximum bid amount
 * @property {string} bid_currency - Currency of bid placed
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef ConsignmentCategorySelected
 * @property {string} [consignment_category] -
 * @property {string} [consignment_sub_category] -
 */
/**
 * @typedef ConsignmentErrorItemDetails
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef ConsignmentErrorPicsDocs
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ConsignmentItemDetails
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ConsignmentItemDetailsEntered
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {number} [customer_id] - customer_id
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ConsignmentResumed
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [step] - Step; in consignment flow equals "category", "details", "photos", "address", "review"
 */
/**
 * @typedef ConsignmentReview
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ConsignmentSignInSelected
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [step] - Step; in consignment flow equals "category", "details", "photos", "address", "review"
 */
/**
 * @typedef ConsignmentSignUpSelected
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [step] - Step; in consignment flow equals "category", "details", "photos", "address", "review"
 */
/**
 * @typedef ConsignmentStarted
 * @property {boolean} [logged_in] - true if logged in, false if not
 */
/**
 * @typedef ConsignmentStepCompleted
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [step] - Step; in consignment flow equals "category", "details", "photos", "address", "review"
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef ConsignmentStepError
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [step] - Step; in consignment flow equals "category", "details", "photos", "address", "review"
 */
/**
 * @typedef ConsignmentStepViewed
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [step] - Step; in consignment flow equals "category", "details", "photos", "address", "review"
 */
/**
 * @typedef ConsignmentSubmit
 * @property {string} [auction_estimate_referencecode] - Reference code
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {string} [consignor_location_country_code] - Country location code of potential consignor
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {string} [item_location_country_code] - Country location code of item
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef ConsignmentSubmitted
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {boolean} [logged_in] - true if logged in, false if not
 */
/**
 * @typedef ConsignmentUploads
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [consignment_category] - Category of object that user is uploading to consignment tool
 * @property {string} [consignment_item_id] - Unique ID of the consignment submission
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ContentCardClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [card_details] - Details within content card
 * @property {string} [card_title] - Title of content card 
 * @property {string} card_type - Type of content card
 * @property {string} [carousel_style] - Style of carousel
 * @property {string} [carousel_title] - Title of carousel
 * @property {string} [collection] - Collection name, used in marketplace app
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {Record<string, any>} [list_id] - Collection name, used in marketplace app
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef ContentHeroClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [hero_version] - Version of hero
 * @property {string} [homepage_featuredtext] - Featured text on homepage hero
 * @property {string} [homepage_imagename] - Homepage image name
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ContinueToConfirmBidButton
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} bid_amount - Maximum bid amount
 * @property {string} bid_currency - Currency of bid placed
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {boolean} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef CostEstimated
 * @property {number} artist_resale_rights - Artist resale rights value
 * @property {number} buyers_premium - Buyer's premium value
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {number} estimated_total - Estimated total value
 * @property {number} hammer_price - Hammer price value
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {number} shipping_amount - Shipping and handling amount; passes the amount if it is present (either in the cost estimator or in payments if users select "Pay Now" for the shipping)
 */
/**
 * @typedef CostEstimatorError
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {number} hammer_price - Hammer price value
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef CostEstimatorOpened
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef CouponApplied
 * @property {string} [coupon_name] - Name of the coupon
 */
/**
 * @typedef CouponDenied
 * @property {string} [coupon_name] - Name of the coupon
 */
/**
 * @typedef CouponEntered
 * @property {string} [coupon_name] - Name of the coupon
 */
/**
 * @typedef CouponRemoved
 * @property {string} [coupon_name] - Name of the coupon
 */
/**
 * @typedef CurrencySelected
 * @property {string} [currency_auction] - Currency of the auction
 * @property {string} [currency_new] - The currency the user is switching TO; e.g. if user is switching from USD to HKD then ‘currency’ should be USD and ‘currency_new’ should be HKD 
 * @property {string} [currency_selection_origin] - Where the currency conversion took place; i.e. auction room, lot page, bidding flow, auction lot list, account
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [original_currency] - Original currency of the item
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef DownloadAppButtonClicked
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef DownloadAppModalViewed
 * @property {Record<string, any>} [] - 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef EmailConfirmationFailed
 * @property {string | null} [email] - email address
 * @property {string | null} [error_msg] - Error message
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef EmailConfirmed
 * @property {string | null} [email] - email address
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef EmailSignup
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [form_page] - Segment page name of where the form was submitted (whatever is being passed in as name in page tracking), e.g. "Homepage: En"
 * @property {string} [form_title] - title of the form, e.g. "Lost Card Request Form"
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef EmailSubscribed
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [event_date] - Date of event (ISO 8601)
 * @property {string} [event_time] - Time of the event
 * @property {string} [form_page] - Segment page name of where the form was submitted (whatever is being passed in as name in page tracking), e.g. "Homepage: En"
 * @property {string} [form_title] - title of the form, e.g. "Lost Card Request Form"
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [rsvp] - Whether user is attending event or not
 * @property {string} [rsvp_quantity] - Total number of people attending
 * @property {string} [sweep_city] - City of sweep
 * @property {string} [sweep_country] - Country of sweep
 * @property {string} [sweep_date] - Date of sweep (ISO 8601)
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef EnquireClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [ecomm_pagetype] - For this event it should be "cart", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, should be array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, same as price, MP-1280
 * @property {string} [image_url] - Image URL of the product image
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [lot_viking_id] - Viking lot guid
 * @property {string} [name] - name of the product
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [sku] - Sku of the product
 * @property {string} [tags] - Tags, should be optional string, MP-1327
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 */
/**
 * @typedef User
 * @property {string} email - Email
 * @property {string} first_name - First Name
 * @property {string} last_name - Last Name
 * @property {string} [phone_number] - Phone Number
 */
/**
 * @typedef EnquireSubmitted
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [ecomm_pagetype] - For this event it should be "cart", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, should be array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, same as price, MP-1280
 * @property {string} [image_url] - Image URL of the product image
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [lot_viking_id] - Viking lot guid
 * @property {string} [name] - name of the product
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [sku] - Sku of the product
 * @property {string} [tags] - Tags, should be optional string, MP-1327
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 * @property {User} [user] - Form Details
 */
/**
 * @typedef ExitLinkClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} external_link - URL of external (non sothebys.com) link
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ExperimentViewed
 * @property {string} [auth0_id] - User's Auth0 value
 */
/**
 * @typedef FaqClick
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [faq_text] - Defined by [Segment page name]: [faq text]
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef FilterResultsClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [filter_search_keyword] - search term typed into the filter component search field
 * @property {string} [filter_selections] - concatenated list of filter selections, ie. "[item 1]" | "[item2]" | "[item3]" etc
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef FormSubmitted
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [form_page] - Segment page name of where the form was submitted (whatever is being passed in as name in page tracking), e.g. "Homepage: En"
 * @property {string} [form_title] - title of the form, e.g. "Lost Card Request Form"
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef GridView
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef HotspotClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [hotspot_component_title] - title of the entire hotspot component
 * @property {string} [hotspot_content_title] - title of specific hotspot that user has opened, ie. when they click on red circle icon overlaying main image
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef HotspotOpened
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [hotspot_component_title] - title of the entire hotspot component
 * @property {string} [hotspot_content_title] - title of specific hotspot that user has opened, ie. when they click on red circle icon overlaying main image
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef InteractiveModuleClicked
 * @property {string} [module_type] -
 */
/**
 * @typedef InternalLink
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} internal_link - Internal (sothebys.com domain) URL
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ItemFollowed
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [follow_origin] - Item Followed origin
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [item_type] - Type of item
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [sale_type] - Type of sale
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef ItemUnfollowed
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [follow_origin] - Item Followed origin
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [item_type] - Type of item
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [sale_type] - Type of sale
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef LearnMore
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [component] - Where did the user click on learn more (How do offers work / authenticity guaranteed / international shipping)
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [name] - name of the product
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Retail item id
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {string} [sku] - SKU number of the item
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 */
/**
 * @typedef LinkButtonClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} link_button - Link button call to action text
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef LoadMoreResultsClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} num_lots_sale - Number of lots in the sale
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {number} sequence - Sequence of events that could occur consecutively on the same page; for example if user has clicked "Load More Results Clicked" on infinite scroll for the 2nd time, pass "2"
 */
/**
 * @typedef LoginAttempt
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 */
/**
 * @typedef LoginAttemptError
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 */
/**
 * @typedef LoginClicked
 * @property {string} [content_type] - Section of the site
 * @property {string} cta_text - Call to action text, the text on the button that was clicked
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string} login_context - What action or ask initiated the login? This is used to understand what drives users to login. The difference from cta is that this gives the context of the page or modal and not just the cta of the button
 * @property {string | null} [placement] - Where on the page or screen is the thing placed, e.g. \"top nav bar\" or \"next to bid button\".  This differs from 'position' as that has only an integer position of an item in a list.
 * @property {string | null} [product_id] - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string | null} [sale_name] - The name of the sale (auction or specific buy-now sale)
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef LoginStart
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 */
/**
 * @typedef LoginToBid
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auction_name] - Auction name
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [lot_name] - Lot name
 * @property {string} lot_viking_id - Viking lot GUID
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef LotTracked
 * @property {string} [auth0_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {any | null} [customer_id] - If user is Preferred, then "true" otherwise "false"
 * @property {boolean} [preferred_member_flag] - Six-digit SAP sale number
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - User's Auth0 value
 */
/**
 * @typedef MakeAnOfferClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [ecomm_pagetype] - For this event it should be "cart", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, should be array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, same as price, MP-1280
 * @property {string} [image_url] - Image URL of the product image
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {string} [location] - PDP or modal (if clicking from "how do offers work"
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [lot_viking_id] - Viking lot guid
 * @property {number} [mao_value] - Price*.25  Used for GoogleAd optimization
 * @property {string} [name] - name of the product
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [sku] - Sku of the product
 * @property {string} [tags] - Tags, should be optional string, MP-1327
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 */
/**
 * @typedef MaybeLaterClicked
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {number | null} [customer_id] - Client system party ID
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {boolean | null} [preferred_member_flag] - If user is Preferred
 */
/**
 * @typedef MeiMosesChartInteraction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [mei_moses_chart_name] - Name of Mei Moses chart
 * @property {number} mei_moses_chart_page - number of page that the user toggles to (integer value) via the arrows on the left or right of the iframe
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef MeiMosesChartLoaded
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [mei_moses_chart_name] - Name of Mei Moses chart
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef MyProfileUpdated
 * @property {string} update_type - Type of Update
 */
/**
 * @typedef Navigated
 * @property {string} [Segment: Page Name] - Used in Avo to generate Segment page name
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [cmp_trackingcode] - CMP tracking code string
 * @property {string} [content_type] - Type of content
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [previous_cta] - Previous call to action
 * @property {string} [previous_pagename] - Previous page name
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [sale_type] - Type of sale
 */
/**
 * @typedef NavigationClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} cta_link_url - Call to action link URL
 * @property {string} [cta_text] - Call to action text
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [navigation_level] - For links within the 3 primary navigational options, pass "Main Navigation Item - BUY", "Main Navigation Item - SELL", "Main Navigation Item - DISCOVER" depending on the location of the navigational click. For lesser links (on the right hand side of the header – see screenshot), pass "Top Level Navigation", for the items on the lower right send 'Sub Navigation Item - MUSEUM NETWORK', 'Sub Navigation Item - ABOUT' and 'Sub Navigation Item - SERVICES'
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef NotAllowedToBid
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [email] - email address
 * @property {string | null} [first_name] - user's first name
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string | null} [last_name] - user's last name
 * @property {string} [message_type] - Type of message the user views
 * @property {boolean} preferred_member_flag - If user is Preferred
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string | null} [title] - user's title or salutation
 * @property {string} [transactional_registration_origin] - Origin of transactional registration
 */
/**
 * @typedef NotifyMe
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} loggedIn_status - NotifyMe
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {string} [lot_item_id] - Why not part of bidding???
 * @property {string} notify_me_origin - NotifyMe
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef ProductsItem5
 * @property {string} [category] - Category of the product
 * @property {string} [category_2] - Secondary category of the product
 * @property {string} [creator] - Creator of the product
 * @property {string} [department] - Name of the department to which the item belongs
 * @property {string} [name] -
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] -
 * @property {string} [product_type] -
 * @property {number} [quantity] - Quantity of the product
 * @property {boolean | null} [ships_internationally] -
 */
/**
 * @typedef OfferCompleted
 * @property {string} [cart_id] - offer bag id
 * @property {string} [content_type] - Section of the site
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {string} [department_category] - departments concatenated to a string, delimited by a |
 * @property {string} [ecomm_pagetype] - for this event it should be "purchase", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, MP-1280
 * @property {string} offer_id - Offer/transaction ID
 * @property {string} [offer_number] - Numerical number of offer
 * @property {number} [offer_price] - Offer amount the user enters
 * @property {number} [percent_of_list_price] - The percentage difference between list price and offer price
 * @property {ProductsItem5[]} [products] - Products within a cart
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {string} [shipping_method] - Represents the shipping the method chosen (only pass this value if a shipping method is set)
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page (MP-1092)
 */
/**
 * @typedef OfferEntered
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [ecomm_pagetype] - For this event it should be "cart", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, should be array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, same as price, MP-1280
 * @property {string} [image_url] - Image URL of the product image
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {boolean} [logged_in] - true if logged in, false if not
 * @property {string} [name] - name of the product
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {number} [offer_price] - Offer amount the user entered
 * @property {number} [percent_of_list_price] - The percentage difference between list price and offer price
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [tags] - Tags, should be optional string, MP-1327
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 */
/**
 * @typedef OnboardingModuleInteraction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef ProductsItem6
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [name] - Name of product
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} [product_id] - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef OrderCancelled
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem6[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef ProductsItem7
 * @property {string} [category] - Category of the product
 * @property {string} [category_2] - Secondary category of the product
 * @property {string} [creator] - Creator of the product
 * @property {string} [department] - Name of the department to which the item belongs
 * @property {string} [name] -
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] -
 * @property {string} [product_type] -
 * @property {number} [quantity] - Quantity of the product
 * @property {boolean | null} [ships_internationally] -
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef OrderCompleted
 * @property {string} [cart_id] -
 * @property {string} [content_type] - Section of the site
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {string} [department_category] - departments concatenated to a string, delimited by a |
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} [ecomm_pagetype] - for this event it should be "purchase", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, MP-1280
 * @property {boolean} [has_variants] - True if the product has variants enabled and available
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} order_id - Order/transaction ID
 * @property {string} [payment_method] -
 * @property {ProductsItem7[]} [products] - Products within a cart
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {string} [seller] - The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {string} [shipping_method] - Represents the shipping the method chosen (only pass this value if a shipping method is set)
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [userToken] - Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef ProductsItem8
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [name] - Name of product
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} [product_id] - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef OrderRefunded
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem8[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef PaddleRegistrationComplete
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} registration_source - Passes 'web' during live sale registration
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef PageViewed
 * @property {any | null} [auctionId] -
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [author] - Author of editorial piece
 * @property {any | null} [client_userid] -
 * @property {string | null} [cmp_trackingcode] - CMP tracking code string
 * @property {string} [content_type] - Type of content
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {string} [edition_code] - Edition code of the consignment tool
 * @property {string} [editorial_content] - url path of editorial content
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - user's selected language
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {string} [name] - page name
 * @property {string} path - window.location.path
 * @property {string} [preferred_content] - Set to true if content is accessible to Preferred only
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [previous_cta] - Previous call to action
 * @property {string} [previous_page_url] - url of previous page
 * @property {string} [previous_pagename] - Previous page name
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [rakuten_referral_data] - Rakutan referral data
 * @property {string} referrer - window.document.referrer
 * @property {string} [related_sales] - array of related sales
 * @property {string} [repeat_user] - Repeat if user is a repeat user, New if not
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [sale_type] - Type of sale
 * @property {string} search - window.location.search
 * @property {string} [studios_content] - Set to true if content is related explicitly to Studios (e.g. could be Studios content related to Article, Slideshow, Video, City Guide, etc.); false if not related explicitly to Studios (e.g. sothebys.com homepage)
 * @property {string} title - user's salutaion
 * @property {string} [topic] - Video topic tag
 * @property {string} [url] - The value of UIApplicationLaunchOptionsURLKey from launchOptions (auto-collected on iOS only)
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef PasswordChangeRequested
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef Payment3DsError
 * @property {string} [auth_id] - User's Auth0 value
 * @property {string} [currency] - Currency code associated with the transaction

 * @property {string} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [error_type] - Error Type
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not

 * @property {string} [invoice_number] - Invoice number
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"

 */
/**
 * @typedef Payment3DsValidationStarted
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {number} [payment_amount ] - Amount that user attempts to pay or successfully pays, excluding shipping
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [validation_origin] - Where in the payment flow the 3DS validation was initiated
 */
/**
 * @typedef PaymentAddNewMethod
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PaymentCancel
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [screen] - Screen name
 */
/**
 * @typedef PaymentError
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [checking_accounts_selected] - Quantity of checking accounts selected
 * @property {number} [checking_payment_amount] - Amount being paid by checking
 * @property {number} [creditcard_accounts_selected] - Quantity of credit cards selected
 * @property {number} [creditcard_payment_amount ] - Amount being paid by credit card
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {string} [error_msg] - Error message
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {number} payment_amount - Amount that user attempts to pay or successfully pays, excluding shipping 
 * @property {number} [payment_balance] - Remaining balance that user must pay (i.e. total invoice amount less payment_amount) 
 * @property {string} [payment_error] - Reason for payment error or failure
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account 
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A. 
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {number} [shipping_amount] - Shipping amount
 * @property {number} [total_invoice_amount] - Total amount of the invoice
 */
/**
 * @typedef ProductsItem9
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [name] - Name of product
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} [product_id] - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 */
/**
 * @typedef PaymentInfoEntered
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {ProductsItem9[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef PaymentMethodSelected
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [checking_accounts_selected] - Quantity of checking accounts selected
 * @property {number} [checking_payment_amount] - Amount being paid by checking
 * @property {number} [creditcard_accounts_selected] - Quantity of credit cards selected
 * @property {number} [creditcard_payment_amount ] - Amount being paid by credit card
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account 
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A. 
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef PaymentNewMethodError
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [error_msg] - The returned error message for the error, if available
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [payment_method] - The type of payment method added or used
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PaymentNewMethodSuccess
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [cc_type] - Credit Card Type
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [payment_method] - The type of payment method added or used
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PaymentProcessStarted
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [has_shipping_options] - Yes/No wether the invoice offers a "Shipping options" step in the payment process
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A. 
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {number} [total_invoice_amount] - Total amount of the invoice
 */
/**
 * @typedef PaymentSelectInvoice
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {boolean} invoice_payable - Whether invoice is payable or not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {number} [total_invoice_amount] - Total amount of the invoice
 */
/**
 * @typedef PaymentShipping
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {boolean} [pay_now] - Is true if user is paying for shipping now but false otherwise
 * @property {number} payment_amount - Amount that user attempts to pay or successfully pays, excluding shipping
 * @property {string} [payment_method] - The type of payment method added or used
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [shipping_amount] - Shipping amount
 */
/**
 * @typedef PaymentSubmit
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [checking_accounts_selected] - Quantity of checking accounts selected
 * @property {number} [checking_payment_amount] - Amount being paid by checking
 * @property {number} [creditcard_accounts_selected] - Quantity of credit cards selected
 * @property {number} [creditcard_payment_amount ] - Amount being paid by credit card
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {number} payment_amount - Amount that user attempts to pay or successfully pays, excluding shipping 
 * @property {number} [payment_balance] - Remaining balance that user must pay (i.e. total invoice amount less payment_amount) 
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account 
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A. 
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {number} [shipping_amount] - Shipping amount
 * @property {number} [total_invoice_amount] - Total amount of the invoice
 */
/**
 * @typedef PaymentSuccess
 * @property {any | null} [Segment: Currency] - 
 * @property {number} [Segment: Revenue] - amount of revenue the event resulted in
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [cc_type] - Credit Card Type
 * @property {number} [checking_accounts_selected] - Quantity of checking accounts selected
 * @property {number} [checking_payment_amount] - Amount being paid by checking
 * @property {number} [creditcard_accounts_selected] - Quantity of credit cards selected
 * @property {number} [creditcard_payment_amount ] - Amount being paid by credit card
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {boolean} [is_address_match] - Does billing address and shipping address match
 * @property {number} payment_amount - Amount that user attempts to pay or successfully pays, excluding shipping 
 * @property {number} [payment_balance] - Remaining balance that user must pay (i.e. total invoice amount less payment_amount) 
 * @property {string} [payment_channel] - Payment Channel
 * @property {string} [payment_method_category] - The category of the payment_method, e.g.  credit card, checking account 
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A. 
 * @property {number} [revenue] - Revenue ($) associated with the transaction
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {number} [shipping_amount] - Shipping amount
 * @property {number} [total_invoice_amount] - Total amount of the invoice
 */
/**
 * @typedef PercentPageViewed100
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {number} page_progress - Scroll
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PercentPageViewed25
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {number} page_progress - Scroll
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PercentPageViewed50
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {number} page_progress - Scroll
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PercentPageViewed75
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {number} page_progress - Scroll
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PlaceBidButton
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auction_name] - Auction name
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [cta_text] - Text of call to action, e.g. "Place Bid" or "Increase Your Bid"
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [lot_name] - Lot name
 * @property {string} lot_viking_id - Viking lot GUID
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {boolean} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 */
/**
 * @typedef PlaceMaximumBidButton
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} bid_amount - Maximum bid amount
 * @property {string} bid_currency - Currency of bid placed
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef PreferredContentCardClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [card_details] - Details within content card
 * @property {string} [card_title] - Title of content card
 * @property {string} card_type - Type of content card
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} view_selected - View option selected
 */
/**
 * @typedef PreferredMapInteraction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [cluster_size] - Map Interaction
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} map_interaction_type - Map Interaction
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef PreferredSearchResultClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [search_keyword] - T'he search term that was initially entered
 * @property {string} [search_result_category] - The type of result, e.g. ""Article"", ""Lot"", etc
 * @property {string} search_result_title - Title of the result that was clicked on
 * @property {string} view_selected - View option selected
 */
/**
 * @typedef PreferredViewTypeSelected
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [search_result_category] - The type of result, e.g. ""Article"", ""Lot"", etc
 * @property {string} search_result_title - Title of the result that was clicked on
 * @property {string} view_selection - Defined by [page name]: [view selection], e.g. if the user is on the Auction Calendar and selects grid view then view_selection should pass “Auction Calendar: Grid”, or if they instead selected the List view then pass “Auction Calendar: List”
 */
/**
 * @typedef PrintButtonClicked
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} print_origin - origin of print button click  
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef PrintCompleted
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} num_lots_sale - Number of lots in the sale
 * @property {number} num_lots_selected - Number of lots selected
 * @property {string} print_format - Print format, either "thumbnails" or "full description"
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef PrintFormatSelected
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} num_lots_sale - Number of lots in the sale
 * @property {number} num_lots_selected - Number of lots selected
 * @property {number} [num_search_filters] - Number of search filters applied
 * @property {string} print_format - Print format, either "thumbnails" or "full description"
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef PrintLotsSelected
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} num_lots_sale - Number of lots in the sale
 * @property {number} num_lots_selected - Number of lots selected
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef PrivateSaleProductClicked
 * @property {string} [name] - Name of product
 */
/**
 * @typedef ProductsItem10
 * @property {string} [category] - Category of the product
 * @property {string} [creator] - Creator of the product
 * @property {string} [name] -
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] -
 * @property {string} [product_type] -
 * @property {number} [quantity] - Quantity of the product
 * @property {boolean | null} [ships_internationally] -
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef ProceedToCheckout
 * @property {string} [cart_id] - 
 * @property {string} [checkout_type] - Checkout type purchase or make an offer
 * @property {string} [content_type] - Section of the site
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {string} [department_category] - departments concatenated to a string, delimited by a |
 * @property {string} [order_id] - 
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {ProductsItem10[]} [products] - Products within a cart
 * @property {number} [revenue] - Revenue associated with the transaction (excluding shipping and tax)
 */
/**
 * @typedef ProductAdded
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [ecomm_pagetype] - For this event it should be "cart", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, should be array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, same as price, MP-1280
 * @property {string} [image_url] - Image URL of the product image
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {any[] | null} [items_in_cart] - List of SKUs of the items that are present in the cart, after the action has taken place, e.g. after a Product has been removed. Send null if no items are in the cart.
 * @property {boolean} [logged_in] - true if logged in, false if not MP-1226
 * @property {string} [lot_viking_id] - Viking lot guid
 * @property {string} [name] - name of the product
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [seller] - The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
 * @property {boolean | null} [ships_internationally] - Boolean which is true if international shipping is available for the item
 * @property {string} [sku] - Sku of the product
 * @property {string} [tags] - Tags, should be optional string, MP-1327
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 * @property {string} [userToken] - Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef ProductInteraction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {number} [low_estimate] - Low estimate of the product being interacted with
 * @property {number} [position] - The position of the thing being interacted with, e.g. position of the alternative image being clicked on
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [product_interaction_type] - Type of product interaction
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 * @property {string} [type] - Type of item being viewed, "Lot", "Buy Now", "Private Sale" or "Wine"
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef ProductsItem11
 * @property {string | null} [brand] - should be creator for now
 * @property {string | null} [category] -
 * @property {string | null} [clean_name] - stripping out |,;
 * @property {string | null} [currency] -
 * @property {string | null} [image_url] -
 * @property {string | null} [name] -
 * @property {number | null} [number_of_variants] - The number of variants of the product, if it is a product with variants. Skip (or use null) if the product has no alternative variants.
 * @property {number | null} [position] - position of the item in the list
 * @property {number | null} [price] - list price
 * @property {string} product_id - viking id
 * @property {string | null} [product_list_position] - <pagination_total_pages><total_number_of_items><pagination_current_page><items_per_page><position> and delimiter ":"
 * @property {number | null} [quantity] -
 * @property {string | null} [sku] -
 * @property {string | null} [url] -
 */
/**
 * @typedef ProductListFiltered
 * @property {string} [auth0_id] - User's Auth0 value

 * @property {string} [collection] - Name of the collection, e.g. “Furniture” or “New Arrivals”
 * @property {string} [content_type] - Section of the site
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [filter_applied] - Filter that was applied that triggered the event

 * @property {string} [filter_selection] - The filter selection
 * @property {string} [filter_widget_type] - Denotes the UI filter widget type and is useful, for example, when AB testing

 * @property {any[]} [filters] - Product filters that the customer is using

 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {number} [items_per_page] - 
 * @property {string} [language] - Browsing language that user has selected via site header

 * @property {string} [list_id] - Unique ID of the collection page being viewed 
 * @property {number} [num_search_filters] - Number of search filters applied

 * @property {number} [num_search_results] - The number of search results returned

 * @property {any[]} [objectIDs] - A list of the Algolia specific objectIDs of the relevant records. These can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {number} [pagination_current_page] - 
 * @property {number} [pagination_total_pages] - 
 * @property {ProductsItem11[]} [products] - List of products displayed on the page
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [search_keyword] - T'he search term that was initially entered

 * @property {number} [total_number_of_items] - 
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [userToken] - Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia 
 */
/**
 * @typedef ProductsItem12
 * @property {string | null} [brand] - should be creator for now
 * @property {string | null} [category] -
 * @property {string | null} [clean_name] - stripping out |,;
 * @property {string | null} [currency] -
 * @property {string | null} [image_url] -
 * @property {string | null} [name] -
 * @property {number | null} [number_of_variants] - The number of variants of the product, if it is a product with variants. Skip (or use null) if the product has no alternative variants.
 * @property {number | null} [position] - position of the item in the list
 * @property {number | null} [price] - list price
 * @property {string} product_id - viking id
 * @property {string | null} [product_list_position] -  <pagination_total_pages><total_number_of_items><pagination_current_page><items_per_page><position> and delimiter ":"
 * @property {number | null} [quantity] -
 * @property {string | null} [sku] -
 * @property {string | null} [url] -
 */
/**
 * @typedef ProductListViewed
 * @property {string} [auction_state] - The open/close state of the relevant auction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [category] - Primary category of page
 * @property {string} [category_2] - Secondary category of page
 * @property {string} [collection] - Name of the collection, e.g. “Furniture” or “New Arrivals”
 * @property {string} [content_type] - Section of the site
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {number} [items_per_page] -
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [list_id] - Unique ID of the collection page being viewed
 * @property {boolean} [logged_in] - true if logged in, false if not MP-1226
 * @property {any[]} [objectIDs] - A list of the Algolia specific objectIDs of the relevant records. These can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {number} [pagination_current_page] - Set the number of the page the user is currently viewing
 * @property {number} [pagination_total_pages] -
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_category_list] - All categories of products viewed in one string, separated by a |
 * @property {string} [product_type] - Type of product being interacted with or viewed. The type refers to the object type as in taxonomy definition and object DB. If many, then it's a concatenated string delimited by '|'.
 * @property {ProductsItem12[]} [products] - List of products dispalyed on the page, Jira MP-1743
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [sale_type] - Type of sale
 * @property {string} [search_keyword] - Search Key word, eg: "Rolex"
 * @property {boolean} [sticky_navigation] - Passes as 'true' if action occurred in sticky navigation, 'false' if not
 * @property {number} [total_number_of_items] -
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [userToken] - Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 */
/**
 * @typedef ProductRemoved
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {boolean} [auto_removed] - FALSE if the user removes the product from the bag. TRUE if the item is removed automatically because of server logic.
 * @property {string} [content_type] - Section of the site
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [image_url] - URL of the image used
 * @property {any[] | null} [items_in_cart] - List of SKUs of the items that are present in the cart, after the action has taken place, e.g. after a Product has been removed. Send null if no items are in the cart.
 * @property {string} [name] - name of the product
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits). For retail items use its uuid.
 * @property {boolean} [product_unavailable] - TRUE if the product has become unavailable at the time of removal
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [seller] - The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
 * @property {boolean | null} [ships_internationally] - Boolean which is true if international shipping is available for the item
 * @property {string} [sku] - Sku of the product
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - The url of the relevant item or content
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef ProductViewed
 * @property {string} [artist] - Artist associated with the product
 * @property {boolean} [augmented_reality_enabled] - Passes only on the mobile app; set to TRUE if lot has augmented reality available and FALSE if not 
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [brand] - Brand associated with the product  
 * @property {string} [category] - Top level product category being viewed, e.g. “furniture” in the hierarchy “furniture > seating > lounge chairs” 
 * @property {string} [category_2] - Second level product category being viewed, e.g. “seating” in the hierarchy “furniture > seating > lounge chairs”
 * @property {string} [content_type] - Section of the site
 * @property {string} [creator] - Creator associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [customer_id] - customer_id
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {string} [designer] - Designer associated with the product
 * @property {boolean} [digital_catalogue_content] - Whether lot includes digital catalogue content (from Brightspot
 * @property {string} [ecomm_pagetype] - for this event it should be "product", MP-1280
 * @property {any[]} [ecomm_prodid] - Sku/Sap id, array of strings, MP-1280
 * @property {number} [ecomm_totalvalue] - Total monetary value, same as price, MP-1280
 * @property {boolean} [has_variants] - Set true for marketplace products that have multiple variants available. False otherwise. Does not apply to lots as variants are not supported.
 * @property {string} [image_url] - Image URL of the product image
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [index] - Name of the Algolia index. In most cases "prod_product_items" and "dev_product_items" respectively
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [logged_in] - true if logged in, false if not MP-1226
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {string} [lot_state] - The open/close state of the relevant lot
 * @property {string} [lot_viking_id] - Viking lot guid
 * @property {string} [name] - name of the product
 * @property {string} [objectID] - Algolia specific objectID of the record that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [period_general] - The general period of the object, such as antique,...
 * @property {number} [position] - The position (integer) of the thing being interacted with
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {number} [price] - Price of the product
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {any[]} [product_tags] - Product tags associated with a product
 * @property {string} [product_type] - Type of product, e.g. “lounge chairs” in the hierarchy “furniture > seating > lounge chairs” (maps to the Type field in object taxonomy model)
 * @property {string} [queryID] - Algolia specific queryID that can be found in the search response (Aloglia requires the camel case of the property name)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {string} [sale_type] - Type of sale
 * @property {string} [seller] - The name of the company selling the retail item, e.g. Bucherer. Use 'Sothebys' for owned inventory
 * @property {boolean | null} [ships_internationally] - Boolean which is true if international shipping is available for the item
 * @property {string} [sku] - Sku of the product
 * @property {string} [tags] - Tags, should be optional string, MP-1327
 * @property {string} [type] - Type of item being viewed, "Lot" or "Buy Now"
 * @property {string} [url] - URL of the product page
 * @property {string} [userToken] - Used for Algolia Analytics via Segment (needs to be camelCase). This is the auth0_id but with the 'auth0|'prefix stripped from it, as | is an reserved char in Algolia 
 * @property {string} [user_service_id] - The user id created in viking and served via the user service (also known as viking_user_id)
 * @property {string} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef PromotionClicked
 * @property {string} [cta_text] - Call to action text
 */
/**
 * @typedef QuantityUpdated
 * @property {boolean} [logged_in] - Log in status
 * @property {number} [quantity] -
 * @property {string} [sku] - product sku
 */
/**
 * @typedef QuickBidButton
 * @property {string} [auction_id] - Viking auction GUID
 * @property {string} [auction_name] - Auction name
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} bid_amount - Maximum bid amount
 * @property {string} bid_currency - Currency of bid placed
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [lot_name] - Lot name
 * @property {string} lot_viking_id - Viking lot GUID
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef QuickBidCancelButton
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef QuickViewClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [cta_text] - Call to action text
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef QuickViewInteraction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} quickview_interaction_type - Interaction within the quick view card
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef QuickViewOpened
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} view_selection - Defined by [page name]: [view selection], e.g. if the user is on the Auction Calendar and selects grid view then view_selection should pass “Auction Calendar: Grid”, or if they instead selected the List view then pass “Auction Calendar: List”
 */
/**
 * @typedef RecommendationClicked
 * @property {string | null} [category] - Top level product category being viewed
 * @property {string | null} [clicked_currency] - The currency of the clicked product
 * @property {number | null} [clicked_estimate_high] - The high estimate of the clicked product
 * @property {number | null} [clicked_estimate_low] - The low estimate of the clicked product
 * @property {number | null} [clicked_price] - The list price of the clicked product
 * @property {string} clicked_product_id - The id of the clicked product. For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011. For Marketplace: the SAP-id (aka. sku or external_id)
 * @property {string} clicked_product_uuid - The uuid of the clicked product, created in the system that created the sale or product, i.e. lot_id or retail_item_id in Viking, or uuid in metaverse etc.
 * @property {string} clicked_sale_type - The type of sale of the clicked item
 * @property {string | null} [clicked_sale_uuid] - The uuid of the sale of the clicked product, a.k.a. viking_auction_id, if applicable
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number | null} [estimate_high] - High estimate of a given lot
 * @property {number | null} [estimate_low] - High estimate of a given lot
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {number | null} [price] - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} recommendation_num - The number (integer) of recommendations included
 * @property {string} recommendation_source - Source of the recommendation or suggestion
 * @property {string | null} [recommendation_trait_content] - Content of the trait
 * @property {string | null} [recommendation_trait_name] - Name of trait used to generate the recommendation
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string} sale_type - Type of sale
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef RecommendationLoaded
 * @property {string | null} [category] - Top level product category being viewed
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number | null} [estimate_high] - High estimate of a given lot
 * @property {number | null} [estimate_low] - High estimate of a given lot
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {number | null} [price] - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} recommendation_num - The number (integer) of recommendations included
 * @property {string} recommendation_source - Source of the recommendation or suggestion
 * @property {string | null} [recommendation_trait_content] - Content of the trait
 * @property {string | null} [recommendation_trait_name] - Name of trait used to generate the recommendation
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string} sale_type - Type of sale
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef RecommendationSeen
 * @property {string | null} [category] - Top level product category being viewed
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number | null} [estimate_high] - High estimate of a given lot
 * @property {number | null} [estimate_low] - High estimate of a given lot
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {number | null} [price] - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} recommendation_num - The number (integer) of recommendations included
 * @property {string} recommendation_source - Source of the recommendation or suggestion
 * @property {string | null} [recommendation_trait_content] - Content of the trait
 * @property {string | null} [recommendation_trait_name] - Name of trait used to generate the recommendation
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string} sale_type - Type of sale
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef RegistrationPendingMessageViewed
 * @property {string} bid_method - For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
 * @property {string | null} [currency] - Currency code associated with the relevant product
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {number | null} [intended_bid_amount] - The amount of the bid that the user intended to place prior to encountering the restricted bidding message
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {string} message_type - Type of message the user views
 * @property {string | null} [product_id] - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} sale_id - Six-digit SAP sale number
 */
/**
 * @typedef SaleTracked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef SaleroomNoticeView
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {string} [details] - Condition Report
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef SearchAnswerCardLoaded
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [search_keyword] - T'he search term that was initially entered
 * @property {string} [search_result_category] - The type of result, e.g. ""Article"", ""Lot"", etc
 * @property {string} search_result_title - Title of the result that was clicked on
 */
/**
 * @typedef SearchInitiated
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef SearchInputActivated
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef SearchResultClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [content_type] - Content Type like Buy Now or Auction or Lot
 * @property {string} [cta_text] - Call to action text
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [result_type] -
 * @property {string} [search_filters] - The array of filters that were applied in the search that resulted to the click
 * @property {string} [search_keyword] - T'he search term that was initially entered
 * @property {string} [search_result_category] - The type of result, e.g. ""Article"", ""Lot"", etc
 * @property {any[]} [search_result_department] - Departments of the search_result
 * @property {string} [search_result_origin] - Origin of the search result click, specifically "Typeahead Top Suggestions" if user clicks on result within Top Suggestions column, "Search Results Page" if user clicks result on the actual search results page
 * @property {string} [search_result_position] - On mobile app, where in the search result list was the clicked result. Used to see how relevant the result list was
 * @property {string} search_result_title - Title of the result that was clicked on
 * @property {string} [search_view_type] - Logs whether the user used ‘card view’ or ‘list view’ to view the search result before clicking
 */
/**
 * @typedef SearchSubmitted
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [query_suggestion_category] - “Top Searches” if user has submitted a search term that matches one of the top searches presented in the dropdown; passes “Suggestions” if search term matches one of the suggestions presented in the dropdown; the query_suggestion_category property should ONLY pass if a user selects a query suggestion within the dropdown to execute their search
 * @property {string} search_keyword - What was typed into the search field when the event was sent
 */
/**
 * @typedef SearchSuggestionClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [search_keyword] - T'he search term that was initially entered
 * @property {string} [search_suggested_keyword] - The suggested keyword that the user clicked on, e.g. "pablo picasso, vallauris, 1953"
 */
/**
 * @typedef SellerPortalAction
 * @property {Record<string, any>} [action_type] -
 * @property {Record<string, any>} [consignment_category] -
 * @property {Record<string, any>} [consignment_item_id] -
 * @property {Record<string, any>} [consignment_location] -
 * @property {Record<string, any>} [consignment_status] -
 * @property {Record<string, any>} [consignment_sub_category] -
 * @property {Record<string, any>} [path] -
 * @property {Record<string, any>} [tab_name] -
 * @property {Record<string, any>} [tab_position] -
 * @property {Record<string, any>} [task_type] -
 */
/**
 * @typedef ShipmentViewed
 * @property {number} [days_until_delivery] - Number of days until estimated delivery of item; i.e. current date minus estimated date of delivery. Should be positive, e.g. "2" if shipping estimated to occur in the future, and negative, e.g. "-2" if shipping was estimated to occur in the past and is overdue. For example if shipping is expected tomorrow, then pass "1", if today, then pass "0", if it was expected yesterday, then pass "-1".
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
 * @property {string} [product_id_list.$] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A.
 * @property {number} [quote_number] - shipping quote number
 * @property {string} [shipment_status] - status of shipment
 * @property {string} [shipping_method] - method of shipment
 * @property {string} [tracking_id] - shipping tracking ID
 */
/**
 * @typedef ShippingPreferenceConfirmed
 * @property {string} [auction_type] - Is this a live or timed auction
 * @property {any[]} [departments] - The department(s) selling the item(s)
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {string} [sale_location] - The location of the sale
 * @property {string} [shipping_preference_origin] - Where did the user enter the shipping preference from
 * @property {string} shipping_preference_type - Which type of shipping preference was selected
 */
/**
 * @typedef ShippingSelected
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [invoice_number] - Invoice number
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {any[]} [product_id_list] - List of product_id values, defined by "sale (6 digits) + lot_number (4 digits)" (no hyphen in between, just straight concatenation with 0's), e.g. "N088500020" for sale N08850 lot 20 or "N099090017A" for sale N09909 lot 17A. 
 * @property {string} [sale_id] - Six-digit SAP sale number
 * @property {string} [selected_rate] - Shipping Selected
 * @property {number} [total_invoice_amount] - Total amount of the invoice
 */
/**
 * @typedef SignedOut
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef SimpleRegistrationStart
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 */
/**
 * @typedef SimpleRegistrationSubmitAttempt
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 */
/**
 * @typedef SimpleRegistrationSubmitError
 * @property {string} [error_description] - Error description
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 */
/**
 * @typedef SlideshowAdvanced
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {number} slideshow_slidenumber - Slide number
 * @property {string} [slideshow_title] - Title of slideshow
 */
/**
 * @typedef SlideshowCarouselOpened
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef SlideshowZoomed
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef SortApplied
 * @property {string} [auth0_id] - User's Auth0 value

 * @property {string} [collection] - Name of the collection, e.g. “Furniture” or “New Arrivals”
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [language] - Browsing language that user has selected via site header

 * @property {string} [list_id] - Unique ID of the collection page being viewed
 * @property {string} [search_keyword] - T'he search term that was initially entered

 * @property {string} [sort_type] - Name of sort applied
 */
/**
 * @typedef TabClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {string} [tab_name] - Name of the given tab that was clicked, e.g. ‘Overview’, ‘Cecil Beaton Library’, etc.

 * @property {number} [tab_position] - Position of tab
 */
/**
 * @typedef TechnicalRegistrationError
 * @property {string} [error_description] - Error description
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef TelephoneRegistrationComplete
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [bid_method] - Live sale bidding method selected during registration
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} registration_source - Passes 'web' during live sale registration
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef TileGridModuleClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} tile_grid_category - Name of the consignment category the user clicked; e.g. if the user clicked “Automobiles” then pass that value for tile_grid_category
 * @property {string} [tile_grid_locale] - Locale of the estimate array, e.g. "English", "Chinese", or "French"
 * @property {number} [tile_grid_position] - Position number of the tile clicked by a user; for example, if the user clicks on the 3rd tile, pass '3'
 */
/**
 * @typedef TimelineModuleClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [hotspot_component_title] - title of the entire hotspot component
 * @property {string} [hotspot_content_title] - title of specific hotspot that user has opened, ie. when they click on red circle icon overlaying main image
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [timeline_content_title] - Title of timeline content
 * @property {any | null} [timeline_title] - Title of timeline
 */
/**
 * @typedef TransactionalBillingAddressView
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef TransactionalBillingInformationViewed
 * @property {boolean} [in_app] -
 * @property {boolean} [used_suggested_address] -
 */
/**
 * @typedef TransactionalComplete
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [email] - email address
 * @property {string} [first_name] - user's first name
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} last_name - user's last name
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [title] - user's salutaion
 * @property {string} [transactional_registration_origin] - Origin of transactional registration
 */
/**
 * @typedef TransactionalCreditCardDetailsInteraction
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef TransactionalCreditCardDetailsView
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef TransactionalProfileInformationView
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [transactional_registration_origin] -
 */
/**
 * @typedef TransactionalRegistrationCancelled
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [page] -
 */
/**
 * @typedef TransactionalRegistrationCompleted
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {number | null} [customer_id] - Client system party ID
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string} [transactional_registration_origin] - Origin of transactional registration
 * @property {string} verification_result - The outcome of the verification process during transactional signups. Accounts are created event though the verification fails
 */
/**
 * @typedef TransactionalRegistrationEdit
 * @property {string | null} [edit_type] -
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef TransactionalRegistrationError
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} error_msg - Error message
 * @property {string} error_type - Error type
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef TransactionalRegistrationFailure
 * @property {boolean} [in_app] - TRUE if the client is a web view within the app
 * @property {string} [language] - Browsing language that user has selected via site header
 */
/**
 * @typedef TransactionalRegistrationReview
 * @property {boolean} [in_app] -
 */
/**
 * @typedef TransactionalRegistrationStarted
 * @property {string} auth0_id - User's Auth0 value
 * @property {string | null} [cta_text] - Call to action text, the text on the button that was clicked
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string} [transactional_registration_origin] - Origin of transactional registration
 */
/**
 * @typedef TransactionalRegistrationStepCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {number} step - Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
 * @property {string} step_name - Name of the step that was completed
 * @property {string} [transactional_registration_origin] - Origin of transactional registration
 * @property {boolean | null} [used_suggested_address] -
 */
/**
 * @typedef TransactionalRegistrationSubmitted
 * @property {boolean} [in_app] -
 * @property {string} [transactional_registration_origin] -
 */
/**
 * @typedef TransactionalValidationError
 * @property {string} [error_description] - Error description
 * @property {string} error_type - Error type
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 */
/**
 * @typedef UnableToVerify
 * @property {boolean} address_check_fail - Whether address check failed
 * @property {boolean} credit_card_fail - Whether credit card failed
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [email] - email address
 * @property {string} [first_name] - user's first name
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [last_name] - user's last name
 * @property {boolean} [lexis_nexis_fail] - Whether Lexis Nexis failed
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [title] - user's title or salutation
 * @property {string} [transactional_registration_origin] - Origin of transactional registration
 */
/**
 * @typedef UploadIdAction
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [upload_id_action_type] - The type of the action that the user or service performed while uploading an ID
 */
/**
 * @typedef UploadIdClicked
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {string | null} [context] - context of the Upload ID button clicked
 * @property {number | null} [customer_id] - Client system party ID
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {boolean | null} [preferred_member_flag] - If user is Preferred
 */
/**
 * @typedef UploadIdError
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string | null} [upload_id_error_description] - Description of error that occurred while uploading an ID
 */
/**
 * @typedef UploadIdFailed
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string | null} [upload_id_failure_reason] - The reason for the failure of uploading an ID
 */
/**
 * @typedef UploadIdSuccess
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef UserSessionError
 * @property {string} [Segment: Page Name] - Used in Avo to generate Segment page name
 * @property {string} application_name - The name of the application that the user is signing in/up from
 * @property {string} [artist_maker_author] - Creator of work
 * @property {number} artist_resale_rights - Artist resale rights value
 * @property {string} [auction_id] - Viking auction guid

 * @property {string} [auction_name] - Auction name

 * @property {boolean} audio_streaming_toggle_to - Whether the user is turning the audio on or off
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} bid_amount - Bid hammer amount
 * @property {string} bid_currency - Currency in three letter acronym format, e.g. "USD"
 * @property {number} bid_current_amount - Current bid increment value (equal to or less than the user's maximum bid value)
 * @property {number} bid_max_amount - Maximum bid amount selected by the user
 * @property {string} bid_method - For Zaius, "live_online" when user registers for online bidding, "telephone_bid" with telephone registration, or "sale_room_bid" with paddle registration 
 * @property {string} [bid_result] - Result of the bid placed
 * @property {string} [bid_type] - Type of bid placed 
 * @property {string} bidding_interaction_type - Type of interaction within the bidding/auction room 
 * @property {string} [bounce_reason] - Bounce reason
 * @property {string} [bounce_type] - Bounce type
 * @property {number} buyers_premium - Buyer's premium value
 * @property {any | null} [campaign_id] - Campaign ID
 * @property {any | null} [campaign_name] - Campaign name
 * @property {string} [card_details] - Details within content card

 * @property {string} [card_title] - Title of content card

 * @property {string} [carousel_style] - Style of carousel

 * @property {string} [carousel_title] - Title of carousel
 * @property {string} [chapter_title] - Chapter title
 * @property {string} [cmp_trackingcode] - Tracking code from offsite marketing campaign
 * @property {string} [contact_us_medium] - Either "email" or "phone" depending upon which "Contact Us" medium a user chooses 
 * @property {string} [content_type] - Section of the site
 * @property {string} cta_link_url - Call to action link URL
 * @property {string} [currency] - Currency in three letter acronym format, e.g. "USD"

 * @property {number} [customer_id] - customer_id
 * @property {string} [days_to_close] - Number of days until close of auction 
 * @property {string} [department_category] - Department(s) to which the event is related
 * @property {any | null} [email_id] - Email ID
 * @property {string} [email_vendor] - Email vendor through which email was delivered 
 * @property {string} [end_date] - Scheduled end date of timed auction (does not pass for live auctions); it's possible for an auction to close later than ...

 * @property {string} error_description - The description of the error that is received, e.g. "bad postal code"
 * @property {string} error_type - The name of the form that returns the error, for example "credit card details" , "request_timeout", "okta error", "auth0 error"
 * @property {string} [estimate_currency] - Currency in three letter acronym format, e.g. "USD"
 * @property {number} [estimate_high] - High estimate of a given lot
 * @property {number} [estimate_low] - High estimate of a given lot
 * @property {number} estimated_total - Estimated total value
 * @property {string} follow_origin - Origin of follow
 * @property {number} hammer_price - Hammer price value
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {number} [intended_bid_amount] - The amount of the bid that the user intended to place prior to encountering the restricted bidding message, i.e. within the advance bidding flow or live auction room
 * @property {string} [intended_bid_currency] - Currency in three letter acronym format, e.g. "USD"
 * @property {string} invoice_number - Unique invoice number, e.g “123-456-78"
 * @property {string} item_type - Type of item that user has followed
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {string} [language] - Browsing language that user has selected via site header
 * @property {string} [link_url] - Link URL
 * @property {string} [live_bidding_date] - Date the live auction starts (does not pass for timed auctions)

 * @property {string} [location] - Auction location

 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {string} [lot_name] - The name of the lot, if available
 * @property {string} lot_viking_id - Viking lot guid
 * @property {string} [message_type] - Type of message the user views
 * @property {string} [navigation_level] - Navigation level
 * @property {string} num_lots_sale - Number of lots in the sale
 * @property {string} num_lots_selected - Number of lots selected
 * @property {number} num_search_filters - Number of search filters applied
 * @property {string} [open_date] - First date that users are able to start submitting timed bids (for timed sales) and advanced bids (for live)

 * @property {string} [pagename] - The name of the page (or screen in app) from where the action was made
 * @property {string} participant_type - Type of auction participant
 * @property {number} payment_amount - Amount that user attempts to pay or successfully pays, excluding shipping 
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {boolean} [preferred_preference_center] - Whether the Preference Center was related to Preferred
 * @property {string} [previous_cta] - Previous call to action
 * @property {string} [previous_pagename] - Previous page name
 * @property {string} print_format - Print format, either "thumbnails" or "full description"
 * @property {Record<string, any>} print_origin - origin of print button click  
 * @property {string} [product_id] - Sale (6 chars) followed by 4 char Lot Number and possibly additional 1 char letter (for inserting lots between other lots)
 * @property {string} [product_interaction_type] - Type of interaction with product detail page
 * @property {string} [publish_date] - Date the auction was published; can be before auction opens

 * @property {string} [query_suggestion_category] - “Top Searches” if user has submitted a search term that matches one of the top searches presented in the dropdown; passes “Suggestions” if search term matches one of the suggestions presented in the dropdown; the query_suggestion_category property should ONLY pass if a user selects a query suggestion within the dropdown to execute their search 
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {string} [sale_type] - Type of sale
 * @property {any | null} [search_filters] - The array of filters that were applied in the search that resulted to the click
 * @property {any[]} [search_filters.$] - The array of filters that were applied in the search that resulted to the click
 * @property {string} search_keyword - What was typed into the search field when the event was sent
 * @property {string} [search_result_category] - What was the category of the result that was clicked, e.g. art fair, museum, or services (for Preferred experience), or story, lot, auction, etc. on the mobile App 
 * @property {number} [search_result_position] - Where in the search result list was the clicked result; used to see how relevant the result list was
 * @property {string} [search_result_title] - The text of the search result that was clicked (page title excluding the content after the  | )
 * @property {string} [search_view_type] - Whether the user used ‘card view’ or ‘list view’ to view the search result before clicking
 * @property {number} shipping_amount - Shipping and handling amount; passes the amount if it is present (either in the cost estimator or in payments if users select "Pay Now" for the shipping)
 * @property {string} slideshow_title - The title of the slideshow
 * @property {boolean} [sso_login] - 
 * @property {string} [transactional_registration_origin] - Origin of transactional registration
 * @property {string} validation_origin - Where in the payment flow the 3DS validation was initiated 
 * @property {string} video_title - The title of the video
 * @property {string} [view_type] - Which Search Result View Type is being switched to
 */
/**
 * @typedef VideoCompanionContentClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [cta_text] - Call to action text
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 */
/**
 * @typedef VideoPlaybackCompleted
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] -
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] -
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoPlaybackPaused
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] - The intended recipient’s email address.
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] - The opening browser’s user agent.
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoPlaybackResumed
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] - Previous page name
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] - CMP tracking code string
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoPlaybackSeekCompleted
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] - Position number of the tile clicked by a user; for example, if the user clicks on the 3rd tile, pass '3'
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] - Locale of the estimate array, e.g. "English", "Chinese", or "French"
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} [seek_length] - Length of video seek
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoPlaybackStarted
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] - The current index position in seconds of the playhead. If the playback is a livestream, you must send a negative integer that represents the offset in seconds in relation to the current timestamp. For example, if content is being livestreamed at 8PM but the viewer is 30 seconds behind, the value of this property should be -30
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoPlaylistSelected
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [video_player] - Video player
 */
/**
 * @typedef VideoSelected
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {any[]} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} playlist_video_position - Position of video within the playlist
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef Keywords
 * @property {any[]} [$] - An array of arbitrary keywords or tags that describe or categorize the video content.
 */
/**
 * @typedef VideoShared
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {string} external_link - URL of external (non sothebys.com) link
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {Keywords} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] -
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] -
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoStarted
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [topic] - Video topic tag
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoStreamInteraction
 * @property {string} [video_interaction_type] - What was the interaction that was made: on, off, fullscreen, mute
 */
/**
 * @typedef VideoWatched25
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] - Map Interaction
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] - title of the form, e.g. "Lost Card Request Form"
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoWatched50
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] - number of days until close of auction
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] - name of the page
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef VideoWatched75
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {string} [closed_captions] - Set to language selected in the Closed Caption dropdown, e.g. 'English', 'Chinese', or 'None'
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {boolean} [full_episode] - true if content is a full episode and false otherwise, ie. video is flagged as a clip or redacted
 * @property {boolean} full_screen - true if playback is currently in full screen mode and false otherwise
 * @property {any | null} [keyword.$] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {any | null} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content.
 * @property {boolean} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string} [playlist_display_name] - Playlist display name, ie. 'related' or 'trending'
 * @property {string} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number} [position] -
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [program] -
 * @property {string} [quality] - The quality of the video, ie. ‘highres’, ‘hd1080’, ‘480p’
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - Video player
 * @property {string} [video_title] - Video title
 */
/**
 * @typedef ViewConditionReport
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {string} [details] - Condition Report
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef ViewPrintCatalogueClicked
 * @property {string} cta_link_url - Call to action link URL
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef ViewTypeSelected
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0) 
 * @property {string} [page_name] - What page was the event fired from, e.g. "Auction Page", "Lot Page", "Marketplace"
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false" 
 * @property {string} view_selection - New format: which view is selected, e.g. "Grid" or "List".              
 Old format: Defined by [page name]: [view selection], e.g. if the user is on the Auction Calendar and selects grid view then view_selection should pass “Auction Calendar: Grid”, or if they instead selected the List view then pass “Auction Calendar: List”
 */
/**
 * @typedef WalletConnectClicked
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {string} [sale_id] - Six-digit SAP sale ID

 * @property {string} wallet_option_clicked - The type of option that user clicks in the wallet connect, either name of wallet or label of link
 */
/**
 * @typedef WalletConnectOpened
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {number} [customer_id] - customer_id
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {string} [origin] - From where was the wallet connect opened
 * @property {string} [sale_id] - Six-digit SAP sale ID

 */
/**
 * @typedef ZoomView
 * @property {string} [auth0_id] - User's Auth0 value
 * @property {any | null} [customer_id] - Client system party ID, which should pass only for transactional users (ie. never pass if party ID is set to 0)
 * @property {string} [department_category] - Selling department
 * @property {string} [lot_id] - SAP 6-digit sale Number concatenated with lot number
 * @property {boolean} [preferred_member_flag] - If user is Preferred, then "true" otherwise "false"
 * @property {string} [product_id] - Six-digit SAP sale number plus lot number (4 digits)
 * @property {string} [sale_id] - Six-digit SAP sale number
 */

/**
 * User finishes registering for an auction via Absentee
 *
 * @param {AbsenteeRegistrationComplete} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function absenteeRegistrationComplete(
	props: AbsenteeRegistrationComplete,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Absentee Registration Complete',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes account profile after users are prompted to update their profile.
Only being used the China client.
 *
 * @param {AccountProfileCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountProfileCompleted(
	props: AccountProfileCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Profile Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Client that is missing information, fills that into a form and clicks submit. 
The flow is User is prompted to update their profile > user provides the missing information and clicks SUBMIT, thats when this event fires
 *
 * @param {AccountProfileUpdateSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountProfileUpdateSubmitted(
	props: AccountProfileUpdateSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Profile Update Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the "Add to Bag" button. Also sent if user is not signed in and is redirected to the login flow
 *
 * @param {AddToBagClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addToBagClicked(
	props?: AddToBagClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add to Bag Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented with 'additional information' needed screen
 *
 * @param {AdditionalInformationNeeded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function additionalInformationNeeded(
	props: AdditionalInformationNeeded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Additional Information Needed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with auction landing details
 *
 * @param {AuctionDetailsInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionDetailsInteraction(
	props: AuctionDetailsInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Details Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views Auction Details
 *
 * @param {AuctionDetailsViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionDetailsViewed(
	props?: AuctionDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User applies auction filter

 *
 * @param {AuctionFilterApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionFilterApplied(
	props: AuctionFilterApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Filter Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User applies auction filter

 *
 * @param {AuctionFilterCleared} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionFilterCleared(
	props: AuctionFilterCleared,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Filter Cleared',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with specific informational elements of an auction landing page
 *
 * @param {AuctionLandingCarouselClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionLandingCarouselClick(
	props?: AuctionLandingCarouselClick,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Landing Carousel Click',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views lot detail page
 *
 * @param {AuctionPageView} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionPageView(
	props: AuctionPageView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Page View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects account prior to auction registration
 *
 * @param {AuctionRegistrationAccountSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationAccountSelected(
	props: AuctionRegistrationAccountSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Account Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects their preferred bidding method
 *
 * @param {AuctionRegistrationBiddingMethodSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationBiddingMethodSelected(
	props: AuctionRegistrationBiddingMethodSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Bidding Method Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks Register to Bid button
 *
 * @param {AuctionRegistrationButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationButtonClicked(
	props: AuctionRegistrationButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes auction registration
 *
 * @param {AuctionRegistrationCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationCompleted(
	props: AuctionRegistrationCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects lots during auction registration (i.e. for telephone bidding)
 *
 * @param {AuctionRegistrationLotsSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationLotsSelected(
	props?: AuctionRegistrationLotsSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Lots Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects telephone bidding during auction registration
 *
 * @param {AuctionRegistrationPhoneBiddingClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationPhoneBiddingClicked(
	props?: AuctionRegistrationPhoneBiddingClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Phone Bidding Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Auction Registration Phone Bidding Confirmed' track call.
 *
 * @param {AuctionRegistrationPhoneBiddingConfirmed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationPhoneBiddingConfirmed(
	props?: AuctionRegistrationPhoneBiddingConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Phone Bidding Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User enters online bidding room
 *
 * @param {AuctionRoomEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRoomEntered(
	props: AuctionRoomEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Room Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with aspect of bidding room
 *
 * @param {AuctionRoomInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRoomInteraction(
	props: AuctionRoomInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auction Room Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User pauses audio playback
 *
 * @param {AudioPlaybackPaused} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function audioPlaybackPaused(
	props?: AudioPlaybackPaused,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Audio Playback Paused',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User starts audio playback
 *
 * @param {AudioPlaybackStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function audioPlaybackStarted(
	props?: AudioPlaybackStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Audio Playback Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views an AB test experiment
 *
 * @param {AudioStreamingToggle} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function audioStreamingToggle(
	props: AudioStreamingToggle,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Audio Streaming Toggle',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a user opens the Augmented Reality feature
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function augmentedRealityOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Augmented Reality Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Avo's Track event which triggers Identify
 *
 * @param {Authenticated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function authenticated(
	props: Authenticated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Authenticated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User finishes registering for an auction via BidNow
 *
 * @param {BiDnowRegistrationComplete} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function biDnowRegistrationComplete(
	props: BiDnowRegistrationComplete,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'BIDnow Registration Complete',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the bid confirmation screen
 *
 * @param {BidConfirmationInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bidConfirmationInteraction(
	props?: BidConfirmationInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bid Confirmation Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the Place Bid button. This includes the "Increase Bid" button.
 *
 * @param {BidPlaced} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bidPlaced(
	props: BidPlaced,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Bid Placed', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Fires a 'Bid Started' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bidStarted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bid Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Bid Step Completed' track call.
 *
 * @param {BidStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bidStepCompleted(
	props?: BidStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bid Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks a carousel (i.e. list or tab)
 *
 * @param {CarouselClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function carouselClicked(
	props: CarouselClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Carousel Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Carousel Viewed' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function carouselViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Carousel Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views their shopping cart
 *
 * @param {CartViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(
	props?: CartViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cart Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks chapter name within rich media navigation
 *
 * @param {ChapterNavigationClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chapterNavigationClicked(
	props: ChapterNavigationClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chapter Navigation Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views chapter within rich media experience
 *
 * @param {ChapterViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chapterViewed(
	props: ChapterViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chapter Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees an error in the cart or checkout
 *
 * @param {CheckoutError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutError(
	props?: CheckoutError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User starts checkout
 *
 * @param {CheckoutStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
	props?: CheckoutStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed a checkout step
 *
 * @param {CheckoutStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
	props?: CheckoutStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a checkout step
 *
 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepViewed(
	props?: CheckoutStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The event is sent on a click of what is considered a lower priority click. It's used to identify steps in user journeys or indicate an interaction with a certain feature but does not cover more important clicks that are used in e.g. A/B test optimizations or ad-campaign optimizations.
 *
 * @param {ClickAction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clickAction(
	props: ClickAction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Click Action',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the 'Complete your profile' link within the 'My Account' section
 *
 * @param {CompleteYourProfileClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function completeYourProfileClicked(
	props: CompleteYourProfileClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Complete Your Profile Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Conditions of Sale Viewed' track call.
 *
 * @param {ConditionsOfSaleViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function conditionsOfSaleViewed(
	props?: ConditionsOfSaleViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Conditions of Sale Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented with the Confirm your email'' screen
 *
 * @param {ConfirmEmailAddressRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function confirmEmailAddressRequested(
	props: ConfirmEmailAddressRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Confirm Email Address Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User confirms quick bid
 *
 * @param {ConfirmQuickBidButton} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function confirmQuickBidButton(
	props: ConfirmQuickBidButton,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Confirm Quick Bid Button',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User Selects Consignment Category
 *
 * @param {ConsignmentCategorySelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentCategorySelected(
	props?: ConsignmentCategorySelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Category Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User gets to Consignment Review page with mandatory Item Details fields unfilled
 *
 * @param {ConsignmentErrorItemDetails} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentErrorItemDetails(
	props: ConsignmentErrorItemDetails,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Error Item Details',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User gets to Consignment Review page with mandatory Pics & Doc fields unfilled
 *
 * @param {ConsignmentErrorPicsDocs} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentErrorPicsDocs(
	props: ConsignmentErrorPicsDocs,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Error Pics & Docs',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User goes to Consignment Step 1 of 4
 *
 * @param {ConsignmentItemDetails} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentItemDetails(
	props: ConsignmentItemDetails,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Item Details',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Item Details Entered' track call.
 *
 * @param {ConsignmentItemDetailsEntered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentItemDetailsEntered(
	props?: ConsignmentItemDetailsEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Item Details Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Resumed' track call.
 *
 * @param {ConsignmentResumed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentResumed(
	props?: ConsignmentResumed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Resumed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User goes to Consignment Step 3 of 4
 *
 * @param {ConsignmentReview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentReview(
	props: ConsignmentReview,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Review',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Sign In Selected' track call.
 *
 * @param {ConsignmentSignInSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentSignInSelected(
	props?: ConsignmentSignInSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Sign In Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Sign Up Selected' track call.
 *
 * @param {ConsignmentSignUpSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentSignUpSelected(
	props?: ConsignmentSignUpSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Sign Up Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Started' track call.
 *
 * @param {ConsignmentStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentStarted(
	props?: ConsignmentStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Step Completed' track call.
 *
 * @param {ConsignmentStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentStepCompleted(
	props?: ConsignmentStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Step Error' track call.
 *
 * @param {ConsignmentStepError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentStepError(
	props?: ConsignmentStepError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Step Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Step Viewed' track call.
 *
 * @param {ConsignmentStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentStepViewed(
	props?: ConsignmentStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User goes to Consignment Step 4 of 4
 *
 * @param {ConsignmentSubmit} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentSubmit(
	props: ConsignmentSubmit,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Submit',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Consignment Submitted' track call.
 *
 * @param {ConsignmentSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentSubmitted(
	props?: ConsignmentSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User goes to Consignment Step 2 of 4
 *
 * @param {ConsignmentUploads} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consignmentUploads(
	props: ConsignmentUploads,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Consignment Uploads',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects any kind of 'card'; carousel_style and carousel_title pass when card is part of a carousel"
 *
 * @param {ContentCardClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentCardClicked(
	props: ContentCardClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Card Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on homepage hero
 *
 * @param {ContentHeroClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentHeroClicked(
	props: ContentHeroClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Hero Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the Continue To Confirm Bid button
 *
 * @param {ContinueToConfirmBidButton} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function continueToConfirmBidButton(
	props: ContinueToConfirmBidButton,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Continue To Confirm Bid Button',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cost Estimated' track call.
 *
 * @param {CostEstimated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function costEstimated(
	props?: CostEstimated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cost Estimated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cost Estimator Error' track call.
 *
 * @param {CostEstimatorError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function costEstimatorError(
	props?: CostEstimatorError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cost Estimator Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cost Estimator Opened' track call.
 *
 * @param {CostEstimatorOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function costEstimatorOpened(
	props?: CostEstimatorOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cost Estimator Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was applied on a user’s shopping cart or order
 *
 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponApplied(
	props: CouponApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was denied within a user’s shopping cart or order
 *
 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponDenied(
	props: CouponDenied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Denied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User entered a coupon on a shopping cart or order
 *
 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponEntered(
	props: CouponEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a coupon from a cart or order
 *
 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponRemoved(
	props: CouponRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Currency Selected' track call.
 *
 * @param {CurrencySelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function currencySelected(
	props?: CurrencySelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Currency Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Download App Button Clicked' track call.
 *
 * @param {DownloadAppButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadAppButtonClicked(
	props?: DownloadAppButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Download App Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views the Download App modal
 *
 * @param {DownloadAppModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadAppModalViewed(
	props?: DownloadAppModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Download App Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User email confirmation fails
 *
 * @param {EmailConfirmationFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailConfirmationFailed(
	props: EmailConfirmationFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Email Confirmation Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User returns to site after verifying their email and the email is successfully confirmed
 *
 * @param {EmailConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailConfirmed(
	props: EmailConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Email Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User subscribes to newsletter via form on homepage
 *
 * @param {EmailSignup} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailSignup(
	props: EmailSignup,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Email Signup',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User subscribes to email
 *
 * @param {EmailSubscribed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailSubscribed(
	props?: EmailSubscribed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Email Subscribed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the "Enquire" button.
 *
 * @param {EnquireClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enquireClicked(
	props?: EnquireClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Enquire Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User the "Enquire" button with all the details
 *
 * @param {EnquireSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enquireSubmitted(
	props?: EnquireSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Enquire Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks non-sothebys.com domain link to exit the website
 *
 * @param {ExitLinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exitLinkClicked(
	props: ExitLinkClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Exit Link Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views an AB test experiment
 *
 * @param {ExperimentViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function experimentViewed(
	props: ExperimentViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Experiment Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user clicks on FAQ component
 *
 * @param {FaqClick} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function faqClick(
	props: FaqClick,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('FAQ Click', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * When user clicks result on all articles and video page, search results, or our team; e.g. pass ""[item 1]"" | ""[item2]"" | ""[item3]"" etc; filter_search_keyword passes if a search keyword is entered in the filter module
 *
 * @param {FilterResultsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filterResultsClicked(
	props: FilterResultsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Filter Results Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User submits a form
 *
 * @param {FormSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formSubmitted(
	props: FormSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Form Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User loads page with a "Grid" of images
 *
 * @param {GridView} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function gridView(
	props?: GridView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Grid View', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User clicks on call to action link within a hotspot

 *
 * @param {HotspotClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hotspotClicked(
	props: HotspotClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Hotspot Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on red icon overlaying main image to reveal more information about a hotspot


 *
 * @param {HotspotOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hotspotOpened(
	props: HotspotOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Hotspot Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Interactive Module Clicked' track call.
 *
 * @param {InteractiveModuleClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function interactiveModuleClicked(
	props?: InteractiveModuleClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Interactive Module Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks internal link
 *
 * @param {InternalLink} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function internalLink(
	props: InternalLink,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Internal Link',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Item Followed' track call.
 *
 * @param {ItemFollowed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemFollowed(
	props: ItemFollowed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Item Followed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Item Unfollowed' track call.
 *
 * @param {ItemUnfollowed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemUnfollowed(
	props: ItemUnfollowed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Item Unfollowed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Used in Marketplace (MP-1092)
 *
 * @param {LearnMore} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function learnMore(
	props?: LearnMore,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Learn More', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * When user clicks link button class
 *
 * @param {LinkButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function linkButtonClicked(
	props: LinkButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Link Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "Load More Results Clicked" to load infinite scroll feature on auction page
 *
 * @param {LoadMoreResultsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loadMoreResultsClicked(
	props: LoadMoreResultsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Load More Results Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User attempts login
 *
 * @param {LoginAttempt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginAttempt(
	props: LoginAttempt,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Login Attempt',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User encounters a login error
 *
 * @param {LoginAttemptError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginAttemptError(
	props: LoginAttemptError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Login Attempt Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a user clicks a ''Log In" button from anywhere. Also for similar cta, like "Sign in" or "Log in to bid". The login_context property is important to send if the login happens from anywhere else than the header, to give context of what context initiated the login. For example, login to view results or login to enter bidding-room
 *
 * @param {LoginClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginClicked(
	props: LoginClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Login Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User starts login
 *
 * @param {LoginStart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginStart(
	props: LoginStart,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Login Start',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects login to bid within bidding room
 *
 * @param {LoginToBid} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginToBid(
	props: LoginToBid,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Login to Bid',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A logged-in user successfully clicks 'Track Lot'
 *
 * @param {LotTracked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function lotTracked(
	props?: LotTracked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Lot Tracked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the "Make an Offer" button.
 *
 * @param {MakeAnOfferClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function makeAnOfferClicked(
	props?: MakeAnOfferClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Make an Offer Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the ''Maybe Later" button when prompted to submit an ID for account verification
 *
 * @param {MaybeLaterClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function maybeLaterClicked(
	props: MaybeLaterClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Maybe Later Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User engages Mei Moses chart on artist page
 *
 * @param {MeiMosesChartInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function meiMosesChartInteraction(
	props: MeiMosesChartInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mei Moses Chart Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User Mei Moses chart loads
 *
 * @param {MeiMosesChartLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function meiMosesChartLoaded(
	props: MeiMosesChartLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mei Moses Chart Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates profile in my-account
 *
 * @param {MyProfileUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function myProfileUpdated(
	props: MyProfileUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'My Profile Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Avo's Track event which triggers Page
 *
 * @param {Navigated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navigated(
	props: Navigated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Navigated', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User clicks header or footer
 *
 * @param {NavigationClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navigationClicked(
	props: NavigationClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Navigation Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A no bid user is created or a no bid user tries to bid
 *
 * @param {NotAllowedToBid} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notAllowedToBid(
	props: NotAllowedToBid,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Not Allowed to Bid',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "CONFIRM" within Notify Me pop up modal either on auction landing or lot detail page;
 *
 * @param {NotifyMe} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notifyMe(
	props: NotifyMe,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Notify Me', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User submitted an offer from confirmation page

 *
 * @param {OfferCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerCompleted(
	props: OfferCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Offer Completed ',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when user clicks "continue" on offer modal (after entering an acceptable price and continues to checkout) MP-1092
 *
 * @param {OfferEntered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerEntered(
	props?: OfferEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Offer Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with (e.g. cycles through) onboarding module
 *
 * @param {OnboardingModuleInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardingModuleInteraction(
	props?: OnboardingModuleInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Onboarding Module Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User cancelled the order
 *
 * @param {OrderCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCancelled(
	props: OrderCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed the order

 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
	props: OrderCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User refunded the order
 *
 * @param {OrderRefunded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderRefunded(
	props: OrderRefunded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Refunded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User finishes registering for an auction via Paddle
 *
 * @param {PaddleRegistrationComplete} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paddleRegistrationComplete(
	props: PaddleRegistrationComplete,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Paddle Registration Complete',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a page
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the 'Change Password' button in Profile and enters the password change flow
 *
 * @param {PasswordChangeRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passwordChangeRequested(
	props: PasswordChangeRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Password Change Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Payment 3DS Error' track call.
 *
 * @param {Payment3DsError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function payment3DsError(
	props?: Payment3DsError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment 3DS Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is directed to 3DS validation page
 *
 * @param {Payment3DsValidationStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function payment3DsValidationStarted(
	props?: Payment3DsValidationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment 3DS Validation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects “Add a Checking Account” or “Add a Credit Card”
 *
 * @param {PaymentAddNewMethod} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentAddNewMethod(
	props: PaymentAddNewMethod,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Add New Method',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User presses the cancel button anywhere in the payment flow
 *
 * @param {PaymentCancel} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentCancel(
	props: PaymentCancel,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Cancel',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A payment fails
 *
 * @param {PaymentError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentError(
	props: PaymentError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added payment information
 *
 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentInfoEntered(
	props: PaymentInfoEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Info Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User presses the continue button on the Payment Method screen
 *
 * @param {PaymentMethodSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentMethodSelected(
	props: PaymentMethodSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Method Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User generates an error when attempting to add a new payment method
 *
 * @param {PaymentNewMethodError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentNewMethodError(
	props: PaymentNewMethodError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment New Method Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User successfully adds new credit card or checking account
 *
 * @param {PaymentNewMethodSuccess} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentNewMethodSuccess(
	props?: PaymentNewMethodSuccess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment New Method Success ',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks pay button on the payment summary screen
 *
 * @param {PaymentProcessStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentProcessStarted(
	props?: PaymentProcessStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Process Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects a specific invoice
 *
 * @param {PaymentSelectInvoice} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentSelectInvoice(
	props: PaymentSelectInvoice,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Select Invoice',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User finishes selecting their shipping options
 *
 * @param {PaymentShipping} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentShipping(
	props: PaymentShipping,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Shipping',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User attempts to submit a payment (whether or not payment succeeds or fails)
 *
 * @param {PaymentSubmit} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentSubmit(
	props: PaymentSubmit,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Submit',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has successfully submitted a payment (i.e. no payment failure)
 *
 * @param {PaymentSuccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentSuccess(
	props: PaymentSuccess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Success',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User scrolls 100% of page
 *
 * @param {PercentPageViewed100} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function percentPageViewed100(
	props: PercentPageViewed100,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Percent Page Viewed 100',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User scrolls 25% of page
 *
 * @param {PercentPageViewed25} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function percentPageViewed25(
	props: PercentPageViewed25,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Percent Page Viewed 25',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User scrolls 50% of page
 *
 * @param {PercentPageViewed50} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function percentPageViewed50(
	props: PercentPageViewed50,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Percent Page Viewed 50',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User scrolls 75% of page
 *
 * @param {PercentPageViewed75} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function percentPageViewed75(
	props: PercentPageViewed75,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Percent Page Viewed 75',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Place Bid Button' track call.
 *
 * @param {PlaceBidButton} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function placeBidButton(
	props: PlaceBidButton,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Place Bid Button',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User confirms maximum bid
 *
 * @param {PlaceMaximumBidButton} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function placeMaximumBidButton(
	props: PlaceMaximumBidButton,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Place Maximum Bid Button',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks a card on a map or in a list
 *
 * @param {PreferredContentCardClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function preferredContentCardClicked(
	props: PreferredContentCardClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Preferred Content Card Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User makes any of the predefined interaction on the map (style change, filter, click, ...)
 *
 * @param {PreferredMapInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function preferredMapInteraction(
	props: PreferredMapInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Preferred Map Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on filter results content either in the list or map view
 *
 * @param {PreferredSearchResultClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function preferredSearchResultClicked(
	props: PreferredSearchResultClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Preferred Search Result Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User switches between map- and list-view

 *
 * @param {PreferredViewTypeSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function preferredViewTypeSelected(
	props: PreferredViewTypeSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Preferred View Type Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks print button
 *
 * @param {PrintButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function printButtonClicked(
	props: PrintButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Print Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User successfully closes the print dialog after printing or saving as pdf (not possible to track what user does in the modal so tracking closure of modal is best approximation)
 *
 * @param {PrintCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function printCompleted(
	props: PrintCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Print Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User confirms their preferred format, i.e. thumbnails or full description by pressing the 'Continue' button
 *
 * @param {PrintFormatSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function printFormatSelected(
	props: PrintFormatSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Print Format Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user completes selection of lots they want to print
 *
 * @param {PrintLotsSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function printLotsSelected(
	props: PrintLotsSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Print Lots Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks link on Private Sale product detail; URL must contain /buy-sell/private-sale
 *
 * @param {PrivateSaleProductClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function privateSaleProductClicked(
	props?: PrivateSaleProductClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Private Sale Product Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks “checkout” on the cart
 *
 * @param {ProceedToCheckout} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function proceedToCheckout(
	props?: ProceedToCheckout,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Proceed To Checkout',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to their shopping cart
 *
 * @param {ProductAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
	props?: ProductAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Product Interaction' track call.
 *
 * @param {ProductInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productInteraction(
	props?: ProductInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event when a visitor filters a list of products in New Search page
 *
 * @param {ProductListFiltered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(
	props?: ProductListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Product List Viewed' track call.
 *
 * @param {ProductListViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(
	props?: ProductListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a product from their shopping cart
 *
 * @param {ProductRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(
	props?: ProductRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Product Viewed' track call.
 *
 * @param {ProductViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(
	props?: ProductViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks an internal offer promotion
 *
 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionClicked(
	props: PromotionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promotion Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires when bag item(s) quantity is updated
 *
 * @param {QuantityUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function quantityUpdated(
	props?: QuantityUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Quantity Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks a quick bid button
 *
 * @param {QuickBidButton} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function quickBidButton(
	props: QuickBidButton,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Quick Bid Button',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User cancels quick bid
 *
 * @param {QuickBidCancelButton} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function quickBidCancelButton(
	props: QuickBidCancelButton,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Quick Bid Cancel Button',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on a primary call to action within the quick view card, ie. to bring user to auction content or registration flow
 *
 * @param {QuickViewClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function quickViewClicked(
	props: QuickViewClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Quick View Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on a primary call to action within the quick view card, ie. to bring user to auction content or registration flow
 *
 * @param {QuickViewInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function quickViewInteraction(
	props: QuickViewInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Quick View Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens a quick view card, i.e. by clicking the icon on the calendar
 *
 * @param {QuickViewOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function quickViewOpened(
	props: QuickViewOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Quick View Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a user clicks an item on a recommendation carousel
 *
 * @param {RecommendationClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recommendationClicked(
	props: RecommendationClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Recommendation Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a recommendation carousel is loaded anywhere on a page
 *
 * @param {RecommendationLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recommendationLoaded(
	props: RecommendationLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Recommendation Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a user sees a recommendation carousel. Triggered via a viewport listener. Event triggers: 1. Sent when a recommendation carousel comes into view for the user. This can be e.g. a ''You may also like" carousel or "For you" carousel. Trigger on Web -Publish to Segment. View in Avo: https://www.avo.app/schemas/sqtpEbdMfEfjjyqszKnR/events/r9uf-dxRH/trigger/RAMCbQ0K5
 *
 * @param {RecommendationSeen} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recommendationSeen(
	props: RecommendationSeen,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Recommendation Seen',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User encounters the Registration Pending or Action Needed to Bid messages when attempting to register for or bid in a restricted sale
 *
 * @param {RegistrationPendingMessageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationPendingMessageViewed(
	props: RegistrationPendingMessageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Registration Pending Message Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A logged-in user successfully clicks 'Track Sale'
 *
 * @param {SaleTracked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function saleTracked(
	props?: SaleTracked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sale Tracked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "SALEROOM NOTICE" on lot detail page
 *
 * @param {SaleroomNoticeView} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function saleroomNoticeView(
	props?: SaleroomNoticeView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Saleroom Notice View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * An answer card loads
 *
 * @param {SearchAnswerCardLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchAnswerCardLoaded(
	props: SearchAnswerCardLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Answer Card Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiates a search by starting to type (the text of the field changing from 0 characters to 1 character)
 *
 * @param {SearchInitiated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchInitiated(
	props?: SearchInitiated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Initiated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User activates the search input field, i.e. by clicking the search icon or clicking into the search field
 *
 * @param {SearchInputActivated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchInputActivated(
	props?: SearchInputActivated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Input Activated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks search result in global site search
 *
 * @param {SearchResultClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchResultClicked(
	props: SearchResultClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Result Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User enters search, driving user to search results page or if they are already on the search page their list of search results is updated
 *
 * @param {SearchSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchSubmitted(
	props?: SearchSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on one of the suggested keywords
 *
 * @param {SearchSuggestionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchSuggestionClicked(
	props: SearchSuggestionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Suggestion Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Seller Portal Action' track call.
 *
 * @param {SellerPortalAction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sellerPortalAction(
	props?: SellerPortalAction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Seller Portal Action',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens a shipment from Shipping landing page
 *
 * @param {ShipmentViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function shipmentViewed(
	props?: ShipmentViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Shipment Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User confirms a selection of shipping preference
 *
 * @param {ShippingPreferenceConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function shippingPreferenceConfirmed(
	props: ShippingPreferenceConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Shipping Preference Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects a shipping option
 *
 * @param {ShippingSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function shippingSelected(
	props?: ShippingSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Shipping Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User signs out
 *
 * @param {SignedOut} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedOut(
	props?: SignedOut,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Signed Out', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User starts simple registration
 *
 * @param {SimpleRegistrationStart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function simpleRegistrationStart(
	props: SimpleRegistrationStart,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Simple Registration Start',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User attempts simple registration
 *
 * @param {SimpleRegistrationSubmitAttempt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function simpleRegistrationSubmitAttempt(
	props: SimpleRegistrationSubmitAttempt,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Simple Registration Submit Attempt',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User attempts to submit the simple registration form but prompts an error
 *
 * @param {SimpleRegistrationSubmitError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function simpleRegistrationSubmitError(
	props: SimpleRegistrationSubmitError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Simple Registration Submit Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User changes slides
 *
 * @param {SlideshowAdvanced} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function slideshowAdvanced(
	props: SlideshowAdvanced,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Slideshow Advanced',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * when user selects the carousel option at the bottom of a slideshow
 *
 * @param {SlideshowCarouselOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function slideshowCarouselOpened(
	props: SlideshowCarouselOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Slideshow Carousel Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * when a user zooms on a slideshow image
 *
 * @param {SlideshowZoomed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function slideshowZoomed(
	props: SlideshowZoomed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Slideshow Zoomed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event when a visitor filters a list of products in New Search page
 *
 * @param {SortApplied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sortApplied(
	props?: SortApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sort Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user within Cecil Beaton experience clicks tab, e.g. ‘Cecil Beaton Library’, ‘Purchase a Print’, ‘License an Image’, or ‘Overview’ pass a track event called ‘Tab Click’
 *
 * @param {TabClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tabClicked(
	props: TabClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tab Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented with 'please try again' screen
 *
 * @param {TechnicalRegistrationError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function technicalRegistrationError(
	props: TechnicalRegistrationError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Technical Registration Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User finishes registering for an auction via Telephone
 *
 * @param {TelephoneRegistrationComplete} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function telephoneRegistrationComplete(
	props: TelephoneRegistrationComplete,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Telephone Registration Complete',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks tile module
 *
 * @param {TileGridModuleClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tileGridModuleClicked(
	props: TileGridModuleClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tile Grid Module Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on call to action link within a hotspot

 *
 * @param {TimelineModuleClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function timelineModuleClicked(
	props?: TimelineModuleClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Timeline Module Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented with billing address screen
 *
 * @param {TransactionalBillingAddressView} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalBillingAddressView(
	props: TransactionalBillingAddressView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Billing Address View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user is presented with the Billing Information screen


 *
 * @param {TransactionalBillingInformationViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalBillingInformationViewed(
	props?: TransactionalBillingInformationViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Billing Information Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user completes transactional registration
 *
 * @param {TransactionalComplete} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalComplete(
	props: TransactionalComplete,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Complete',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with credit card details screen
 *
 * @param {TransactionalCreditCardDetailsInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalCreditCardDetailsInteraction(
	props: TransactionalCreditCardDetailsInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Credit Card Details Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented with credit card details screen
 *
 * @param {TransactionalCreditCardDetailsView} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalCreditCardDetailsView(
	props: TransactionalCreditCardDetailsView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Credit Card Details View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented with profile screen
 *
 * @param {TransactionalProfileInformationView} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalProfileInformationView(
	props?: TransactionalProfileInformationView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Profile Information View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When users click the “Leave” option from the “Are you sure?” prompt
 *
 * @param {TransactionalRegistrationCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationCancelled(
	props: TransactionalRegistrationCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User finishes a transactional registration (an account upgrade). This is sent on a successful creation of an account, even if the user gets an ''Unable to bid" flag.
 *
 * @param {TransactionalRegistrationCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationCompleted(
	props: TransactionalRegistrationCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When users click either edit link from the review screen
 *
 * @param {TransactionalRegistrationEdit} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationEdit(
	props: TransactionalRegistrationEdit,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Edit',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * An error occurs during a transactional registration (an account upgrade). Sent for all types of errors but the property error_type distinguishes between the error types.
 *
 * @param {TransactionalRegistrationError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationError(
	props: TransactionalRegistrationError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Passes when user is presented with 'something went wrong' screen
 *
 * @param {TransactionalRegistrationFailure} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationFailure(
	props?: TransactionalRegistrationFailure,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Failure',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user is presented with the review screen prior to submitting registration
 *
 * @param {TransactionalRegistrationReview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationReview(
	props?: TransactionalRegistrationReview,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Review',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a user start the transactional registration flow by clicking Complete-my-Profile or other CTAs that start the flow
 *
 * @param {TransactionalRegistrationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationStarted(
	props: TransactionalRegistrationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed a registration step
 *
 * @param {TransactionalRegistrationStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationStepCompleted(
	props: TransactionalRegistrationStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user clicks submit from the review screen
 *
 * @param {TransactionalRegistrationSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalRegistrationSubmitted(
	props?: TransactionalRegistrationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Registration Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User encounters validation error during transactional registration
 *
 * @param {TransactionalValidationError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionalValidationError(
	props: TransactionalValidationError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transactional Validation Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented with 'unable to verify' screen
 *
 * @param {UnableToVerify} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unableToVerify(
	props: UnableToVerify,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Unable to Verify',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when any of the relevant actions is done by the user or service while uploading an ID
 *
 * @param {UploadIdAction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadIdAction(
	props?: UploadIdAction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload ID Action',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the ''Upload ID" button when prompted to submit an ID for account verification
 *
 * @param {UploadIdClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadIdClicked(
	props: UploadIdClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload ID Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when an error occurs while uploading an ID
 *
 * @param {UploadIdError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadIdError(
	props?: UploadIdError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload ID Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a failure occurs while uploading an ID
 *
 * @param {UploadIdFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadIdFailed(
	props?: UploadIdFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload ID Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when an ID has been successfully submitted
 *
 * @param {UploadIdSuccess} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadIdSuccess(
	props?: UploadIdSuccess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload ID Success',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User's auth0 or Okta login session times out
 *
 * @param {UserSessionError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userSessionError(
	props?: UserSessionError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'User Session Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks companion content
 *
 * @param {VideoCompanionContentClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoCompanionContentClicked(
	props: VideoCompanionContentClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Companion Content Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Video reaches 100%
 *
 * @param {VideoPlaybackCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackCompleted(
	props: VideoPlaybackCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User pauses a video
 *
 * @param {VideoPlaybackPaused} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackPaused(
	props: VideoPlaybackPaused,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Paused',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Playback is resumed, by the user, after being paused
 *
 * @param {VideoPlaybackResumed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackResumed(
	props: VideoPlaybackResumed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Resumed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User manually seeks to a certain position of the content or ad in the playback. Pass in the position property to denote where the user desires to begin the playback from
 *
 * @param {VideoPlaybackSeekCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackSeekCompleted(
	props: VideoPlaybackSeekCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Seek Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User presses play
 *
 * @param {VideoPlaybackStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackStarted(
	props: VideoPlaybackStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User toggles to video playlist
 *
 * @param {VideoPlaylistSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaylistSelected(
	props?: VideoPlaylistSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Playlist Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on video within playlist
 *
 * @param {VideoSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoSelected(
	props: VideoSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on one of the video sharing options
 *
 * @param {VideoShared} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoShared(
	props: VideoShared,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Shared',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Video play begins
 *
 * @param {VideoStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoStarted(
	props: VideoStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interact with the video stream in a live auction
 *
 * @param {VideoStreamInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoStreamInteraction(
	props?: VideoStreamInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Stream Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Video reaches 25%
 *
 * @param {VideoWatched25} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoWatched25(
	props: VideoWatched25,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Watched 25',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Video reaches 50%
 *
 * @param {VideoWatched50} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoWatched50(
	props: VideoWatched50,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Watched 50',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Video reaches 75%
 *
 * @param {VideoWatched75} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoWatched75(
	props: VideoWatched75,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Watched 75',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a lot condition report
 *
 * @param {ViewConditionReport} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewConditionReport(
	props?: ViewConditionReport,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'View Condition Report',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the 'VIEW PRINT CATALOGUE' on an AEM auction landing page
 *
 * @param {ViewPrintCatalogueClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewPrintCatalogueClicked(
	props?: ViewPrintCatalogueClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'View Print Catalogue Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects view preference on e.g. calendar page, ADP or LDP
 *
 * @param {ViewTypeSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewTypeSelected(
	props: ViewTypeSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'View Type Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks a link or button in the wallet connect window
 *
 * @param {WalletConnectClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletConnectClicked(
	props: WalletConnectClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Connect Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks a "Connect your wallet" link on ADP LDP or My Acount
 *
 * @param {WalletConnectOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletConnectOpened(
	props?: WalletConnectOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Connect Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User zooms on AEM lot image
 *
 * @param {ZoomView} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function zoomView(
	props?: ZoomView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Zoom View', props || {}, withTypewriterContext(options), callback)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * User finishes registering for an auction via Absentee
	 *
	 * @param {AbsenteeRegistrationComplete} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	absenteeRegistrationComplete,
	/**
	 * User completes account profile after users are prompted to update their profile.
	Only being used the China client.
	 *
	 * @param {AccountProfileCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountProfileCompleted,
	/**
	 * Client that is missing information, fills that into a form and clicks submit. 
	The flow is User is prompted to update their profile > user provides the missing information and clicks SUBMIT, thats when this event fires
	 *
	 * @param {AccountProfileUpdateSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountProfileUpdateSubmitted,
	/**
	 * User clicks the "Add to Bag" button. Also sent if user is not signed in and is redirected to the login flow
	 *
	 * @param {AddToBagClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addToBagClicked,
	/**
	 * User is presented with 'additional information' needed screen
	 *
	 * @param {AdditionalInformationNeeded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	additionalInformationNeeded,
	/**
	 * User interacts with auction landing details
	 *
	 * @param {AuctionDetailsInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionDetailsInteraction,
	/**
	 * User views Auction Details
	 *
	 * @param {AuctionDetailsViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionDetailsViewed,
	/**
	 * User applies auction filter
	
	 *
	 * @param {AuctionFilterApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionFilterApplied,
	/**
	 * User applies auction filter
	
	 *
	 * @param {AuctionFilterCleared} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionFilterCleared,
	/**
	 * User interacts with specific informational elements of an auction landing page
	 *
	 * @param {AuctionLandingCarouselClick} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionLandingCarouselClick,
	/**
	 * User views lot detail page
	 *
	 * @param {AuctionPageView} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionPageView,
	/**
	 * User selects account prior to auction registration
	 *
	 * @param {AuctionRegistrationAccountSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationAccountSelected,
	/**
	 * User selects their preferred bidding method
	 *
	 * @param {AuctionRegistrationBiddingMethodSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationBiddingMethodSelected,
	/**
	 * User clicks Register to Bid button
	 *
	 * @param {AuctionRegistrationButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationButtonClicked,
	/**
	 * User completes auction registration
	 *
	 * @param {AuctionRegistrationCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationCompleted,
	/**
	 * User selects lots during auction registration (i.e. for telephone bidding)
	 *
	 * @param {AuctionRegistrationLotsSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationLotsSelected,
	/**
	 * User selects telephone bidding during auction registration
	 *
	 * @param {AuctionRegistrationPhoneBiddingClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationPhoneBiddingClicked,
	/**
	 * Fires a 'Auction Registration Phone Bidding Confirmed' track call.
	 *
	 * @param {AuctionRegistrationPhoneBiddingConfirmed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationPhoneBiddingConfirmed,
	/**
	 * User enters online bidding room
	 *
	 * @param {AuctionRoomEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRoomEntered,
	/**
	 * User interacts with aspect of bidding room
	 *
	 * @param {AuctionRoomInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRoomInteraction,
	/**
	 * User pauses audio playback
	 *
	 * @param {AudioPlaybackPaused} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	audioPlaybackPaused,
	/**
	 * User starts audio playback
	 *
	 * @param {AudioPlaybackStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	audioPlaybackStarted,
	/**
	 * User views an AB test experiment
	 *
	 * @param {AudioStreamingToggle} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	audioStreamingToggle,
	/**
	 * Sent when a user opens the Augmented Reality feature
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	augmentedRealityOpened,
	/**
	 * Avo's Track event which triggers Identify
	 *
	 * @param {Authenticated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	authenticated,
	/**
	 * User finishes registering for an auction via BidNow
	 *
	 * @param {BiDnowRegistrationComplete} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	biDnowRegistrationComplete,
	/**
	 * User interacts with the bid confirmation screen
	 *
	 * @param {BidConfirmationInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bidConfirmationInteraction,
	/**
	 * User clicks the Place Bid button. This includes the "Increase Bid" button.
	 *
	 * @param {BidPlaced} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bidPlaced,
	/**
	 * Fires a 'Bid Started' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bidStarted,
	/**
	 * Fires a 'Bid Step Completed' track call.
	 *
	 * @param {BidStepCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bidStepCompleted,
	/**
	 * User clicks a carousel (i.e. list or tab)
	 *
	 * @param {CarouselClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	carouselClicked,
	/**
	 * Fires a 'Carousel Viewed' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	carouselViewed,
	/**
	 * User views their shopping cart
	 *
	 * @param {CartViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cartViewed,
	/**
	 * User clicks chapter name within rich media navigation
	 *
	 * @param {ChapterNavigationClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chapterNavigationClicked,
	/**
	 * User views chapter within rich media experience
	 *
	 * @param {ChapterViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chapterViewed,
	/**
	 * User sees an error in the cart or checkout
	 *
	 * @param {CheckoutError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutError,
	/**
	 * User starts checkout
	 *
	 * @param {CheckoutStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * User completed a checkout step
	 *
	 * @param {CheckoutStepCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * User viewed a checkout step
	 *
	 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepViewed,
	/**
	 * The event is sent on a click of what is considered a lower priority click. It's used to identify steps in user journeys or indicate an interaction with a certain feature but does not cover more important clicks that are used in e.g. A/B test optimizations or ad-campaign optimizations.
	 *
	 * @param {ClickAction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clickAction,
	/**
	 * User clicks the 'Complete your profile' link within the 'My Account' section
	 *
	 * @param {CompleteYourProfileClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	completeYourProfileClicked,
	/**
	 * Fires a 'Conditions of Sale Viewed' track call.
	 *
	 * @param {ConditionsOfSaleViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	conditionsOfSaleViewed,
	/**
	 * User is presented with the Confirm your email'' screen
	 *
	 * @param {ConfirmEmailAddressRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	confirmEmailAddressRequested,
	/**
	 * User confirms quick bid
	 *
	 * @param {ConfirmQuickBidButton} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	confirmQuickBidButton,
	/**
	 * User Selects Consignment Category
	 *
	 * @param {ConsignmentCategorySelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentCategorySelected,
	/**
	 * User gets to Consignment Review page with mandatory Item Details fields unfilled
	 *
	 * @param {ConsignmentErrorItemDetails} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentErrorItemDetails,
	/**
	 * User gets to Consignment Review page with mandatory Pics & Doc fields unfilled
	 *
	 * @param {ConsignmentErrorPicsDocs} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentErrorPicsDocs,
	/**
	 * User goes to Consignment Step 1 of 4
	 *
	 * @param {ConsignmentItemDetails} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentItemDetails,
	/**
	 * Fires a 'Consignment Item Details Entered' track call.
	 *
	 * @param {ConsignmentItemDetailsEntered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentItemDetailsEntered,
	/**
	 * Fires a 'Consignment Resumed' track call.
	 *
	 * @param {ConsignmentResumed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentResumed,
	/**
	 * User goes to Consignment Step 3 of 4
	 *
	 * @param {ConsignmentReview} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentReview,
	/**
	 * Fires a 'Consignment Sign In Selected' track call.
	 *
	 * @param {ConsignmentSignInSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentSignInSelected,
	/**
	 * Fires a 'Consignment Sign Up Selected' track call.
	 *
	 * @param {ConsignmentSignUpSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentSignUpSelected,
	/**
	 * Fires a 'Consignment Started' track call.
	 *
	 * @param {ConsignmentStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentStarted,
	/**
	 * Fires a 'Consignment Step Completed' track call.
	 *
	 * @param {ConsignmentStepCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentStepCompleted,
	/**
	 * Fires a 'Consignment Step Error' track call.
	 *
	 * @param {ConsignmentStepError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentStepError,
	/**
	 * Fires a 'Consignment Step Viewed' track call.
	 *
	 * @param {ConsignmentStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentStepViewed,
	/**
	 * User goes to Consignment Step 4 of 4
	 *
	 * @param {ConsignmentSubmit} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentSubmit,
	/**
	 * Fires a 'Consignment Submitted' track call.
	 *
	 * @param {ConsignmentSubmitted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentSubmitted,
	/**
	 * User goes to Consignment Step 2 of 4
	 *
	 * @param {ConsignmentUploads} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consignmentUploads,
	/**
	 * User selects any kind of 'card'; carousel_style and carousel_title pass when card is part of a carousel"
	 *
	 * @param {ContentCardClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentCardClicked,
	/**
	 * User clicks on homepage hero
	 *
	 * @param {ContentHeroClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentHeroClicked,
	/**
	 * User clicks the Continue To Confirm Bid button
	 *
	 * @param {ContinueToConfirmBidButton} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	continueToConfirmBidButton,
	/**
	 * Fires a 'Cost Estimated' track call.
	 *
	 * @param {CostEstimated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	costEstimated,
	/**
	 * Fires a 'Cost Estimator Error' track call.
	 *
	 * @param {CostEstimatorError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	costEstimatorError,
	/**
	 * Fires a 'Cost Estimator Opened' track call.
	 *
	 * @param {CostEstimatorOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	costEstimatorOpened,
	/**
	 * Coupon was applied on a user’s shopping cart or order
	 *
	 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponApplied,
	/**
	 * Coupon was denied within a user’s shopping cart or order
	 *
	 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponDenied,
	/**
	 * User entered a coupon on a shopping cart or order
	 *
	 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponEntered,
	/**
	 * User removed a coupon from a cart or order
	 *
	 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponRemoved,
	/**
	 * Fires a 'Currency Selected' track call.
	 *
	 * @param {CurrencySelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	currencySelected,
	/**
	 * Fires a 'Download App Button Clicked' track call.
	 *
	 * @param {DownloadAppButtonClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downloadAppButtonClicked,
	/**
	 * User views the Download App modal
	 *
	 * @param {DownloadAppModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downloadAppModalViewed,
	/**
	 * User email confirmation fails
	 *
	 * @param {EmailConfirmationFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailConfirmationFailed,
	/**
	 * User returns to site after verifying their email and the email is successfully confirmed
	 *
	 * @param {EmailConfirmed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailConfirmed,
	/**
	 * User subscribes to newsletter via form on homepage
	 *
	 * @param {EmailSignup} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailSignup,
	/**
	 * User subscribes to email
	 *
	 * @param {EmailSubscribed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailSubscribed,
	/**
	 * User clicks the "Enquire" button.
	 *
	 * @param {EnquireClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	enquireClicked,
	/**
	 * User the "Enquire" button with all the details
	 *
	 * @param {EnquireSubmitted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	enquireSubmitted,
	/**
	 * User clicks non-sothebys.com domain link to exit the website
	 *
	 * @param {ExitLinkClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exitLinkClicked,
	/**
	 * User views an AB test experiment
	 *
	 * @param {ExperimentViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	experimentViewed,
	/**
	 * When a user clicks on FAQ component
	 *
	 * @param {FaqClick} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	faqClick,
	/**
	 * When user clicks result on all articles and video page, search results, or our team; e.g. pass ""[item 1]"" | ""[item2]"" | ""[item3]"" etc; filter_search_keyword passes if a search keyword is entered in the filter module
	 *
	 * @param {FilterResultsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filterResultsClicked,
	/**
	 * User submits a form
	 *
	 * @param {FormSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formSubmitted,
	/**
	 * User loads page with a "Grid" of images
	 *
	 * @param {GridView} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	gridView,
	/**
	 * User clicks on call to action link within a hotspot
	
	 *
	 * @param {HotspotClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	hotspotClicked,
	/**
	 * User clicks on red icon overlaying main image to reveal more information about a hotspot
	
	
	 *
	 * @param {HotspotOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	hotspotOpened,
	/**
	 * Fires a 'Interactive Module Clicked' track call.
	 *
	 * @param {InteractiveModuleClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	interactiveModuleClicked,
	/**
	 * User clicks internal link
	 *
	 * @param {InternalLink} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	internalLink,
	/**
	 * Fires a 'Item Followed' track call.
	 *
	 * @param {ItemFollowed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemFollowed,
	/**
	 * Fires a 'Item Unfollowed' track call.
	 *
	 * @param {ItemUnfollowed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemUnfollowed,
	/**
	 * Used in Marketplace (MP-1092)
	 *
	 * @param {LearnMore} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	learnMore,
	/**
	 * When user clicks link button class
	 *
	 * @param {LinkButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	linkButtonClicked,
	/**
	 * User clicks "Load More Results Clicked" to load infinite scroll feature on auction page
	 *
	 * @param {LoadMoreResultsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loadMoreResultsClicked,
	/**
	 * User attempts login
	 *
	 * @param {LoginAttempt} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginAttempt,
	/**
	 * User encounters a login error
	 *
	 * @param {LoginAttemptError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginAttemptError,
	/**
	 * Sent when a user clicks a ''Log In" button from anywhere. Also for similar cta, like "Sign in" or "Log in to bid". The login_context property is important to send if the login happens from anywhere else than the header, to give context of what context initiated the login. For example, login to view results or login to enter bidding-room
	 *
	 * @param {LoginClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginClicked,
	/**
	 * User starts login
	 *
	 * @param {LoginStart} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginStart,
	/**
	 * User selects login to bid within bidding room
	 *
	 * @param {LoginToBid} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginToBid,
	/**
	 * A logged-in user successfully clicks 'Track Lot'
	 *
	 * @param {LotTracked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	lotTracked,
	/**
	 * User clicks the "Make an Offer" button.
	 *
	 * @param {MakeAnOfferClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	makeAnOfferClicked,
	/**
	 * User clicks the ''Maybe Later" button when prompted to submit an ID for account verification
	 *
	 * @param {MaybeLaterClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	maybeLaterClicked,
	/**
	 * User engages Mei Moses chart on artist page
	 *
	 * @param {MeiMosesChartInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	meiMosesChartInteraction,
	/**
	 * User Mei Moses chart loads
	 *
	 * @param {MeiMosesChartLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	meiMosesChartLoaded,
	/**
	 * User updates profile in my-account
	 *
	 * @param {MyProfileUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	myProfileUpdated,
	/**
	 * Avo's Track event which triggers Page
	 *
	 * @param {Navigated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	navigated,
	/**
	 * User clicks header or footer
	 *
	 * @param {NavigationClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	navigationClicked,
	/**
	 * A no bid user is created or a no bid user tries to bid
	 *
	 * @param {NotAllowedToBid} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	notAllowedToBid,
	/**
	 * User clicks "CONFIRM" within Notify Me pop up modal either on auction landing or lot detail page;
	 *
	 * @param {NotifyMe} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	notifyMe,
	/**
	 * User submitted an offer from confirmation page
	
	 *
	 * @param {OfferCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerCompleted,
	/**
	 * Fired when user clicks "continue" on offer modal (after entering an acceptable price and continues to checkout) MP-1092
	 *
	 * @param {OfferEntered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerEntered,
	/**
	 * User interacts with (e.g. cycles through) onboarding module
	 *
	 * @param {OnboardingModuleInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	onboardingModuleInteraction,
	/**
	 * User cancelled the order
	 *
	 * @param {OrderCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCancelled,
	/**
	 * User completed the order
	
	 *
	 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * User refunded the order
	 *
	 * @param {OrderRefunded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderRefunded,
	/**
	 * User finishes registering for an auction via Paddle
	 *
	 * @param {PaddleRegistrationComplete} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paddleRegistrationComplete,
	/**
	 * User views a page
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User clicks the 'Change Password' button in Profile and enters the password change flow
	 *
	 * @param {PasswordChangeRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	passwordChangeRequested,
	/**
	 * Fires a 'Payment 3DS Error' track call.
	 *
	 * @param {Payment3DsError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	payment3DsError,
	/**
	 * User is directed to 3DS validation page
	 *
	 * @param {Payment3DsValidationStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	payment3DsValidationStarted,
	/**
	 * User selects “Add a Checking Account” or “Add a Credit Card”
	 *
	 * @param {PaymentAddNewMethod} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentAddNewMethod,
	/**
	 * User presses the cancel button anywhere in the payment flow
	 *
	 * @param {PaymentCancel} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentCancel,
	/**
	 * A payment fails
	 *
	 * @param {PaymentError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentError,
	/**
	 * User added payment information
	 *
	 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentInfoEntered,
	/**
	 * User presses the continue button on the Payment Method screen
	 *
	 * @param {PaymentMethodSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentMethodSelected,
	/**
	 * User generates an error when attempting to add a new payment method
	 *
	 * @param {PaymentNewMethodError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentNewMethodError,
	/**
	 * User successfully adds new credit card or checking account
	 *
	 * @param {PaymentNewMethodSuccess} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentNewMethodSuccess,
	/**
	 * User clicks pay button on the payment summary screen
	 *
	 * @param {PaymentProcessStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentProcessStarted,
	/**
	 * User selects a specific invoice
	 *
	 * @param {PaymentSelectInvoice} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentSelectInvoice,
	/**
	 * User finishes selecting their shipping options
	 *
	 * @param {PaymentShipping} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentShipping,
	/**
	 * User attempts to submit a payment (whether or not payment succeeds or fails)
	 *
	 * @param {PaymentSubmit} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentSubmit,
	/**
	 * User has successfully submitted a payment (i.e. no payment failure)
	 *
	 * @param {PaymentSuccess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentSuccess,
	/**
	 * User scrolls 100% of page
	 *
	 * @param {PercentPageViewed100} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	percentPageViewed100,
	/**
	 * User scrolls 25% of page
	 *
	 * @param {PercentPageViewed25} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	percentPageViewed25,
	/**
	 * User scrolls 50% of page
	 *
	 * @param {PercentPageViewed50} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	percentPageViewed50,
	/**
	 * User scrolls 75% of page
	 *
	 * @param {PercentPageViewed75} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	percentPageViewed75,
	/**
	 * Fires a 'Place Bid Button' track call.
	 *
	 * @param {PlaceBidButton} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	placeBidButton,
	/**
	 * User confirms maximum bid
	 *
	 * @param {PlaceMaximumBidButton} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	placeMaximumBidButton,
	/**
	 * User clicks a card on a map or in a list
	 *
	 * @param {PreferredContentCardClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	preferredContentCardClicked,
	/**
	 * User makes any of the predefined interaction on the map (style change, filter, click, ...)
	 *
	 * @param {PreferredMapInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	preferredMapInteraction,
	/**
	 * User clicks on filter results content either in the list or map view
	 *
	 * @param {PreferredSearchResultClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	preferredSearchResultClicked,
	/**
	 * User switches between map- and list-view
	
	 *
	 * @param {PreferredViewTypeSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	preferredViewTypeSelected,
	/**
	 * User clicks print button
	 *
	 * @param {PrintButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	printButtonClicked,
	/**
	 * User successfully closes the print dialog after printing or saving as pdf (not possible to track what user does in the modal so tracking closure of modal is best approximation)
	 *
	 * @param {PrintCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	printCompleted,
	/**
	 * User confirms their preferred format, i.e. thumbnails or full description by pressing the 'Continue' button
	 *
	 * @param {PrintFormatSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	printFormatSelected,
	/**
	 * user completes selection of lots they want to print
	 *
	 * @param {PrintLotsSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	printLotsSelected,
	/**
	 * User clicks link on Private Sale product detail; URL must contain /buy-sell/private-sale
	 *
	 * @param {PrivateSaleProductClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	privateSaleProductClicked,
	/**
	 * User clicks “checkout” on the cart
	 *
	 * @param {ProceedToCheckout} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	proceedToCheckout,
	/**
	 * User added a product to their shopping cart
	 *
	 * @param {ProductAdded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAdded,
	/**
	 * Fires a 'Product Interaction' track call.
	 *
	 * @param {ProductInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productInteraction,
	/**
	 * Event when a visitor filters a list of products in New Search page
	 *
	 * @param {ProductListFiltered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListFiltered,
	/**
	 * Fires a 'Product List Viewed' track call.
	 *
	 * @param {ProductListViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListViewed,
	/**
	 * User removed a product from their shopping cart
	 *
	 * @param {ProductRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemoved,
	/**
	 * Fires a 'Product Viewed' track call.
	 *
	 * @param {ProductViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productViewed,
	/**
	 * User clicks an internal offer promotion
	 *
	 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionClicked,
	/**
	 * Fires when bag item(s) quantity is updated
	 *
	 * @param {QuantityUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	quantityUpdated,
	/**
	 * User clicks a quick bid button
	 *
	 * @param {QuickBidButton} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	quickBidButton,
	/**
	 * User cancels quick bid
	 *
	 * @param {QuickBidCancelButton} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	quickBidCancelButton,
	/**
	 * User clicks on a primary call to action within the quick view card, ie. to bring user to auction content or registration flow
	 *
	 * @param {QuickViewClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	quickViewClicked,
	/**
	 * User clicks on a primary call to action within the quick view card, ie. to bring user to auction content or registration flow
	 *
	 * @param {QuickViewInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	quickViewInteraction,
	/**
	 * User opens a quick view card, i.e. by clicking the icon on the calendar
	 *
	 * @param {QuickViewOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	quickViewOpened,
	/**
	 * Sent when a user clicks an item on a recommendation carousel
	 *
	 * @param {RecommendationClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recommendationClicked,
	/**
	 * Sent when a recommendation carousel is loaded anywhere on a page
	 *
	 * @param {RecommendationLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recommendationLoaded,
	/**
	 * Sent when a user sees a recommendation carousel. Triggered via a viewport listener. Event triggers: 1. Sent when a recommendation carousel comes into view for the user. This can be e.g. a ''You may also like" carousel or "For you" carousel. Trigger on Web -Publish to Segment. View in Avo: https://www.avo.app/schemas/sqtpEbdMfEfjjyqszKnR/events/r9uf-dxRH/trigger/RAMCbQ0K5
	 *
	 * @param {RecommendationSeen} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recommendationSeen,
	/**
	 * User encounters the Registration Pending or Action Needed to Bid messages when attempting to register for or bid in a restricted sale
	 *
	 * @param {RegistrationPendingMessageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationPendingMessageViewed,
	/**
	 * A logged-in user successfully clicks 'Track Sale'
	 *
	 * @param {SaleTracked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	saleTracked,
	/**
	 * User clicks "SALEROOM NOTICE" on lot detail page
	 *
	 * @param {SaleroomNoticeView} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	saleroomNoticeView,
	/**
	 * An answer card loads
	 *
	 * @param {SearchAnswerCardLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchAnswerCardLoaded,
	/**
	 * User initiates a search by starting to type (the text of the field changing from 0 characters to 1 character)
	 *
	 * @param {SearchInitiated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchInitiated,
	/**
	 * User activates the search input field, i.e. by clicking the search icon or clicking into the search field
	 *
	 * @param {SearchInputActivated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchInputActivated,
	/**
	 * User clicks search result in global site search
	 *
	 * @param {SearchResultClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchResultClicked,
	/**
	 * User enters search, driving user to search results page or if they are already on the search page their list of search results is updated
	 *
	 * @param {SearchSubmitted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchSubmitted,
	/**
	 * User clicks on one of the suggested keywords
	 *
	 * @param {SearchSuggestionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchSuggestionClicked,
	/**
	 * Fires a 'Seller Portal Action' track call.
	 *
	 * @param {SellerPortalAction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sellerPortalAction,
	/**
	 * User opens a shipment from Shipping landing page
	 *
	 * @param {ShipmentViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	shipmentViewed,
	/**
	 * User confirms a selection of shipping preference
	 *
	 * @param {ShippingPreferenceConfirmed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	shippingPreferenceConfirmed,
	/**
	 * User selects a shipping option
	 *
	 * @param {ShippingSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	shippingSelected,
	/**
	 * User signs out
	 *
	 * @param {SignedOut} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedOut,
	/**
	 * User starts simple registration
	 *
	 * @param {SimpleRegistrationStart} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	simpleRegistrationStart,
	/**
	 * User attempts simple registration
	 *
	 * @param {SimpleRegistrationSubmitAttempt} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	simpleRegistrationSubmitAttempt,
	/**
	 * User attempts to submit the simple registration form but prompts an error
	 *
	 * @param {SimpleRegistrationSubmitError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	simpleRegistrationSubmitError,
	/**
	 * User changes slides
	 *
	 * @param {SlideshowAdvanced} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	slideshowAdvanced,
	/**
	 * when user selects the carousel option at the bottom of a slideshow
	 *
	 * @param {SlideshowCarouselOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	slideshowCarouselOpened,
	/**
	 * when a user zooms on a slideshow image
	 *
	 * @param {SlideshowZoomed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	slideshowZoomed,
	/**
	 * Event when a visitor filters a list of products in New Search page
	 *
	 * @param {SortApplied} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sortApplied,
	/**
	 * When user within Cecil Beaton experience clicks tab, e.g. ‘Cecil Beaton Library’, ‘Purchase a Print’, ‘License an Image’, or ‘Overview’ pass a track event called ‘Tab Click’
	 *
	 * @param {TabClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tabClicked,
	/**
	 * User is presented with 'please try again' screen
	 *
	 * @param {TechnicalRegistrationError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	technicalRegistrationError,
	/**
	 * User finishes registering for an auction via Telephone
	 *
	 * @param {TelephoneRegistrationComplete} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	telephoneRegistrationComplete,
	/**
	 * User clicks tile module
	 *
	 * @param {TileGridModuleClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tileGridModuleClicked,
	/**
	 * User clicks on call to action link within a hotspot
	
	 *
	 * @param {TimelineModuleClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	timelineModuleClicked,
	/**
	 * User is presented with billing address screen
	 *
	 * @param {TransactionalBillingAddressView} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalBillingAddressView,
	/**
	 * When a user is presented with the Billing Information screen
	
	
	 *
	 * @param {TransactionalBillingInformationViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalBillingInformationViewed,
	/**
	 * user completes transactional registration
	 *
	 * @param {TransactionalComplete} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalComplete,
	/**
	 * User interacts with credit card details screen
	 *
	 * @param {TransactionalCreditCardDetailsInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalCreditCardDetailsInteraction,
	/**
	 * User is presented with credit card details screen
	 *
	 * @param {TransactionalCreditCardDetailsView} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalCreditCardDetailsView,
	/**
	 * User is presented with profile screen
	 *
	 * @param {TransactionalProfileInformationView} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalProfileInformationView,
	/**
	 * When users click the “Leave” option from the “Are you sure?” prompt
	 *
	 * @param {TransactionalRegistrationCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationCancelled,
	/**
	 * User finishes a transactional registration (an account upgrade). This is sent on a successful creation of an account, even if the user gets an ''Unable to bid" flag.
	 *
	 * @param {TransactionalRegistrationCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationCompleted,
	/**
	 * When users click either edit link from the review screen
	 *
	 * @param {TransactionalRegistrationEdit} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationEdit,
	/**
	 * An error occurs during a transactional registration (an account upgrade). Sent for all types of errors but the property error_type distinguishes between the error types.
	 *
	 * @param {TransactionalRegistrationError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationError,
	/**
	 * Passes when user is presented with 'something went wrong' screen
	 *
	 * @param {TransactionalRegistrationFailure} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationFailure,
	/**
	 * When a user is presented with the review screen prior to submitting registration
	 *
	 * @param {TransactionalRegistrationReview} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationReview,
	/**
	 * Sent when a user start the transactional registration flow by clicking Complete-my-Profile or other CTAs that start the flow
	 *
	 * @param {TransactionalRegistrationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationStarted,
	/**
	 * User completed a registration step
	 *
	 * @param {TransactionalRegistrationStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationStepCompleted,
	/**
	 * When a user clicks submit from the review screen
	 *
	 * @param {TransactionalRegistrationSubmitted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalRegistrationSubmitted,
	/**
	 * User encounters validation error during transactional registration
	 *
	 * @param {TransactionalValidationError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionalValidationError,
	/**
	 * User is presented with 'unable to verify' screen
	 *
	 * @param {UnableToVerify} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unableToVerify,
	/**
	 * Sent when any of the relevant actions is done by the user or service while uploading an ID
	 *
	 * @param {UploadIdAction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadIdAction,
	/**
	 * User clicks the ''Upload ID" button when prompted to submit an ID for account verification
	 *
	 * @param {UploadIdClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadIdClicked,
	/**
	 * Sent when an error occurs while uploading an ID
	 *
	 * @param {UploadIdError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadIdError,
	/**
	 * Sent when a failure occurs while uploading an ID
	 *
	 * @param {UploadIdFailed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadIdFailed,
	/**
	 * Sent when an ID has been successfully submitted
	 *
	 * @param {UploadIdSuccess} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadIdSuccess,
	/**
	 * User's auth0 or Okta login session times out
	 *
	 * @param {UserSessionError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userSessionError,
	/**
	 * User clicks companion content
	 *
	 * @param {VideoCompanionContentClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoCompanionContentClicked,
	/**
	 * Video reaches 100%
	 *
	 * @param {VideoPlaybackCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackCompleted,
	/**
	 * User pauses a video
	 *
	 * @param {VideoPlaybackPaused} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackPaused,
	/**
	 * Playback is resumed, by the user, after being paused
	 *
	 * @param {VideoPlaybackResumed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackResumed,
	/**
	 * User manually seeks to a certain position of the content or ad in the playback. Pass in the position property to denote where the user desires to begin the playback from
	 *
	 * @param {VideoPlaybackSeekCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackSeekCompleted,
	/**
	 * User presses play
	 *
	 * @param {VideoPlaybackStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackStarted,
	/**
	 * User toggles to video playlist
	 *
	 * @param {VideoPlaylistSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaylistSelected,
	/**
	 * User clicks on video within playlist
	 *
	 * @param {VideoSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoSelected,
	/**
	 * User clicks on one of the video sharing options
	 *
	 * @param {VideoShared} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoShared,
	/**
	 * Video play begins
	 *
	 * @param {VideoStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoStarted,
	/**
	 * User interact with the video stream in a live auction
	 *
	 * @param {VideoStreamInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoStreamInteraction,
	/**
	 * Video reaches 25%
	 *
	 * @param {VideoWatched25} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoWatched25,
	/**
	 * Video reaches 50%
	 *
	 * @param {VideoWatched50} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoWatched50,
	/**
	 * Video reaches 75%
	 *
	 * @param {VideoWatched75} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoWatched75,
	/**
	 * User views a lot condition report
	 *
	 * @param {ViewConditionReport} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewConditionReport,
	/**
	 * User clicks the 'VIEW PRINT CATALOGUE' on an AEM auction landing page
	 *
	 * @param {ViewPrintCatalogueClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewPrintCatalogueClicked,
	/**
	 * User selects view preference on e.g. calendar page, ADP or LDP
	 *
	 * @param {ViewTypeSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewTypeSelected,
	/**
	 * User clicks a link or button in the wallet connect window
	 *
	 * @param {WalletConnectClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletConnectClicked,
	/**
	 * User clicks a "Connect your wallet" link on ADP LDP or My Acount
	 *
	 * @param {WalletConnectOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletConnectOpened,
	/**
	 * User zooms on AEM lot image
	 *
	 * @param {ZoomView} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	zoomView,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/sothebys/protocols/tracking-plans/rs_1GG5Hb9m0xMkFPhCBTwAhU1CjOp`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
