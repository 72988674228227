import { isBrowser, getCurrentPDPUrl, getRetailItemSeller } from './utils';
import { urlPathToSegmentPageName, stringAfterSeparator } from './strUtils';
import typewriter from '../../../analytics';
import { ProductInteractionType } from '../shared/segmentTypes';
import {
  RetailItem,
  RetailItemPropertyType,
  Currency,
  SimilarItem,
  RecommendedRetailItem,
  RecommendedLot,
  Variant,
  LowHighEstimateV2,
} from '../client';
import _ from 'lodash';
import { SearchState } from 'react-instantsearch-core';
import trackingStore from '../stores/TrackingStore';
import {
  getGeneralPeriodFromRetailItem,
  propertyTypeToSaleType,
  propertyTypeToContentType,
  waysToBuyToSaleType,
  waysToBuyToContentType,
  getAnalytics,
} from './segment/shared';

export type TrackProductViewedProps = {
  retailItem: RetailItem;
  language: string;
  host: string;
  path: string;
  user: any;
  loggedIn: boolean;
  selectedVariant?: Variant;
};

export type TrackEnquireClickedProps = {
  retailItem: RetailItem;
  loggedIn: boolean;
  language: string;
  host: string;
  path: string;
  selectedVariant?: Variant;
};

export type TrackEnquireSubmittedProps = {
  retailItem: RetailItem;
  loggedIn: boolean;
  language: string;
  host: string;
  path: string;
  contactInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
  };
  selectedVariant?: Variant;
};

export type TrackMakeAnOfferClickedProps = {
  retailItem: RetailItem;
  loggedIn: boolean;
  location: string;
  host: string;
  path: string;
  selectedVariant?: Variant;
};

export type AlternateImageInteractionProps = {
  position: number;
  retailItem: RetailItem;
};

type TrackProductListViewedProps = {
  collectionTitle: string;
  language: string;
  departments: string[];
  categories: string[];
  waysToBuy: string[];
  user?: any;
  loggedIn: boolean;
  products?: {
    product_id: string;
    brand: string | null;
    category: string | null;
    image_url: string | null;
    name: string | null;
    position: number | null;
    price: number | null;
    quantity: number | null;
    sku: string | null;
    url: string | null;
  }[];
  currentPage: number;
  totalPages: number;
  totalNumberOfItems: number;
  itemsPerPage: number;
  objectIDs: string[];
};

type TrackProductListFilteredProps = {
  collection: string;
  appliedFilters: {
    attribute: string;
    value: string;
  }[];
  search_keyword: string;
  filter_applied: string;
  filter_widget_type: string;
  num_search_results: number;
  waysToBuy?: string[];
  user?: any;
  products?: {
    product_id: string;
    brand: string | null;
    category: string | null;
    image_url: string | null;
    name: string | null;
    position: number | null;
    price: number | null;
    quantity: number | null;
    sku: string | null;
    url: string | null;
  }[];
  currentPage: number;
  totalPages: number;
  totalNumberOfItems: number;
  itemsPerPage: number;
  objectIDs: string[];
};

type Filters = { attribute: string; value: string }[];

export type ProductListFilteredDataProps = {
  appliedFilters: Filters;
  lastAppliedFilter: string;
  lastAppliedValue: string;
  filterWidgetType: string;
  searchQuery: string;
};

type TrackCurrencySelectedProps = {
  originalCurrency?: Currency;
  selectedCurrency: Currency;
  currencySelectionOrigin: 'product page' | 'product list';
};

export type TrackLearnMoreClickedProps = {
  retailItem: RetailItem;
  host: string;
  path: string;
  component: string;
};

export type TrackOfferEnteredProps = {
  retailItem: RetailItem;
  host: string;
  path: string;
  offerPrice: number;
  percentOfListPrice: number;
  loggedIn: boolean;
};

export type TrackRecommendedClickedProps = {
  retailItem: RetailItem;
  productClicked: SimilarItem;
  numberOfRecommendations: number;
  language: string;
};

type TrackRecommendationCarouselLoadedProps = {
  retailItem: RetailItem;
  numberOfRecommendations: number;
  language: string;
};

type TrackRecommendationCarouselSeenProps = {
  retailItem: RetailItem;
  numberOfRecommendations: number;
  language: string;
};

/*
 * Here are helper function to map data that are often used to reduce duplication
 */

export const productListFilteredDataEmptySate = {
  appliedFilters: [],
  lastAppliedFilter: '',
  lastAppliedValue: '',
  searchQuery: '',
  filterWidgetType: '',
};

type TrackContentCardClickedProps = {
  cardTitle: string;
  cardType: string;
  collection: string;
  listId: string;
  language: string;
  auth0_id: string;
};

export const trackContentCardClicked = ({
  cardTitle,
  cardType,
  collection,
  listId,
  language,
  auth0_id,
}: TrackContentCardClickedProps) => {
  if (isBrowser()) {
    typewriter.contentCardClicked({
      auth0_id,
      card_title: cardTitle,
      card_type: cardType,
      collection: collection,
      list_id: { [listId]: listId },
      language,
    });
  }
};

export const trackSelectedCurrency = ({
  originalCurrency,
  selectedCurrency,
  currencySelectionOrigin,
}: TrackCurrencySelectedProps) => {
  if (isBrowser()) {
    let props = {
      original_currency: originalCurrency,
      currency_new: selectedCurrency,
      currency_selection_origin: currencySelectionOrigin,
    };
    typewriter.currencySelected(props);
  }
};

export const trackProductViewed = ({
  retailItem,
  language,
  host,
  path,
  user,
  loggedIn,
  selectedVariant,
}: TrackProductViewedProps) => {
  if (isBrowser()) {
    const trackingState = trackingStore.getState();

    const creatorsArr = retailItem.creators?.map(
      (creator) => creator.displayName,
    );
    let originalImageUrl = '';
    retailItem.media?.images.find((image) => {
      const originalImage = image.renditions.find(
        (rendition) => rendition.imageSize === 'Original',
      );
      if (originalImage) {
        originalImageUrl = originalImage.url;
        return true;
      } else {
        return false;
      }
    });

    let taggingList: string[] = [];
    retailItem.tags?.map((tags) => {
      taggingList = taggingList.concat(tags.tagLabel);
    });
    let retailItemCategory = retailItem.category || [];
    let hasVariants = retailItem.variantGroup.variants.length > 1;

    trackPageCall({
      propertyType: retailItem.propertyType,
      language,
      user,
      department: retailItem.department?.title || '',
      callback: () =>
        typewriter.productViewed({
          product_id: selectedVariant
            ? selectedVariant?.variantId
            : retailItem.retailItemId,
          sku: selectedVariant ? selectedVariant?.sku : retailItem.sku,
          type: propertyTypeToContentType(retailItem.propertyType),
          content_type: propertyTypeToContentType(retailItem.propertyType),
          sale_type: propertyTypeToSaleType(retailItem.propertyType),
          category: retailItemCategory?.[0]?.name,
          category_2: retailItemCategory?.[1]?.name,
          product_type: retailItem.objects?.[0]?.objectTypeName,
          department_category: retailItem.department?.title,
          name: retailItem.title,
          creator: creatorsArr?.join(', '),
          price: selectedVariant
            ? selectedVariant?.listPrice || 0
            : retailItem.pricing.listPrice || 0,
          currency: retailItem.pricing.currency,
          url: getCurrentPDPUrl({ host, path }),
          image_url: originalImageUrl,
          queryID: trackingState.algoliaQueryID,
          objectID: trackingState.algoliaObjectID,
          position: 1,
          index: trackingState.algoliaIndexName,
          //we don't need this param but it's required for current tracking plan
          lot_viking_id: retailItem.retailItemId,
          ecomm_prodid: [
            selectedVariant ? selectedVariant.sku : retailItem.sku,
          ],
          ecomm_totalvalue: selectedVariant
            ? selectedVariant.listPrice || 0
            : retailItem.pricing.listPrice || 0,
          ecomm_pagetype: 'product',
          tags: taggingList.join(' | '),
          period_general:
            getGeneralPeriodFromRetailItem(retailItem) || undefined,
          ships_internationally: retailItem.shipping?.shipsInternationally,
          logged_in: loggedIn,
          variant:
            hasVariants && selectedVariant
              ? `${selectedVariant?.groupName}: ${selectedVariant?.value}`
              : '',
          userToken: user ? stringAfterSeparator(user.sub, '|') : undefined,
          user_service_id: user?.vikingId,
          seller: getRetailItemSeller(retailItem),
        }),
    });
  }
};

const trackPageCall = ({
  propertyType,
  language,
  user,
  department,
  callback,
}: {
  propertyType?: RetailItemPropertyType;
  language: string;
  user: any;
  department: string;
  callback?: () => void;
}) => {
  if (isBrowser()) {
    const analytics = getAnalytics();
    const urlParams = new URLSearchParams(window.location.search);
    if (analytics) {
      analytics.page(
        urlPathToSegmentPageName({ path: window.location.pathname, language }),
        {
          content_type: propertyType
            ? propertyTypeToContentType(propertyType)
            : 'Buy Now',
          cmp_trackingcode: urlParams.get('cmp'),
          language: language,
          userId: user ? user.sub : null,
          user_service_id: user?.vikingId,
          department_category: department,
        },
        callback,
      );
    }
  }
};

export const trackMakeAnOfferClicked = ({
  retailItem,
  loggedIn,
  location,
  host,
  path,
  selectedVariant,
}: TrackMakeAnOfferClickedProps) => {
  if (isBrowser()) {
    const trackingState = trackingStore.getState();

    const creatorsArr = retailItem?.creators?.map(
      (creator) => creator.displayName,
    );
    let originalImageUrl = '';
    retailItem.media?.images.find((image) => {
      const originalImage = image.renditions.find(
        (rendition) => rendition.imageSize === 'Original',
      );
      if (originalImage) {
        originalImageUrl = originalImage.url;
        return true;
      } else {
        return false;
      }
    });
    let taggingList: string[] = [];
    retailItem.tags?.map((tags) => {
      taggingList = taggingList.concat(tags.tagLabel);
    });
    typewriter.makeAnOfferClicked({
      category: retailItem.category?.[0]?.name,
      category_2: retailItem.category?.[1]?.name,
      content_type: propertyTypeToContentType(retailItem.propertyType),
      creator: creatorsArr?.join(', '),
      currency: retailItem.pricing.currency,
      department_category: retailItem.department?.title,
      ecomm_pagetype: 'cart',
      ecomm_prodid: [selectedVariant ? selectedVariant.sku : retailItem.sku],
      ecomm_totalvalue: selectedVariant
        ? selectedVariant.listPrice
        : retailItem.pricing.listPrice,
      image_url: originalImageUrl,
      index: trackingState.algoliaIndexName,
      logged_in: loggedIn,
      lot_viking_id: retailItem.retailItemId,
      location: location,
      name: retailItem.title,
      objectID: trackingState.algoliaObjectID,
      price: selectedVariant
        ? selectedVariant.listPrice
        : retailItem.pricing.listPrice,
      product_id: selectedVariant
        ? selectedVariant.variantId
        : retailItem.retailItemId,
      product_type: retailItem.objects?.[0]?.objectTypeName,
      queryID: trackingState.algoliaQueryID,
      mao_value: retailItem.pricing.listPrice
        ? retailItem.pricing.listPrice * 0.25
        : undefined,
      sku: selectedVariant ? selectedVariant.sku : retailItem.sku,
      tags: taggingList.join(' | '),
      type: propertyTypeToContentType(retailItem.propertyType),
      url: getCurrentPDPUrl({ host, path }),
      period_general: getGeneralPeriodFromRetailItem(retailItem) || undefined,
    });
  }
};

export const trackEnquireClicked = ({
  retailItem,
  loggedIn,
  language,
  host,
  path,
  selectedVariant,
}: TrackEnquireClickedProps) => {
  if (isBrowser()) {
    const trackingState = trackingStore.getState();

    const creatorsArr = retailItem?.creators?.map(
      (creator) => creator.displayName,
    );
    let originalImageUrl = '';
    retailItem.media?.images.find((image) => {
      const originalImage = image.renditions.find(
        (rendition) => rendition.imageSize === 'Original',
      );
      if (originalImage) {
        originalImageUrl = originalImage.url;
        return true;
      } else {
        return false;
      }
    });
    let taggingList: string[] = [];
    retailItem.tags?.map((tags) => {
      taggingList = taggingList.concat(tags.tagLabel);
    });
    let props = {
      category: retailItem.category?.[0]?.name,
      category_2: retailItem.category?.[1]?.name,
      content_type: propertyTypeToContentType(retailItem.propertyType),
      creator: creatorsArr?.join(', '),
      currency: retailItem.pricing.currency,
      department_category: retailItem.department?.title,
      ecomm_pagetype: 'cart',
      ecomm_prodid: [selectedVariant ? selectedVariant.sku : retailItem.sku],
      ecomm_totalvalue: selectedVariant
        ? selectedVariant.listPrice
        : retailItem.pricing.listPrice,
      image_url: originalImageUrl,
      index: trackingState.algoliaIndexName,
      logged_in: loggedIn,
      lot_viking_id: retailItem.retailItemId,
      name: retailItem.title,
      objectID: trackingState.algoliaObjectID,
      price: selectedVariant
        ? selectedVariant.listPrice
        : retailItem.pricing.listPrice,
      product_id: selectedVariant
        ? selectedVariant.variantId
        : retailItem.retailItemId,
      product_type: retailItem.objects?.[0]?.objectTypeName,
      queryID: trackingState.algoliaQueryID,
      sku: selectedVariant ? selectedVariant.sku : retailItem.sku,
      tags: taggingList.join(' | '),
      type: propertyTypeToContentType(retailItem.propertyType),
      url: getCurrentPDPUrl({ host, path }),
      period_general: getGeneralPeriodFromRetailItem(retailItem) || undefined,
    };
    typewriter.enquireClicked(props);
  }
};

export const trackEnquireSubmitted = ({
  retailItem,
  contactInfo,
  loggedIn,
  language,
  host,
  path,
  selectedVariant,
}: TrackEnquireSubmittedProps) => {
  if (isBrowser()) {
    const trackingState = trackingStore.getState();

    const creatorsArr = retailItem?.creators?.map(
      (creator) => creator.displayName,
    );
    let originalImageUrl = '';
    retailItem.media?.images.find((image) => {
      const originalImage = image.renditions.find(
        (rendition) => rendition.imageSize === 'Original',
      );
      if (originalImage) {
        originalImageUrl = originalImage.url;
        return true;
      } else {
        return false;
      }
    });
    let taggingList: string[] = [];
    retailItem.tags?.map((tags) => {
      taggingList = taggingList.concat(tags.tagLabel);
    });
    typewriter.enquireSubmitted({
      user: {
        email: contactInfo.email,
        first_name: contactInfo.firstName,
        last_name: contactInfo.lastName,
        phone_number: contactInfo.phone,
      },
      category: retailItem?.category?.[0]?.name,
      category_2: retailItem.category?.[1]?.name,
      content_type: propertyTypeToContentType(retailItem.propertyType),
      creator: creatorsArr?.join(', '),
      currency: retailItem.pricing.currency,
      department_category: retailItem.department?.title,
      ecomm_pagetype: 'cart',
      ecomm_prodid: [selectedVariant ? selectedVariant.sku : retailItem.sku],
      ecomm_totalvalue: selectedVariant
        ? selectedVariant.listPrice
        : retailItem.pricing.listPrice,
      image_url: originalImageUrl,
      index: trackingState.algoliaIndexName,
      logged_in: loggedIn,
      lot_viking_id: retailItem.retailItemId,
      name: retailItem.title,
      objectID: trackingState.algoliaObjectID,
      price: selectedVariant
        ? selectedVariant.listPrice
        : retailItem.pricing.listPrice,
      product_id: selectedVariant
        ? selectedVariant.variantId
        : retailItem.retailItemId,
      product_type: retailItem.objects?.[0]?.objectTypeName,
      queryID: trackingState.algoliaQueryID,
      sku: selectedVariant ? selectedVariant.sku : retailItem.sku,
      tags: taggingList.join(' | '),
      type: propertyTypeToContentType(retailItem.propertyType),
      url: getCurrentPDPUrl({ host, path }),
      period_general: getGeneralPeriodFromRetailItem(retailItem) || undefined,
    });
  }
};

export const trackProductListViewed = ({
  collectionTitle,
  departments,
  categories,
  language,
  waysToBuy,
  user,
  products,
  loggedIn,
  currentPage,
  totalPages,
  totalNumberOfItems,
  itemsPerPage,
  objectIDs,
}: TrackProductListViewedProps) => {
  const trackingState = trackingStore.getState();

  const uniqDeparments = _.uniq(departments);
  let categoryNames: string[] = [];
  categories?.map((categoryLvl) => {
    const tokens = _.split(categoryLvl, ' > ');
    categoryNames = categoryNames.concat(tokens);
  });

  const uniqCategories = _.uniq(categoryNames);
  if (isBrowser()) {
    trackPageCall({
      language,
      user,
      department: uniqDeparments.join(' | '),
      callback: () => {
        typewriter.productListViewed({
          collection: collectionTitle,
          list_id: collectionTitle,
          language,
          department_category: uniqDeparments.join(' | '),
          product_category_list: uniqCategories.join(' | '),
          type: waysToBuy.map(waysToBuyToContentType).join(' | '),
          content_type: waysToBuy.map(waysToBuyToContentType).join(' | '),
          queryID: trackingState.algoliaQueryID,
          index: trackingState.algoliaIndexName,
          category: uniqCategories.length > 0 ? uniqCategories[0] : undefined,
          sale_type: waysToBuy.map(waysToBuyToSaleType).join(' | '),
          logged_in: loggedIn,
          products: products,
          total_number_of_items: totalNumberOfItems,
          pagination_current_page: currentPage,
          pagination_total_pages: totalPages,
          items_per_page: itemsPerPage,
          userToken: user ? stringAfterSeparator(user.sub, '|') : undefined,
          user_service_id: user?.vikingId,
          objectIDs: objectIDs,
        });
      },
    });
  }
};

export const trackProductListFiltered = ({
  collection,
  appliedFilters,
  search_keyword,
  filter_applied,
  filter_widget_type,
  num_search_results,
  waysToBuy,
  user,
  products,
  currentPage,
  totalPages,
  totalNumberOfItems,
  itemsPerPage,
  objectIDs,
}: TrackProductListFilteredProps) => {
  const trackingState = trackingStore.getState();
  let filters: string[] = [];
  appliedFilters.map((filter) =>
    filters.push(`${filter.attribute}:${filter.value}`),
  );

  if (isBrowser()) {
    typewriter.productListFiltered({
      collection,
      filters: filters,
      search_keyword,
      filter_applied,
      filter_widget_type: filter_widget_type,
      num_search_filters: filters.length,
      num_search_results: num_search_results,
      type: waysToBuy?.map(waysToBuyToContentType).join(' | '),
      content_type: waysToBuy?.map(waysToBuyToContentType).join(' | '),
      list_id: collection,
      queryID: trackingState.algoliaQueryID,
      index: trackingState.algoliaIndexName,
      userToken: user ? stringAfterSeparator(user.sub, '|') : undefined,
      products: products,
      total_number_of_items: totalNumberOfItems,
      pagination_current_page: currentPage,
      pagination_total_pages: totalPages,
      items_per_page: itemsPerPage,
      objectIDs: objectIDs,
    });
  }
};

export const identifyUser = (userId?: string, traits?: Object) => {
  const analytics = getAnalytics();

  if (isBrowser() && analytics) {
    analytics.identify(userId, traits);
  }
};

export const updateProductListFilteredTrackingData = ({
  searchState,
  trackingData,
  setTrackingData,
}: {
  searchState: SearchState;
  trackingData: ProductListFilteredDataProps;
  setTrackingData: (value: ProductListFilteredDataProps) => void;
}) => {
  const { refinementList, priceFilterLow, priceFilterHigh, query } =
    searchState;
  let trackingDataNew: ProductListFilteredDataProps =
    productListFilteredDataEmptySate;
  trackingDataNew.searchQuery = query ? query : '';
  const appliedFiltersPrev = trackingData.appliedFilters;
  const filters: Filters = [];
  if (priceFilterLow !== undefined && priceFilterHigh !== undefined) {
    filters.push({
      attribute: 'price',
      value: `${priceFilterLow}-${priceFilterHigh}`,
    });
    if (
      !appliedFiltersPrev.find(
        (filterApplied) =>
          filterApplied.attribute === 'price' &&
          filterApplied.value === `${priceFilterLow}-${priceFilterHigh}`,
      )
    ) {
      trackingDataNew.lastAppliedFilter = 'price';
      trackingDataNew.filterWidgetType = 'slider';
      trackingDataNew.lastAppliedValue = `price:${priceFilterLow}-${priceFilterHigh}`;
    }
  }

  if (refinementList && Object.keys(refinementList).length) {
    for (const [key, refinementValue] of Object.entries(refinementList)) {
      if (
        refinementValue.length <
        appliedFiltersPrev.filter(
          (filterAppliedPrev) => filterAppliedPrev.attribute === key,
        ).length
      ) {
        trackingDataNew.lastAppliedFilter = key;
        trackingDataNew.filterWidgetType = 'refinementList';
      }
      if (!refinementValue) {
        continue;
      }

      refinementValue.map((value) => {
        if (
          !appliedFiltersPrev.find(
            (filterApplied) =>
              filterApplied.attribute === key && filterApplied.value === value,
          )
        ) {
          trackingDataNew.lastAppliedFilter = key;
          trackingDataNew.filterWidgetType = 'refinementList';
          trackingDataNew.lastAppliedValue = `${key}:${value}`;
        }
        filters.push({ attribute: key, value });
      });
    }
  }
  trackingDataNew.appliedFilters = filters;
  setTrackingData(trackingDataNew);
};

export const trackLearnMoreClicked = ({
  retailItem,
  host,
  path,
  component,
}: TrackLearnMoreClickedProps) => {
  if (isBrowser()) {
    const creatorsArr = retailItem?.creators?.map(
      (creator) => creator.displayName,
    );

    typewriter.learnMore({
      category: retailItem.category?.[0]?.name,
      category_2: retailItem.category?.[1]?.name,
      content_type: propertyTypeToContentType(retailItem.propertyType),
      component: component,
      creator: creatorsArr?.join(', '),
      currency: retailItem.pricing.currency,
      department_category: retailItem.department?.title,
      name: retailItem.title,
      price: retailItem.pricing.listPrice,
      product_id: retailItem.retailItemId,
      product_type: retailItem.objects?.[0]?.objectTypeName,
      sku: retailItem.sku,
      type: propertyTypeToContentType(retailItem.propertyType),
      url: getCurrentPDPUrl({ host, path }),
    });
  }
};

export const trackOfferEntered = ({
  retailItem,
  host,
  path,
  loggedIn,
  offerPrice,
  percentOfListPrice,
}: TrackOfferEnteredProps) => {
  if (isBrowser()) {
    const creatorsArr = retailItem.creators?.map(
      (creator) => creator.displayName,
    );

    typewriter.offerEntered({
      category_2: retailItem.category?.[1]?.name,
      category: retailItem.category?.[0]?.name,
      content_type: propertyTypeToContentType(retailItem.propertyType),
      creator: creatorsArr?.join(', '),
      currency: retailItem.pricing.currency,
      department_category: retailItem.department?.title,
      logged_in: loggedIn,
      name: retailItem.title,
      offer_price: offerPrice,
      percent_of_list_price: percentOfListPrice,
      price: retailItem.pricing.listPrice,
      product_id: retailItem.retailItemId,
      product_type: retailItem?.objects?.[0]?.objectTypeName,
      sku: retailItem.sku,
      type: propertyTypeToContentType(retailItem.propertyType),
      url: getCurrentPDPUrl({ host, path }),
    });
  }
};

export const alternateImageInteraction = ({
  position,
  retailItem,
}: AlternateImageInteractionProps) => {
  typewriter.productInteraction({
    product_interaction_type: ProductInteractionType.ClickAlternativeImage,
    position: position + 1,
    type: propertyTypeToContentType(retailItem.propertyType),
  });
};

export const trackRecommendationClicked = ({
  retailItem,
  productClicked,
  numberOfRecommendations,
  language,
}: TrackRecommendedClickedProps) => {
  if (productClicked?.__typename === 'RetailItem') {
    const retailItemClicked = productClicked as RecommendedRetailItem;
    typewriter.recommendationClicked({
      recommendation_num: numberOfRecommendations || 0,
      sale_type: propertyTypeToSaleType(retailItem?.propertyType) || '',
      currency: retailItem?.pricing?.currency || '',
      recommendation_source: 'TG',
      category: retailItem?.category?.[0]?.name,
      in_app: false,
      language: language,
      // PDP item info
      product_id: retailItem?.sku || '',
      product_uuid: retailItem?.id,
      price: retailItem?.pricing?.listPrice || 0,
      // recommended product clicked info
      clicked_product_id: retailItemClicked?.id,
      clicked_product_uuid: retailItemClicked?.sku || '',
      clicked_price: retailItemClicked?.pricing?.listPrice || 0,
      clicked_currency: retailItemClicked?.pricing?.currency,
      clicked_sale_type: retailItemClicked.propertyType
        ? propertyTypeToSaleType(retailItemClicked.propertyType) || ''
        : '',
    });
  } else if (productClicked?.__typename === 'LotCard') {
    const lotItemClicked = productClicked as RecommendedLot;
    let estimate;
    if (lotItemClicked.estimateV2?.__typename === 'LowHighEstimateV2') {
      estimate = lotItemClicked.estimateV2 as LowHighEstimateV2;
    }

    typewriter.recommendationClicked({
      recommendation_num: numberOfRecommendations || 0,
      sale_type: propertyTypeToSaleType(retailItem?.propertyType) || '',
      currency: retailItem?.pricing?.currency || '',
      recommendation_source: 'TG',
      category: retailItem?.category?.[0].name,
      in_app: false,
      language: language,
      // PDP item info
      product_id: retailItem?.sku || '',
      product_uuid: retailItem?.id,
      price: retailItem?.pricing?.listPrice || 0,
      // recommended product clicked info
      clicked_product_id: lotItemClicked?.id,
      clicked_product_uuid: lotItemClicked?.lotId,
      clicked_sale_uuid: lotItemClicked?.auction?.auctionId,
      clicked_estimate_high: estimate?.highEstimate?.amount
        ? +estimate?.highEstimate?.amount
        : null,
      clicked_estimate_low: estimate?.lowEstimate?.amount
        ? +estimate?.lowEstimate?.amount
        : null,
      clicked_sale_type: 'lot',
    });
  }
};

export const trackRecommendationCarouselLoaded = ({
  retailItem,
  numberOfRecommendations,
  language,
}: TrackRecommendationCarouselLoadedProps) => {
  typewriter.recommendationLoaded({
    currency: retailItem?.pricing?.currency || '',
    price: retailItem?.pricing?.listPrice,
    category: retailItem?.category?.[0]?.name,
    product_id: retailItem.sku || '',
    product_uuid: retailItem?.id,
    recommendation_num: numberOfRecommendations || 0,
    recommendation_source: 'TG',
    sale_type: propertyTypeToSaleType(retailItem.propertyType) || '',
    in_app: false,
    language: language,
  });
};

export const trackRecommendationCarouselSeen = ({
  retailItem,
  numberOfRecommendations,
  language,
}: TrackRecommendationCarouselSeenProps) => {
  typewriter.recommendationSeen({
    currency: retailItem?.pricing?.currency || '',
    price: retailItem?.pricing?.listPrice || 0,
    category: retailItem?.category?.[0]?.name,
    product_id: retailItem?.sku || '',
    product_uuid: retailItem?.id,
    recommendation_num: numberOfRecommendations || 0,
    recommendation_source: 'TG',
    sale_type: propertyTypeToSaleType(retailItem?.propertyType) || '',
    in_app: false,
    language: language,
  });
};
